$redpoint-color: #fa5c53;
$onsight-color: #666666;
$flash-color: #eaca35;
$toprope-color: #999999;

body {
  font-size: 12px;
  font-family: "Roboto", "Trebuchet MS", "Helvetica", "Arial", SANS-SERIF;
  color: #272727;
  background: #fff url("#{$image_path}body_white.gif") repeat;
  font-weight: normal;
}

.mbox {
  //display: none;
  max-width: 600px;
}

.crag-list-link {
  color: $gray-light;
  font-weight: 700;

  &:hover {
    color: $gray-base;
    text-decoration: underline;
  }
}

.inline-block {
  display: inline-block;
}

.text-ellipsis {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#feed_items_loading {
  height: 200px;
  position: relative;
  width: 100%;
  text-align: left;

  .loading {
    height: 55px;
    width: 200px;
    background: #333333;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -27px;
    margin-left: -100px;
    display: block;
    border-radius: $border-radius-base;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);

    .icon-loading {
      padding: 15px;
      display: inline-block;

      img {
        height: 25px;
        width: 25px;
      }
    }

    .message {
      display: inline-block;
      color: #fff;
    }
  }
}

.wellcome {
  .banner {
    background-color: $brand-primary;
    margin: -10px -10px 0 -10px;
    border-radius: 4px 4px 0 0;
    padding: 60px 20px 25px;

    h1 {
      text-align: center;
      margin: 0;
      font-weight: 700;
    }

    p {
      text-align: center;
      margin: 10px;
    }
  }

  .btn-back {
    color: $gray-light;
  }

  form {
    padding: 10px 15px;
  }

  .wellcome-footer {
    margin-top: 20px;
  }

  .carousel-indicators {
    top: -35px;
    bottom: auto;

    li {
      border: 1px solid #fff;
    }
  }

  .genres {
    input + label {
      margin-right: 10px;
      margin-left: 3px;
    }
  }

  table {
    width: 100%;
  }
}

$font-size-xsmall: 10px;

.unimportant {
  label {
    color: $gray-light;
    font-weight: 400;
  }
}

@mixin appstore-badge($size) {
  display: inline-block;
  background: url("#{$image_path}download_on_app_store.png") no-repeat;
  background-size: cover;

  $width: 270px;
  $height: 80px;

  @if $size == x1 {
    width: $width/2;
    height: $height/2;
  } @else if $size == x2 {
    width: $width/1.5;
    height: $height/1.5;
  } @else if $size == x3 {
    width: $width;
    height: $height;
  }
}

.appstore-badge-x1 {
  @include appstore-badge(x1);
}

@mixin googleplay-badge($size) {
  display: inline-block;
  background: url("#{$image_path}download_on_google_play.png") no-repeat;
  background-size: cover;

  $width: 270px;
  $height: 80px;

  @if $size == x1 {
    width: $width/2;
    height: $height/2;
  } @else if $size == x2 {
    width: $width/1.5;
    height: $height/1.5;
  } @else if $size == x3 {
    width: $width;
    height: $height;
  }
}

.googleplay-badge-x1 {
  @include googleplay-badge(x1);
}

@mixin app-icon($type, $size) {
  display: inline-block;
  line-height: 14px * $size;
  background-size: cover;
  margin-bottom: -2px * $size;

  background-image: url("#{$image_path}appicon_" + $type + ".png");

  $width: 14px;
  $height: 14px;

  width: $width * $size;
  height: $height * $size;
}

.stars {
  font-family: iconfont;
  white-space: nowrap;
  cursor: default;
  display: inline-block;

  .star {
    display: inline-block;
    top: auto;
  }

  .star.full {
    color: #e4d012;
  }

  .star.empty {
    color: #cccccc;
  }

  .star.half {
    position: relative;
    color: #cccccc;

    .star.full {
      width: 49%;
      overflow: hidden;
      position: absolute;
      left: 0;
    }
  }

  /*.star.half {
    position: relative;
  }*/
}

.dropdown-toggle {
  cursor: pointer;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.content-error {
  text-align: center;
  max-width: 550px;
  margin: auto;

  .glyphicon {
    font-size: 300%;
    color: #949494;
    display: block;
  }

  .message {
    text-align: center;
    display: block;
    margin-top: 15px;
    font-size: 14px;
  }
}

.wide {
  width: 600px;
}

.climber-thumb {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: 2px solid #fff;

  @include respond-to(xs) {
    width: 30px;
    height: 30px;
  }

  &.gray-border {
    border-color: #ddd;
  }

  &--large {
    width: 60px;
    height: 60px;

    @include respond-to(xs) {
      width: 40px;
      height: 40px;
    }
  }
}

.crag-thumb {
  width: 40px;
  height: 40px;

  @include respond-to(xs) {
    width: 30px;
    height: 30px;
  }

  &.gray-border {
    border-color: #ddd;
  }
}

#topspace {
  display: none !important;
}

.map-background-container {
  position: relative;

  .map-background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .map-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  }

  .container {
    position: relative;
  }
}

.btn-readmore {
  font-weight: 700;

  .desc {
    font-weight: 400;
    font-size: 70%;
    display: block;
  }
}

.btn-follow {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  width: 85px;
  position: relative;

  //transition: background 0.1s;
  color: #222222 !important;
  //border-bottom: 1px solid darken($btn-primary-bg, 5%);
  // $btn-primary-border:             darken($btn-primary-bg, 5%) !default;
  &:hover {
    //border: 1px solid #fff;
    border: 1px solid transparent;
    background: $btn-primary-bg;
    color: #000000;
    //color: #fff;
  }

  //background: darken($btn-primary-bg, 5%);
  //border: 1px solid transparent;
  //border-top: 1px solid lighten($btn-primary-bg, 10%);
  span.follow-text {
    display: inline;
  }

  span.following-text {
    display: none;
  }

  span.img-loading {
    display: none;
    padding: 5px;
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    background-color: rgba(255, 255, 255, 0.7);
  }

  &.follow-loading {
    span.follow-text,
    span.following-text {
      //display: none !important;
    }

    span.img-loading {
      display: block;
    }
  }
}

.btn-following {
  border: 1px solid #cccccc;
  //border-top: 1px solid lighten($btn-primary-bg, 10%);
  background: #f0f0f0; // $btn-primary-bg;
  color: #8b8b8b !important;
  // # background-color: #ccc;
  span.follow-text {
    display: none;
  }

  span.following-text {
    display: inline;
  }

  span.img-loading {
    display: none;
  }

  &.follow-loading {
    span.follow-text,
    span.following-text {
      display: none !important;
    }

    span.img-loading {
      display: block;
    }
  }
}

.products {
  .product-container {
    .product-photo {
    }

    .product-brand {
    }

    .product-name {
    }

    .product-price {
    }
  }

  .product-container + .product-container {
  }
}

.tags {
  display: block;
  color: $gray;

  .tag {
    display: inline-block;
    margin-right: 3px;
    width: 21px;
    background-color: $brand-primary;
    height: 21px;
    padding: 3px;
    text-align: center;
    font-size: 13px;
    border-radius: 50%;
    overflow: hidden;
  }

  .tag-text {
    width: auto;
    //border-radius: 25%;
    background-color: transparent;
  }
}

.number-liftup {
  .number {
    font-size: 18px;
    text-transform: uppercase;
    color: #fff;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    @include respond-to(xs) {
      font-size: 16px;
    }
  }

  .name {
    font-size: 12px;
    text-transform: uppercase;
    color: #fff;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    @include respond-to(xs) {
      font-size: 10px;
    }
  }

  &__white-bg {
    .number {
      text-shadow: none;
      color: #000;
    }

    .name {
      color: #333;
      text-shadow: none;
    }
  }
}

.tickbox {
  border-radius: 50%;
  height: 20px;
  width: 20px;
  display: inline-block;
  border: 1px solid #e4e4e4;
  text-align: center;
  vertical-align: middle;

  .icon {
    display: none;
    color: $gray-base;
    font-size: 12px;
    line-height: 12px;
    vertical-align: top;

    &:before {
      vertical-align: middle;
    }
  }

  &:hover {
    background-color: #ffffff;

    .icon {
      display: inline-block;
    }
  }
}

a.gray-link {
  color: #666;

  &:hover {
    color: #000;
  }
}

.content-container .box-white table {
  margin: 30px 0;
}

#admin-dashboard {
  a {
    color: $link-color-blue;
  }
}

.box-white {
  background: #fff;
  border: 1px solid;
  border-color: #e5e6e9 #dfe0e4 #d0d1d5;
  border-radius: $border-radius-base;
  margin-top: 30px;

  a {
    color: $link-color-blue;
  }

  &__transparent {
    background: transparent !important;
    border: none !important;
    border-radius: 0 !important;
  }

  .miniheader {
    &:first-child {
      margin-top: 5px;
    }

    color: #959595;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    //margin: 0 0 5px 0;
    .actions {
      color: #959595;
      font-weight: 500;
      font-size: 12px;
      text-transform: none;
      float: right;

      &:hover {
        color: #888888;
      }

      > a {
        color: #959595;

        &:hover {
          color: #888888;
        }

        &.btn {
          color: #333333;
        }
      }
    }

    &:after {
      display: block;
      content: "";
      clear: both;
    }
  }

  & + .box-white {
    margin-top: 30px;
  }

  nav {
    ul {
      list-style-type: none;

      li {
        a {
          color: $link-color-blue;
          display: block;
          margin: 4px 0;
        }
      }
    }
  }
}

.no-top-margin {
  margin-top: 0;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.box-padded {
  padding: 15px 15px 15px 20px;

  .no-margin {
    margin: -15px -15px 0 -20px;
  }
}

.box-padded {
  > h3.additional-heading {
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

h3.miniheader,
.miniheader {
  color: #959595;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  margin: 10px 0 10px 0;
  font-family: $font-family-roboto;
  /*a {
    color: #959595 !important;
    font-weight: 700 !important;
  }*/
  &:hover {
    .icon {
      color: #333 !important;
    }
  }

  .edit-link {
    font-weight: 400;
    text-transform: lowercase;

    a {
      font-weight: 400 !important;
    }
  }

  /*.dropdown-menu a {
    font-weight: 400 !important;
  }*/
  &.bottom-border {
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
  }
}

.copytoclipboard {
  cursor: pointer;
}

.sidebar {
  margin-top: 30px;
  padding-bottom: 30px;
}

.content-list {
  margin-top: 15px;
  padding-bottom: 30px;

  .miniheader {
    margin-top: 30px;
    margin-bottom: 0;
  }

  @include respond-to(xs) {
    .miniheader {
      margin-top: 15px;
    }
    .row {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.grade.up {
  color: darkred;
}

.grade.down {
  color: darkgreen;
}

.ascent-type {
  //background: transparent !important;
  display: inline-block;
  border-radius: 20px;
  padding: 1px 5px;
  font-size: 11px;
  color: #fff;

  &.redpoint {
    background: $redpoint-color;
  }

  &.onsight {
    background: $onsight-color;
  }

  &.flash {
    background: $flash-color;
  }

  &.toprope {
    background: $toprope-color;
  }
}

.side-actions {
  li {
    margin: 10px 0;
  }

  a {
    color: $gray-dark;
  }
}

ul.pagination {
  li {
    a {
      color: #222 !important;
    }

    &.active {
      a {
        font-weight: 700 !important;
        color: #333 !important;
      }
    }
  }
}

.btn-like {
  @include like-btn(1, $gray-light, $gray-light);

  &.btn-like-default {
    color: $gray-dark !important;
    font-family: $font-family-roboto;
    font-style: normal !important;
    text-transform: uppercase;
    text-decoration: none !important;
    font-weight: 700;
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 4px;
    //background-color: $brand-primary;

    border: 1px solid transparent;
    border-top: 1px solid #f5e341;
    border-bottom: 1px solid $btn-primary-border;

    background: $brand-primary; //#dec90b;
    transition: background 0.1s;

    &:hover {
      background: #f3dc10;
    }
  }
}

a {
  cursor: pointer;
}

.item-content {
  .user-image {
    float: left;
  }

  %img.climber-thumb {
    vertical-align: top;
  }

  .item-details {
    padding-top: 5px;
    margin-left: 50px;

    .climber-name {
      color: #333;
      font-weight: bold;
    }

    .date {
      color: #777777;

      * {
        color: #777777;
        text-decoration: none;
      }
    }

    .title,
    .description {
      color: #333;

      a {
        color: #333;
        font-weight: 500;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.chat {
  padding-bottom: 15px;

  .comment-form {
    padding: 10px 0;

    form {
      padding-top: 3px;
    }
  }

  .chat-input-table {
    display: table-row;
    width: 100%;

    .text-cell {
      display: table-cell;
      border: 1px solid #ddd;
      border-radius: 5px 0 0 5px;

      textarea {
        margin: 0;
        border: none;
      }
    }

    .send-cell {
      display: table-cell;
      border: 1px solid #ddd;
      border-radius: 0 5px 5px 0;
      border-left: none;
      width: 1%;
      vertical-align: middle;
      min-height: 50px;
      background: #fff;

      .send-button {
        margin: 0;
        border: none;
        height: 100%;
        padding-top: 0;
        padding-bottom: 0;
        border-radius: 0 5px 5px 0;
        //margin-right: -1px;
        background: #fff;
      }
    }
  }
}

@include keyframes(anim-hide) {
  0% {
    height: 21px;
    transform: scale(1);
  }
  50% {
    height: 21px;
    transform: scale(0.1);
  }
  51% {
    height: 21px;
  }
  52% {
    height: 0px;
  }
}

@include keyframes(anim-appear) {
  0% {
    height: 0px;
  }
  51% {
    height: 0px;
  }
  52% {
    height: 21px;
    transform: scale(0.1);
  }
  80% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

.sharetools {
  a {
    color: $gray-light-2;
    text-decoration: none;

    &:hover {
      color: $gray-light;
    }
  }
}

.padding-left-0 {
  padding-left: 0px;
}

.btn.btn-facebook {
  background-color: #3e549c !important;
  color: #fff !important;
}

.help-block {
  font-size: 10px;
}

.reactions {
  border-top: 1px solid $gray-lighter;
  padding-top: 13px;
  margin-top: 8px;
  color: $gray-light;

  a {
    color: $gray-light-2;
    text-decoration: none;

    &:hover {
      color: $gray-light;
    }
  }

  .btn-like {
    font-size: 18px;
    color: $gray-light;
    display: inline-block;
    width: 20px;
    height: 18px;

    .like {
    }

    .text {
      display: none;
    }
  }

  .btn-comments {
    margin-left: 10px;
    font-size: 18px;
    color: $gray-light;
    display: inline-block;
    width: auto;
    height: 18px;

    .text {
      padding-left: 3px;
      display: inline-block;
      font-weight: 500;
      height: 20px;
      line-height: 14px;
      font-size: 12px;
      vertical-align: middle;
    }
  }

  .likes {
    display: inline-block;
    font-weight: 500;
    height: 20px;
    line-height: 14px;
    font-size: 12px;
    vertical-align: middle;

    .climber-thumb {
      height: 20px;
      width: 20px;
    }
  }

  .sharetools {
    font-size: 16px;
    display: inline-block;

    a {
      display: inline-block;
      height: 18px;
      margin: 0 3px;
    }
  }
}

.keyperson {
  font-size: 12px;
  text-align: center;
  padding: 5px 0;

  .climber-photo {
    text-align: center;
    margin: 10px 0;
  }

  .climber-name {
    font-weight: 700;
  }

  .climber-title {
    display: block;
    font-weight: normal;
    font-style: italic;
  }

  .role {
    font-size: 12px;
  }
}

.wide-max {
  max-width: 600px;
  width: 600px;
  @include respond-to(sm) {
    width: 450px;
  }
  @include respond-to(xs) {
    width: 350px;
  }
}

.wide-desktop {
  max-width: 740px;
  width: 740px;
  @include respond-to(sm) {
    width: 450px;
    max-width: 100%;
  }
  @include respond-to(xs) {
    width: 350px;
    max-width: 100%;
  }
}

.action-with-explanation {
  border-top: 1px solid $gray-lighter;
  display: block;
  color: $gray-dark;
  padding: 10px 0;

  &:active,
  &:hover {
    color: $gray-base;
    text-decoration: none;
    background-color: lighten($gray-base, 97%);

    .name {
    }

    .img {
      border: 2px solid #cccccc;
    }

    .img-send {
      border-color: #6abb50;
    }

    .img-import {
      border-color: #4a85d7;
    }
  }

  &::after {
    display: block;
    content: "";
    clear: both;
  }

  .img-container {
    display: block;
    float: left;
    width: 80px;
    height: 50px;
    text-align: center;
    margin-top: 5px;
  }

  .img {
    width: 50px;
    height: 50px;
    padding: 5px;
    font-size: 24px;
    display: inline-block;
    text-align: center;
    color: #fff;
    background-color: $gray-light-2;
    border-radius: 50%;
    border: 2px solid transparent;
    transition: border 0.1s;

    .icon {
      font-size: 24px;
      padding: 6px 0 0 0px;
    }
  }

  .img-send {
    background-color: #43d321;

    .icon {
      font-size: 24px;
      padding: 4px 4px 0 0;
    }
  }

  .img-import {
    background-color: #4a90e2;

    .icon {
      padding: 7px 0 0 4px;
      font-size: 22px;
    }
  }

  .img-accept {
    background-color: #43d321;

    .icon {
    }
  }

  .img-reject {
    background-color: #e20006;

    .icon {
    }
  }

  .img-default {
    background-color: $brand-primary;

    .icon {
      color: #222;
    }
  }

  .text-container {
    margin-left: 90px;

    .name {
      font-size: 16px;
      font-weight: 700;
    }

    .description {
      font-size: 14px;
    }
  }
}

table td.actions {
  text-align: left;
  padding-top: inherit;

  > a {
    display: inline-block;
    margin: 0 2px;
    font-size: 14px;
    color: $gray-light-2;
    text-shadow: none;

    &:hover {
      color: $gray-dark;
    }
  }
}

.shared-photos-box {
  .photos-container {
    margin: 0 -2%;
    padding-bottom: 10px;

    .photo {
      display: inline-block;
      float: left;
      width: 21%;
      margin: 2%;

      img {
        width: 100%;
        height: 100%;
        opacity: 1;
      }

      &:hover {
        img {
          //opacity: 1;
        }
      }
    }
  }
}

.crags-list-box {
  padding: 0;
  list-style-type: none;

  a.crag-name {
    display: block;
    margin: 4px 0;
  }
}

.wide-max .terms {
  height: 70vh;
  overflow: auto;
  background-color: $gray-lighter;
  padding: 5px 10px;
  border: 1px solid $gray-light-2;
  border-radius: 4px;

  h2 {
    text-transform: none;
    font-size: 18px;
    font-weight: bold;
    margin: 15px 0;
  }

  p {
    text-transform: none;
    font-size: 14px;
    margin: 15px 0;
  }

  h3 {
    margin: 25px 0 15px 0;
    text-transform: none;
    font-size: 16px;
    font-weight: bold;
  }
}

.load-more {
  &--loading {
  }
}

.ribbon--pic-of-the-week {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}

.ribbon--pic-of-the-week span {
  font-size: 10px;
  font-weight: bold;
  color: #222;
  text-transform: lowercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 100px;
  display: block;
  background: #ecdc08;
  position: absolute;
  top: 19px;
  right: -21px;
}

.readmore-toggle {
  display: block;
  color: #207bb7;
  text-transform: uppercase;
  font-size: 14px;
  text-align: center;
  margin: 10px 0;

  &::after {
    display: inline-block;
  }

  &:focus,
  &:hover {
    color: #0869b0;
    text-decoration: none;
  }
}

.multiline-read-more {
  position: relative;

  .readmore-gradient {
    display: none;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 30px;
    right: 0;
    background: linear-gradient(
                    to bottom,
                    rgba(255, 255, 255, 0) 0%,
                    rgba(254, 254, 254, 1) 100%
    );
  }

  &.readmore__text-clipped {
    .readmore-gradient {
      display: block;
    }
  }
}

.location-item {
  color: #333;
  display: block;
  margin-left: 20px;
  margin-right: 20px;

  &--fixed-size {
    width: 300px !important;
    display: block !important;
  }

  &--fixed-small {
    margin-left: 10px;
    margin-right: 10px;
    width: 150px !important;
    display: block !important;
  }

  &:focus,
  &:hover {
    text-decoration: none !important;
    color: #212121;

    .location-item__img {
      &__content {
        transform: scale(1.2);
        -webkit-filter: brightness(1);
        filter: brightness(1);
      }
    }
  }

  &__img {
    position: relative;
    width: 100%;
    overflow: hidden;

    &:before {
      content: "";
      display: block;
      padding-top: 100%;
    }

    &__content {
      -webkit-filter: brightness(0.9);
      filter: brightness(0.9);
      transition: all 0.5s;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      color: white;
      text-align: center;
      background-position: center center;
      background-size: cover;
      border-radius: 4px;

      img {
        visibility: hidden;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }
    }
  }

  &__header {
  }

  &__description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-height: 20px; /* fallback */
    max-height: 60px; /* fallback */
  }
}

.swiper-nav {
  $nav_width: 27px;
  height: $nav_width * 2;
  width: $nav_width;
  position: absolute;
  top: 50%;
  transform: translateY(-27px);
  background-color: #fff;
  z-index: 20;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);

  &--next {
    right: 0;
    border-bottom-left-radius: $nav_width * 2;
    border-top-left-radius: $nav_width * 2;
  }

  &--prev {
    left: 0;
    border-bottom-right-radius: $nav_width * 2;
    border-top-right-radius: $nav_width * 2;
  }
}

.swiper-nav__icon {
  height: 20px;
  width: auto;
  position: absolute;
  top: 50%;
  transform: translateY(-10px);
  fill: #ccc;

  &--next {
    right: 4px;
  }

  &--prev {
    left: 4px;
  }
}

.video-embed-container {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  width: 600px;

  @include respond-to(xs) {
    width: 300px;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

ul.list {
  &.list-default {
    li {
      margin-left: 20px;
      font-size: 14px;
      line-height: 150%;
      margin-bottom: 5px;
    }
  }

  &.check-list {
    list-style-type: none;

    li {
      margin-left: 0px;
      padding-left: 30px;
      background-image: url("#{$image_path}blue_li.png");
      background-repeat: no-repeat;
      background-position: left 5px;
      background-size: 14px auto;
    }
  }
}

.facebox .content a.back-to {
  color: #333333;
  text-decoration: none;
}