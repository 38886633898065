#site-publish_premium {
  header {
    .navbar {
      box-shadow: none;
      background: none;
      border: none;
    }
    .nav-container {
      box-shadow: none;
      background: none;
    }
    .logo {
      margin-left: 30px;
    }
  }
  .header-spacer {
    display: none;
  }
}

.publish-premium {
  section.header {
    background: #000;
    .banner {
      .carousel {
        width: 100%;
        overflow: visible;
        min-height: 480px;
        position: relative;
        height: 66vh;

        .banner-slides, .banner-static {
          overflow: hidden;
        }

        .overlay {
          z-index: 5;
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          bottom: auto;
          background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.15) 0%,
            rgba(0, 0, 0, 0.25) 80%,
            rgba(0, 0, 0, 0.8) 100%
          );
          opacity: 1;
        }
        .item {
          display: inline-block;
          width: 100%;
          height: 66vh;
          min-height: 450px;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
          .photographer {
            position: absolute;
            top: 20px;
            right: 20px;
            color: #fff;
            z-index: 10;
            font-size: 14px;
            font-weight: 400;

            @include respond-to(xs) {
              display: none;
            }
          }
        }
        .content-placeholder {
          z-index: 6;
          position: absolute;
          top: 33%;
          width: 100%;
          text-align: center;
          .liftup {
            color: #fff;
            padding: 0;
            margin-left: 30px;
            margin-top: 0;
            h1 {
              font-size: 38px;
              font-family: $font-family-roboto;
              font-weight: 500;
              margin: 0;
              padding: 0;
              line-height: 1.1;
              text-transform: none;
              text-align: center;
              .pretag {
                display: block;
                font-size: 50%;
              }
            }
            h2 {
              font-size: 18px;
              font-family: $font-family-roboto;
              margin: 20px 0;
              line-height: 1.5;
              padding: 0 30px;
              text-transform: none;
              text-align: center;
              .premium {
                font-size: 100%;
                color: #fff;
              }
            }
            .getit {
              text-align: center;
              .btn {
                font-size: 14px;
                padding: 12px 10px;
                width: 190px;
              }
              .btn-buy {
                border-radius: 50px;
                text-align: center;
                background: $brand-primary;
                border: 2px solid $brand-primary;
                .premium {
                  color: #000;
                }
                &:hover {
                }
              }
              .btn-transparent {
                color: #fff;
                border: 2px solid #fff;
                background: transparent;
                transform: none;
                &:hover {
                  border: 2px solid #dadada;
                }
              }
            }
          }
        }
      }
    }
    .container-interest {
      position: relative;
      margin-top: -12vh;
      z-index: 1000;
      width: 100%;
    }
    .box.box-interest {
      padding: 25px 40px 40px 40px;
      background: white;
      border-radius: 10px;
    }
    .interest-item {
      display: table;
      width: 100%;
      .interest-icon {
        display: table-cell;
        width: 54px;
        .circle.circle-check {
          border-radius: 50%;
          display: inline-block;
          width: 27px;
          height: 27px;
          background: #6cd76b;
          color: #fff;
          text-align: center;
          vertical-align: middle;
          line-height: 27px;
          i {
            vertical-align: middle;
          }
        }
      }
      .interest-content {
        display: table-cell;
      }
    }
    .slideshow-container {
      margin-top: 0px;
      .swiper-container {
        .swiper-pagination {
          bottom: 0;
        }
      }
    }
  }
  section.interest {
    padding-top: 100px;
    padding-bottom: 40px;
    .container {
    }
    .aligner {
      max-width: 940px;
      margin: auto;
    }
    .padded {
      padding-right: 10px;
    }
    h3 {
      color: #000;
      margin-top: 20px;
      margin-bottom: 25px;
      font-size: 28px;
    }
    p {
      font-size: 16px;
      line-height: 160%;
      font-weight: 400;
      color: $text-color-gray;
      a {
        text-transform: uppercase;
        font-weight: 600;
        color: #dcc70f;
      }
    }
  }
  section.how-it-works {
    position: relative;
    .banner {
      width: 100%;
      overflow: visible;
      min-height: 400px;
      position: relative;
      height: 55vh;
      background: url("#{$image_path}sesi_bg.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: left center;
    }
    .container__how-it-works {
      position: relative;
      margin-top: -55vh;
      padding-top: 100px;
      z-index: 1;
      width: 100%;
    }
    .how-it-works-slides {
      overflow: hidden;
      .swiper-slide {
        background: transparent;
      }
      .item {
        display: flex;
        align-items: flex-end;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        max-width: 900px;
        .header {
          height: 100%;
          flex: 1;
          text-align: left;
          h3 {
            margin-top: 0;
            font-size: 32px;
            line-height: 42px;
            text-align: left;
          }
        }
        .photo {
          flex: 2;
          text-align: center;
          height: 55vh;
          img {
            max-height: 100%;
          }
        }
        .instructions {
          flex: 1;
          text-align: left;
        }
        /*img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 66vh;
          border-radius: 10px;
          background: transparent;
        }*/
      }
      .quote-slides-pagination {
        display: inline-block;

        .swiper-pagination-bullet {
          width: 10px;
          height: 10px;
          margin: 0 5px;
          cursor: pointer;
        }
      }
    }
  }
  section.knowhow {
    padding-top: 50px;
    padding-bottom: 50px;
    .container__knowhow {
      max-width: 920px;
    }
    .knowhows {
      margin-top: 30px;
      position: relative;
      padding-top: 47px;
    }
    .knowhows__decoration {
      /*background-image: url("#{$image_path}decoration_numbers.png");
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: top center;*/
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 10;
      text-align: center;
      img {
        width: 100%;
      }
    }
    .knowhows__container {
      display: flex;
      height: 100%;
      flex-wrap: wrap;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }
    .knowhow__item {
      display: inline-block;
      width: 31%;
      background-color: #fff;
      border-radius: 10px;
      padding: 25px 30px;
      h3 {
        color: #000;
        margin-top: 20px;
        margin-bottom: 15px;
      }
      p {
        font-size: 14px;
        line-height: 160%;
        font-weight: 400;
        color: $text-color-gray;
      }
    }
  }
  section.quotes {
    padding-top: 50px;
    padding-bottom: 25px;
    img {
      max-width: 100%;
    }
    .row-quote {
      margin-top: 70px;
      .quote {
        h3 {
          text-align: left;
          font-size: 32px;
          color: #000;
          font-weight: bold;
        }
        p {
          font-size: 20px;
          text-align: left;
          color: $text-color-gray;
          font-weight: normal;
          font-style: italic;
          padding-left: 45px;
          background-repeat: no-repeat;
          background-position: 0px 4px;
          background-image: url("#{$image_path}quote_start.png");
        }
      }
    }

    .single-quote {
      border-radius: 10px;
      background-color: #fff;
      padding: 17px 20px 20px;
      z-index: 10;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      .name {
        font-weight: 600;
        padding-left: 45px;
        margin-bottom: 5px;
        color: #000;
      }
      .description {
        color: $text-color-gray;
        padding-left: 45px;
        background-repeat: no-repeat;
        background-position: 0px 4px;
        background-image: url("#{$image_path}quote_start.png");
      }

      @include respond-to(xs) {
      }
    }
  }
  section.action {
    padding-top: 25px;
    padding-bottom: 80px;
    h2 {
    }
    p {
      margin-top: 20px;
      font-size: 16px;
    }
  }
  section.comparison {
    padding-top: 25px;
    padding-bottom: 50px;
    .free-label {
      font-weight: 400;
      padding: 3px 9px;
      border-radius: 12px;
      font-size: 14px;
      display: inline-block;
      background-color: #d3d3d3;
      color: rgba(0, 0, 0, 0.6);
    }
    .premium-label {
      font-weight: 500;
      padding: 3px 10px;
      border-radius: 12px;
      font-size: 14px;
      display: inline-block;
      background-color: $brand-primary;
      color: #000;
    }
    .color-green {
      color: #6cd76b;
    }
    .comparison-table {
      margin-left: auto;
      margin-right: auto;

      tr th:nth-child(2),
      tr th:nth-child(3),
      tr td:nth-child(2),
      tr td:nth-child(3) {
        text-align: center;
      }

      tr td:nth-child(1) {
        font-size: 14px;
      }
    }
  }

  section.requirements {
  }

  section.section-faq {
    padding-top: 50px;
  }

  h2.heading {
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    font-family: $font-family-roboto;
    text-transform: none;
  }
  h3 {
    font-family: $font-family-roboto;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 0px;
    margin-top: 10px;
    padding: 0;
    text-transform: none;
  }
  h4 {
    font-family: $font-family-roboto;
    text-transform: none;
    font-size: 18px;
    font-weight: 700;
    margin-top: 25px;
    margin-bottom: 15px;
    padding: 0;
  }
  p.description {
    font-size: 16px;
    text-align: center;
    margin: 15px 0 20px 0;
    text-transform: none;
  }
  .bg-dark {
    .premium {
      color: #fff;
    }
  }
}

@include respond-to(md) {
  .publish-premium {
    section.quotes {
      // background-color: #fff;
      .row-quote {
        // margin-top: 15px;
        .quote {
          // padding-right: 10px;
          // margin-top: 20px;
          h3 {
            font-size: 26px;
          }
          p {
          }
        }
      }
    }
  }
}
@include respond-to(sm) {
  .publish-premium {
    h2.heading {
      // font-size: 24px;
    }
    section.header {
      .banner .carousel {
        min-height: 300px;
        height: 45vh;
        .item {
          min-height: 300px;
          height: 45vh;
        }
        .content-placeholder {
          bottom: 10vh;
          top: auto;
          .liftup {
            /*background-color: transparent;
            margin: 0px;
            padding: 10px;
            h1 {
              font-size: 32px;
            }
            h2 {
              margin: 10px 0 15px 0;
              font-size: 14px;
              padding: 0;
            }*/
          }
        }
      }

      .box.box-interest {
        padding: 25px 40px 40px 30px;
      }
      .interest-item {
        .interest-icon {
          width: 45px;
        }

        .interest-content {
        }
      }
    }
    section.interest {
      margin-top: 0;
      padding-top: 15px;
      padding-bottom: 0;
      .padded {
        padding-right: 150px;
        padding-left: 80px;
      }
      h3 {
        font-size: 24px;
        margin-bottom: 15px;
      }
      h4 {
      }
      p {
        font-size: 14px;
      }
    }
    section.how-it-works {
      .container__how-it-works {
        padding-top: 60px;
      }
      .how-it-works-slides {
        .item {
          align-items: center;
          flex-direction: column;
          .header {
            display: none;
            h3 {
              font-size: 24px;
            }
            margin-bottom: 15px;
          }

          .photo {
          }
          .instructions {
            text-align: center;
            p {
              padding: 0px 60px;
            }
          }
        }
      }
    }
    section.quotes {
      background-color: #fff;
      .row-quote {
        margin-top: 25px;
        .quote {
          padding-right: 10px;
          margin-top: 20px;
          h3 {
            font-size: 14px;
            padding-left: 45px;
          }
          p {
            margin-top: 10px;
            font-size: 14px;
            font-style: normal;
          }
        }
      }
    }
  }
}
@include respond-to(xs) {
  .publish-premium {
    h2.heading {
      font-size: 24px;
    }
    section.header {
      .banner .carousel {
        min-height: 300px;
        height: 58vh;
        .item {
          min-height: 300px;
          height: 58vh;
        }
        .content-placeholder {
          bottom: 14vh;
          top: auto;
          .liftup {
            background-color: transparent;
            margin: 0px;
            padding: 10px;
            h1 {
              font-size: 32px;
            }
            h2 {
              margin: 10px 0 15px 0;
              font-size: 14px;
              padding: 0;
            }
          }
        }
      }

      .box.box-interest {
        padding: 25px 40px 40px 30px;
      }
      .interest-item {
        .interest-icon {
          width: 45px;
        }

        .interest-content {
        }
      }
    }
    section.interest {
      margin-top: 0;
      padding-top: 15px;
      padding-bottom: 0;
      .padded {
        padding-right: 15px;
        padding-left: 15px;
      }
      h3 {
        font-size: 24px;
        margin-bottom: 15px;
      }
      h4 {
      }
      p {
        font-size: 14px;
      }
    }
    section.how-it-works {
      .container__how-it-works {
        padding-top: 60px;
      }
      .how-it-works-slides {
        .item {
          align-items: center;
          flex-direction: column;
          .header {
            display: none;
            h3 {
              font-size: 24px;
            }
            margin-bottom: 15px;
          }

          .photo {
          }
          .instructions {
            text-align: center;
            p {
              padding: 0px 60px;
            }
          }
        }
      }
    }
    section.quotes {
      background-color: #fff;
      .row-quote {
        margin-top: 15px;
        .quote {
          padding-right: 10px;
          margin-top: 20px;
          h3 {
            font-size: 14px;
            padding-left: 45px;
          }
          p {
            margin-top: 10px;
            font-size: 14px;
            font-style: normal;
          }
        }
      }
    }
  }
}
