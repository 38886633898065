#premium_topo_payments-show {
  background: #fff !important;
}
.receipt {
  width: 210mm;
  min-height: 297mm;
  padding: 20mm;
  margin: 10mm auto;
  background: #fff;
  border: 1px solid #cacaca;
  box-sizing: border-box;
  h1 {
    margin-top: 20px;
    padding-top: 0;
    font-family: $font-family-roboto;
    font-size: 24px;
  }
  .status {
    font-size: 24px;
    color: red;
    font-weight: bold;
    text-transform: uppercase;
  }
  .receiver {
    .business,
    .name {
      font-weight: 700;
    }
    .business + .name {
      font-weight: normal;
    }
    .address1,
    .address2,
    .address3 {

    }
    .postal {

    }
    .state {

    }
    .country {

    }
  }
  .payer {
    margin: 20px 0;
    .name {
      font-weight: 700;
    }
  }
  .invoice-description {
    margin: 20px 0;
  }
  table.invoice-details {
    width: 100%;
    td, th {
      vertical-align: top;
    }
  }
  .lines {
    margin: 20px 0;
  }
}

.receipt + .receipt {
  margin-top: 20mm;
}