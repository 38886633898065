.shop {
  .product-container {
    background-color: #ffffff;
    padding: 20px;
  }
  .photographer {
    display: none;
  }
  .content-container {
    //color: #22;
  }
  .banner {
    img {
      width: 100%;
    }

    .carousel {
      width: 100%;
      overflow: hidden;
      min-height: 80vh;
      position: relative;
      height: auto;

      @include respond-to(xs) {
        min-height: unset;
        height: auto;
        overflow: visible;
      }

      .overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        bottom: auto;
        background-color: #000;
        opacity: .65;
        @include respond-to(xs) {
          opacity: .7;
        }
      }
      .item {
        display: inline-block;
        width: 100%;
        height: 100%;
        min-height: 80vh;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;

        @include respond-to(xs) {
          min-height: unset;
          height: auto;
          overflow: visible;
        }

        /*@include respond-to(sm) {
          height: 100%;
        }*/

        .photographer {
          display: none;
        }
      }
    }
    .banner-placeholder {
      position: relative;
      top: auto;
      padding-top: 100px;
      padding-bottom: 25px;
      width: 100%;
      bottom: auto;
      text-align: center;

      @include respond-to(sm) {
        padding-top: 50px;
      }
      @include respond-to(xs) {
        top: 30px;
        bottom: auto;
        position: relative;
        padding: 15px 0 50px 0;
      }

      h1 {
        color: #222222;
        //font-size: 80px;
        height: auto;
        overflow: visible;
        font-weight: 400;
        text-transform: uppercase;
        font-family: $font-family-roboto;
        margin: 0;
        padding: 0;

        @include respond-to(md) {
          font-size: 64px;
        }
        @include respond-to(sm) {
          font-size: 44px;
        }
        @include respond-to(xs) {
          font-size: 30px;
        }
      }
      h2 {
        color: #222222;
        font-family: $font-family-roboto;
        font-weight: 400;
        font-size: 20px;
        text-transform: uppercase;
        margin: 0;
        padding: 0;
        @include respond-to(sm) {
          font-size: 16px;
        }
        @include respond-to(xs) {
          font-size: 12px;
          margin-bottom: 4px;
        }
      }
      p {
        font-size: 16px;
        margin: 35px 0 15px 0;
        @include respond-to(sm) {
          margin: 15px 0 15px 0;
        }
        @include respond-to(xs) {
          font-size: 14px;
          margin: 15px 0 20px 0;
        }
      }
      #mobiledetails {
        margin: 20px 0;
      }
      ul {
        margin: 0 0 0 20px;
        li {
          font-size: 16px;
          @include respond-to(xs) {
            font-size: 14px;
          }
          margin: 5px 0;
        }
      }
    }
  }
  .description {
    margin: 40px 0;
    p {
      text-align: center;
      font-size: 16px;
    }
  }
  .shopify-frame {
    margin-bottom: 30px;
  }

}