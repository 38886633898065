.ads-form {
  .img {
    float: left;
    padding: 0 15px 10px 0;
  }
}
#ads-edit{
  .select-category {
    display: none;
  }
}
#ads-new{
  .select-category {
    display: block;
  }
}
.crags-ad {
  display: block;
  background-size: cover;
  img {
    width: 100%;
    height: auto;
    visibility: hidden;
  }
}
.crags-ad__topo_banner {
  max-width: 100%;
  margin: 15px 0;
}
.crags-ad__crag_banner {
  width: 100%;
}
.ads-table {
  a {
    color: $link-color-blue;
  }
}