form {
  input[type=text], input[type=password] {
    &.input-field--line {
      border-bottom: 1px solid rgba(0,0,0,0.15);
      border-top: none;
      border-right: none;
      border-left: none;
      box-shadow: none;
      border-radius: 0;
      text-align: center;
      &:focus {
        border: none;
        border-bottom: 1px solid rgba(0,0,0,0.6);
        box-shadow: none;
      }
    }
  }
  .fieldWithErrors {
    input[type=text], input[type=password] {
      &.input-field--line {
        border: none;
        box-shadow: none;
        border-bottom: 1px solid rgba(226, 0, 6, 0.6);
      }
    }
  }
}