.banner.topo-banner {
  .carousel {
    height: 260px;
    .item {
      height: 260px;
    }

    @include respond-to(sm) {
      height: 230px;
      .item {
        height: 230px;
      }
    }
  }
  .banner-placeholder {
    text-align: center;
    bottom: 0;

    h1 {
      @include respond-to(sm) {
        font-size: 36px;
      }
    }
    .tabs {
      padding-top: 50px;
      .tab {
        display: inline-block;
        margin: 0 15px;
        a {
          display: inline-block;
          background-color: #fff;
          color: #222;
          font-size: 16px;
          font-weight: 700;
          padding: 10px 15px 9px 15px;
          border-radius: 4px 4px 0 0;
          min-width: 300px;
        }
        &.active {
          a:hover {
            text-decoration: none;
          }
        }
        &.free-topo {
          a {

          }
        }
        &.premium-topo {
          a {
            background-color: $brand-primary;
          }
        }
      }
    }
  }
}

.attraction {
  padding: 90px 0;
  @include respond-to(sm) {
    padding: 60px 0;
  }
  h1 {

  }
  p {

  }
}
.intereset {
  padding: 50px 0;
  .additional-description {
    margin-top: 5px;
    margin-bottom: 20px;
  }
  iframe {
    margin: 20px auto;
  }
}
.desire {

}
.create-topo-page {
  font-size: 16px;
  p {
    font-size: 16px;
  }
  h4 {
    text-transform: none;
    margin: 20px 0;
  }
  p.first-letter {
    &::first-letter {
      font-weight: bold;
    }
  }
  ol {
    margin-left: 20px;
  }
  .section-top-padding {
    padding-top: 50px;
  }

  .footer-banner {
    .carousel {
      height: 300px !important;
      .item {
        height: 300px !important;
      }
    }
    .banner-placeholder {
      bottom: 20px !important;
      h2 {
        color: #fff;
        text-transform: none;
        font-size: 36px;
        font-weight: 700;
        font-family: $font-family-roboto;
      }
      p {
        font-size: 16px;
        color: #fff;
      }
    }
  }

  @include respond-to(sm) {
    font-size: 14px;
    p {
      font-size: 14px;
    }
  }
}
.benefit-item {
  display: block;
  text-align: center;
  &:hover {
    text-decoration: none;
  }
  .img {
    display: inline-block;
    border-radius: 50%;
    height: 140px;
    width: 140px;
    border: 5px #E6E6E6 solid;
    background-color: #fff;
    position: relative;
    .placeholder {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      margin-top: -32px;
    }
    img {
    }
  }
  .name {
    font-weight: bold;
    font-size: 18px;
    color: $gray-base;
    margin: 10px 0 15px 0;
  }
  .description {
    color: $gray-base;
    font-size: 16px;
  }

  @include respond-to(sm) {
    .img {
      border: 4px #E6E6E6 solid;
      height: 100px;
      width: 100px;
      .placeholder {
        margin-top: -22px;
      }
      img {
        height: 44px;
      }
    }
    .name {
      font-size: 14px;
    }
    .description {
      font-size: 14px;
    }
  }
}