.photo {
  text-align: center;

  &.container {
    padding: 0;
  }

  .image-row {
    display: block;
    background-color: #fff;
    border-radius: $border-radius-base;

    a {
      color: $link-color-blue;
    }

    .row {
      margin: 0;
    }
  }

  .edit-form {
    display: none;
  }

  .image-container {
    padding: 0;
    vertical-align: middle;
    text-align: center;
    background-color: #222;
    border-radius: $border-radius-base 0 0 $border-radius-base;
    @include respond-to(xs) {
      border-radius: $border-radius-base $border-radius-base 0 0;
    }
    overflow-x: hidden;

    .video {
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      overflow: hidden;
      max-width: 100%;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    .image {
      img {
        //border-radius: 4px 0 0 4px;
        display: inline-block;
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 85vh;
        @include respond-to(xs) {
          max-height: none;
        }
      }

      .prev,
      .next {
        top: 0;
        bottom: 0;
        width: 50px;
        opacity: 0;
        transition: opacity 0.3s;
        cursor: pointer;

        .arrow {
          position: absolute;
          top: 0;
          bottom: 0;
          width: 40px;
          opacity: 0.3;

          &:hover {
            opacity: 1;
          }
        }
      }

      &:hover {
        .prev,
        .next {
          transition: opacity 0.3s;
          opacity: 1;
        }
      }

    }

    .image .prev {
      left: -50px;

      .arrow {
        left: 0;
        background: url("#{$image_path}next-prev.png") left center no-repeat;
      }
    }

    .image .next {
      right: -50px;

      .arrow {
        right: 0;
        background: url("#{$image_path}next-prev.png") right center no-repeat;
      }
    }
  }

  .image-details {
    text-align: left;

    .climber-follow-container {
      margin: 15px 0;
      clear: both;
    }

    .climber {
      float: left;
      display: block;
      text-decoration: none;

      .climber-thumb {
        vertical-align: middle;
        border: 2px solid $gray-lighter;
      }

      .climber-name {
        vertical-align: middle;
        display: inline-block;
        margin-left: 10px;
        color: $gray-dark;
        font-weight: 700;
      }
    }

    .btn-follow {
      float: right;
      margin-top: 3px;
    }

    .shares {
      margin: 15px 0;

      .btn-like {
        font-size: 18px;
        color: $gray-light;

        .text {
          display: none;
        }
      }

      .likes {
        margin: 0;
        display: inline-block;

        .climber-thumb {
          width: 25px;
          height: 25px;
        }

        a {
          text-decoration: none;
        }
      }

      .addthis_sharing_toolbox {
        margin: 0;
        float: right;
      }

      .sharetools {
        a {
          font-size: 18px;
          color: $gray-light-2;
          text-decoration: none;
          display: inline-block;
          margin: 0 5px;

          &:hover {
            color: $gray-light;
          }
        }
      }
    }

    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }
}


#videos-show,
#photos-show {
  background: $gray-lighter !important;

  .photo {
    @extend .container;
    margin: 30px auto;
  }
}

#facebox .content .photo {
  margin: 0px;

  .row {
    margin: 0;
  }
}


.photo-wall {
  display: block;

  .news {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: inline-block;
    position: relative;
    overflow: hidden;
    border-radius: $border-radius-base;

    .bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-size: cover;
      background-position: center center;

      opacity: 1;
      //transition: opacity .1s;
    }

    .del_x {
      display: block;
      position: absolute;
      z-index: 1;
      top: 10px;
      right: 10px;
      height: 15px;
      width: 15px;
      font-size: 15px;
      text-decoration: none;
      color: #fff;

      &:hover {
        color: $gray-dark;
      }
    }

    .climber-thumb {
      border-radius: 50%;
      width: 40px;
      height: 40px;
      border: 2px solid #fff;

      @include respond-to(xs) {
        width: 30px;
        height: 30px;
      }
    }

    .thumbnail {

    }

    .time {
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 1;
      font-size: $font-size-small;
      color: #fff;

      @include respond-to(xs) {
        font-size: $font-size-xsmall;
        z-index: 0;
        top: 15px;
        left: 15px;
      }
    }

    .content {
      display: block;

      width: 190px;
      height: 190px;

      @include respond-to(xs) {
        width: 130px;
        height: 130px;
      }

      &.size-2x2 {
        width: 380px;
        height: 380px;

        @include respond-to(xs) {
          width: 260px;
          height: 260px;
        }
      }

      &.size-2x1 {
        width: 380px;
        height: 190px;
        @include respond-to(xs) {
          width: 260px;
          height: 130px;
        }
      }

      &.size-1x2 {
        width: 190px;
        height: 380px;
        @include respond-to(xs) {
          width: 130px;
          height: 260px;
        }
      }


      .description {

      }
    }


    .description-overlay {
      opacity: 0;
      transition: opacity 0.3s;
      background: rgba(0, 0, 0, 0.5);
      word-wrap: break-word;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      font-family: $font-family-roboto;
      color: #fff;

      .text {
        width: 100%;
        text-align: center;
        padding: 0 10px;
      }

      .name {
        font-family: $font-family-roboto;
        font-weight: 500;
        // text-transform: uppercase;
        font-size: 16px;
        // display: none;
      }
    }

    .share-photo {
      font-weight: 500;

      .icon {
        font-size: 30px;
      }

    }

    > a {
      text-decoration: none;

      &:hover {
        .bg {
          //opacity: 1;
          //background-color:$brand-primary;
        }

        .description-overlay {
          opacity: 1;
          transition: opacity 0.3s;
        }

        .climber-name {
          opacity: 1;
        }
      }
    }

    b {
      font-weight: 700;
    }

    .bg {
      opacity: 0.95;
      //background-color:darken($brand-primary, 3);
      background-color: $brand-primary;
    }

    .content {
      font-size: $font-size-small;

      @include respond-to(xs) {
        font-size: $font-size-xsmall;
      }
      color: $gray-darker;
      display: table-cell;
      vertical-align: middle;
      padding: 15px;

      a {
        color: $gray-darker;
        font-weight: 700;
      }

      .title-container {
        display: block;
        position: relative;
        bottom: auto;
        top: auto;
        left: auto;
        width: 100%;
        z-index: 1;
        padding: 0;

        .title-row {
          display: block;
          text-align: center;

          .climber-thumb {
            display: inline-block;
            vertical-align: top;
          }

          .climber-name {
            color: #fff;
            opacity: 0;
          }

          .title {
            display: block;
            vertical-align: middle;
            width: 100%;
            text-align: left;
            padding: 10px 0;
            @include respond-to(xs) {
              padding: 5px 0;
              max-height: 60px;
            }
          }
        }
      }

      .description {
        font-size: $font-size-small;

        @include respond-to(xs) {
          font-size: $font-size-xsmall;
          height: 45px;
        }

        p {
          font-size: $font-size-small;
          @include respond-to(xs) {
            font-size: $font-size-xsmall;
          }
          margin: 0;
        }

        display: block;
        position: relative;
      }
    }

    .content.photo-content {
      color: #fff;

      .title-container {
        position: absolute;
        bottom: 0px;
        left: 0px;
        padding: 15px;
        width: 100%;
        z-index: 1;

        .title-row {
          display: table;
          width: 100%;

          .title {
            display: table-cell;
            vertical-align: middle;
            font-size: $font-size-small;
            @include respond-to(xs) {
              font-size: $font-size-xsmall;
            }
          }

          .climber-thumb {
            display: table-cell;
            vertical-align: top;
          }

          .climber-thumb + .title {
            padding: 0 0 0 10px;
          }

          .title-row-tags {
            display: table-cell;
            text-align: right;
            vertical-align: middle;
          }
        }
      }

      a {
        color: $brand-primary;

        &:hover {
          //color: $brand-primary;
        }
      }

      .description {
        position: relative;
      }

      .description a {
        display: none;

        &:first-of-type {
          display: block;

          img {
            width: auto;
            height: auto;
            visibility: hidden;
          }
        }
      }

    }

    &.type-topo {

    }

    &.feedad {
      display: none;
    }
  }
}
