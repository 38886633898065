// The top level header box

$header-height: 94px;
$header-height-shrinked: 50px;

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $header-height-shrinked;
  z-index: 1030;
  &.navbar {
    border: none;
    box-shadow: 0 1px 3px #010101;
  }
  .navbar-search {
    display: inline-block;
    vertical-align: middle;
    .navbar-search-button {
      margin-left: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #464646;
      color: rgba(255, 255, 255, 0.45);
      height: 40px;
      padding: 8px 10px;
      border-radius: 4px;
      background: #464646;
      font-size: 16px;
      font-weight: 300;
      transition: background 0.3s, color 0.3s;
      &__label {
        display: inline-block;
        padding: 0 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @include respond-to(sm) {
          width: 150px;
          font-size: 14px;
        }
      }
      .glyphicon {
        font-size: 21px;
      }
      &:hover {
        background: #666666;
        color: rgba(255, 255, 255, 0.85);
      }
    }
  }

  .nav-container {
    line-height: $header-height-shrinked;
    transition: line-height 0.3s;
    background: #222;
    @include respond-to(xs) {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .navbar-collapse {
    &.collapse.collapse--mobile {
      display: none !important;
    }
    &.collapse.collapse--mobile.in {
      display: none; //hide menu when changing mobile to desktop
      @include respond-to(xs) {
        display: block !important;
      }
    }
  }
  .navbar-header {
    vertical-align: middle;

    .logo {
      line-height: 1;
      background-image: url("#{$image_path}logo.png");
      background-repeat: no-repeat;
      background-size: cover;
      display: inline-block;
      vertical-align: middle;
      height: 30px;
      width: 100px;
      @include respond-to(sm) {
        height: 30px;
        width: 30px;
      }
      @include respond-to(xs) {
        margin-left: 15px;
      }
      background-position: left center;
    }

    // DESKTOMENU
    &--desktop {
      display: block;
      float: none;
      @include respond-to(xs) {
        display: none;
      }
      @include respond-to(sm) {
        .navbar-search {
          .navbar-search-button {
            margin-left: 10px;
          }
        }
      }

      .notifications-menu-component {
        .nav-item-label {
          display: none;
        }
      }

      .nav {
        > li {
          > a {
            color: #fafafa;
            text-transform: uppercase;
            font-weight: 500;
            @include respond-to(md) {
              padding-left: 10px !important;
              padding-right: 10px !important;
            }
            @include respond-to(sm) {
              padding-left: 9px !important;
              padding-right: 9px !important;
            }
            &:hover {
              color: $brand-primary;
            }
          }
        }
      }

      .nav-item {
        display: inline-block;
        height: 100%;
        .nav-item-container {
          display: inline-block;
          vertical-align: middle;
          position: relative;
          &--notifications {
            padding-left: 10px;
            padding-right: 10px;
            color: #fafafa;
          }
          .glyphicon {
            font-size: 21px;
            vertical-align: middle;
          }
          .nav-item-icon {
            color: rgba(255, 255, 255, 0.65);
          }
        }
      }

      .dropdown.open .nav-item {
        background: #000;
        .nav-item-icon {
          color: #fff !important;
        }
      }
    }

    // MOBILEMENU
    &--mobile {
      display: none;
      @include respond-to(xs) {
        display: block;
      }
      .navbar-toggle {
        margin-right: 0;
        padding-right: 20px;
        .icon-bar {
          width: 24px;
          height: 3px;
          background-color: rgba(255, 255, 255, 0.85);
        }
        .icon-bar + .icon-bar {
          margin-top: 5px;
        }
      }
      .nav-item {
        display: inline-block;
        height: 100%;
        .nav-item-container {
          position: relative;
        }
      }
    }
    @include respond-to(xs) {
      .dropdown {
        position: static;
        .dropdown-menu {
          width: 96%;
          left: 2%;
        }
      }
    }

    button {
      background-color: transparent !important;
      border: none !important;
      float: right;
    }
    .mobile-header-item {
      display: none;
      float: right;
      margin: 0 2px;
      > a,
      a.dropdown-toggle {
        display: block;
        padding: 0 10px;
      }
      .glyphicon {
        font-size: 20px;
        vertical-align: middle;
        color: rgba(255, 255, 255, 0.65);
      }
      .nav-item-icon {
      }
      .nav-item-label {
        display: none;
      }
      @include respond-to(xs) {
        display: inline-block;
      }
      .navbar-search {
        .navbar-search-button {
          margin-left: 0;
          background: transparent;
        }
      }
    }
  }

  .label-notification_count {
    box-sizing: border-box;
    font-size: 10px;
    background: #f33410;
    border-radius: 50%;
    color: #ffffff;
    display: inline-block;
    height: 18px;
    width: 18px;
    position: absolute;
    text-align: center;
    font-weight: 400;
    top: 8px;
    right: 2px;
    line-height: 18px;
    @include respond-to(xs) {
      right: -9px;
    }
  }

  .main-menu {
    height: 100vh;
    overflow: hidden;
    .nav {
      padding-left: 15px;
      > li {
        > a {
          text-transform: uppercase;
          color: #fafafa !important;
          font-size: 13px;
        }
        @include respond-to(xs) {
          border-bottom: 1px solid #464646;
          &.open a {
            background: transparent !important;
            padding: 15px 0 5px;
          }
          .open .dropdown-menu > li > a {
            color: #fafafa;
            font-size: 12px;
          }
          > a {
            padding: 13px 0;
          }
        }
        .premium {
          color: #fff;
        }
        &.get-premium {
          text-align: left;
          a {
            padding: 10px;
            color: #000 !important;
          }
        }
      }
      @include respond-to(xs) {
        &.navbar-nav {
          padding: 0;
          margin: 7.5px;
        }
      }
    }
    $close-button-size: 22px;
    .close-btn {
      color: #fff !important;
      position: relative;
      display: inline-block;
      width: $close-button-size;
      height: $close-button-size;
      background: transparent;
      opacity: 0.8;
      transition: opacity 0.2s;
      vertical-align: middle;
      &:hover {
        opacity: 1;
      }

      &:after,
      &:before {
        position: absolute;
        cursor: pointer;
        border-radius: 1px;
        height: 2px;
        width: $close-button-size;
        background: white;
        display: block;
        content: "";
        top: 10px;
      }
      &:before {
        transform: rotate(45deg);
        -ms-transform: rotate(45deg); /* IE 9 */
        -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
      }
      &:after {
        transform: rotate(-45deg);
        -ms-transform: rotate(-45deg); /* IE 9 */
        -webkit-transform: rotate(-45deg); /* Chrome, Safari, Opera */
      }
    }
    &.collapsing,
    &.collapse.in {
      min-height: 90vh;
      min-height: calc(100vh - 50px);
      max-height: none;
    }
    @include respond-to(xs) {
      .js-toggle-navmode {
        display: none !important;
      }
    }
  }

  .searchbox {
    line-height: 20/14;
    display: inline-block;
    width: 350px;
    vertical-align: middle;
    padding-right: 0;

    .searchbox-group {
      width: 300px;
      .search-field {
        &:focus {
          outline: 0px !important;
          -webkit-appearance: none;
          border-color: #ccc;
          box-shadow: none !important;
        }
      }
      .search_text {
        border-radius: 4px 0 0 4px;
      }
      .btn-wrapper {
        button {
          margin-left: -2px;
          border-left: none;
          border-radius: 0 4px 4px 0;
          i {
            line-height: 1;
          }
          span {
            display: none;
          }
        }
      }
    }

    @include respond-to(xs) {
      display: block;
      width: 100%;
      .searchbox-group {
        width: 100%;
      }
    }
  }
  .controls {
    float: right;
    @include respond-to(xs) {
      float: none !important;
      text-align: center;
    }
  }
  .notifications {
    visibility: hidden;
    display: none;
    .glyphicon {
      color: rgba(255, 255, 255, 0.8);
      font-size: 20px;
      line-height: 28px;
    }
    .badge {
      margin-top: -20px;
      margin-left: -6px;
    }

    &:hover {
      .glyphicon {
        color: rgba(255, 255, 255, 1);
      }
    }
  }
  .user-menu {
    .nav .open > a {
      background-color: #000000;
      border-color: transparent;
    }
    .nav a {
      //color: #fafafa;
    }
    .nav-item-label {
      display: none;

      @include respond-to(xs) {
        display: block;
      }
    }
    .user-menu-image {
      height: 35px;
      margin-top: -10px;
      margin-bottom: -10px;
    }
    @include respond-to(xs) {
      padding-top: 0px;
      padding-bottom: 115px !important;
    }

    ul {
      li:last-child {
        border-bottom: none;
      }
      .onboarding-container {
        width: 210px;
      }
    }

    //.login {
    .join-btn {
      font-size: 13px;
      font-weight: 700;
      padding: 0.6em 1.3em;
      border-radius: 25px;
    }
    .login-btn {
      font-size: 13px;
      font-weight: 700;
      padding: 0.7em 1.5em;
      background: transparent;
      color: #fff;
      border: 1px solid #fff;
      border-radius: 25px;
      margin-right: 15px;
      &:hover {
        color: $brand-primary;
        border: 1px solid $brand-primary;
      }
    }
    .btn-primary {
      display: none;
    }
    // }

    .user-menu-notifications {
      position: absolute;
      top: 7px;
      left: 8px;
      line-height: normal;
      z-index: 1;
      background: #4d92df;
      color: #fff;
      border-radius: 10px;
      padding: 2px 6px;
    }
  }

  .user-menu--mobile {
    .user-menu__user {
      border-bottom: none !important;
      padding-top: 20px;
      a {
        text-transform: none !important;
        font-weight: 700;
        img {
          margin-right: 15px;
        }
      }
    }
    .onboarding-container {
      border-bottom: none !important;
      width: auto !important;
    }
    .onboarding-progress {
      padding: 5px 0 !important;
    }
  }

  /* technical dept overrides to be removed later */
  ul {
    li {
      &:before {
        content: none;
      }
    }
  }
  img {
    vertical-align: middle;
  }

  // utils
  .get-premium {
    text-align: center;
    transition: transform 0.1s ease;
    a {
      display: inline-block;
      background-color: $brand-primary !important;
      border-radius: 2px;
      color: #222 !important;
      padding: 8px 10px;
      vertical-align: middle;
      line-height: 1;
      transform: scale(1);
      .offer-tag {
        font-size: 9px;
        display: block;
        text-transform: none;
        font-weight: 400;
      }
    }
    &:hover {
      -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1);
    }

    .offer-tag-description {
      display: inline-block;
      text-transform: none;
      font-weight: 400;
      color: #fff;
      margin-left: 10px;
    }
  }

  .onboarding-progress {
    font-size: 10px !important;
    text-transform: none !important;
  }
}
.header-spacer {
  padding-top: $header-height-shrinked;
}
.notice-container {
  position: fixed;
  top: 65px;
  left: 15px;
  right: 15px;
  line-height: 1.5;
  @include respond-to(md-min) {
    width: 300px;
    left: auto;
  }
  .notice {
    color: #fff;
    padding: 10px 15px;
    text-align: left;
    font-size: 14px;
    color: #222;
    padding: 10px 15px;
    text-align: left;
    font-size: 12px;
    background: #fff;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    .close {
      float: none;
      display: inline-block;
      margin-left: 5px;
      font-size: 22px;
      font-weight: normal;
      line-height: 12px;
      color: #fff;
      text-shadow: none;
      opacity: 0.9;
      &:hover {
        opacity: 1;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      margin: 0;
      padding: 0;
      line-height: 1.5;
      font-size: 14px;
      font-weight: 700;
      font-family: $font-family-roboto;
    }
    p {
      margin-bottom: 0;
    }
  }
  .notice + .notice {
    margin-top: 10px;
  }
  z-index: 1050;
}

.user-visitor #feed_items-index {
  .header-spacer {
    padding-top: $header-height;

    @include respond-to(xs) {
      padding-top: $header-height-shrinked;
    }
  }

  header {
    height: $header-height;

    @include respond-to(xs) {
      height: $header-height-shrinked;
    }

    .nav-container {
      line-height: $header-height;

      @include respond-to(xs) {
        line-height: $header-height-shrinked;
      }
    }
    &.shrinked {
      &.front-page {
        //height: $header;
      }
      .nav-container {
        line-height: $header-height-shrinked;
      }
    }
  }
}
.nav-mobile {
  .mobile-menu-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 12.5px 22.5px;
    background: #222;
    .get-premium {
      padding: 15px;
      font-weight: 500px;
    }
  }
}

#user-menu {
  .login {
    .btn-primary {
      display: inline-block;
    }
    .dropdown-menu {
      &:after {
        top: -7px;
        right: 25px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 8px solid #fafafa;
      }
    }
  }
  @include respond-to(xs) {
    display: block;
    text-align: center;
    .dropdown {
      display: block;
      .dropdown-menu {
        display: block;
        text-align: center;
        position: relative;
        background: none;
        border: none;
        box-shadow: none;
        width: 100%;
        &:after {
          display: none;
        }
        a {
          color: #fafafa;
          font-size: 12px;
          &:hover {
            background-color: transparent !important;
          }
        }
      }
    }
  }
}

.navbar-nav > li > .dropdown-menu {
  margin-top: 2px;
  border-radius: 4px;
}
.search_user-menu_container {
  @include respond-to(xs) {
    text-align: center;
  }
}

.custom-disable {
  pointer-events: none;
}

.unscrollable {
  overflow-y: hidden;
}
