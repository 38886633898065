#subscription_plans-index {
  header {
    .navbar {
      box-shadow: none;
      background: none;
      border: none;
    }

    .nav-container {
      box-shadow: none;
      background: none;
    }

    .logo {
      margin-left: 30px;
    }
  }

  .header-spacer {
    display: none;
  }
}

.subscription-plans {
  .banner {
    .carousel {
      width: 100%;
      overflow: visible;
      min-height: 480px;
      position: relative;
      height: 66vh;

      .banner-slides, .banner-static {
        overflow: hidden;
      }

      .overlay {
        z-index: 5;
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        bottom: auto;
        background: linear-gradient(
                        to bottom,
                        rgba(0, 0, 0, 0.15) 0%,
                        rgba(0, 0, 0, 0.25) 80%,
                        rgba(0, 0, 0, 0.8) 100%
        );
        opacity: 1;
      }

      .item {
        display: inline-block;
        width: 100%;
        height: 66vh;
        min-height: 450px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;

        .photographer {
          position: absolute;
          top: 20px;
          right: 20px;
          color: #fff;
          z-index: 10;
          font-size: 14px;
          font-weight: 400;

          @include respond-to(xs) {
            display: none;
          }
        }
      }

      .content-placeholder {
        z-index: 6;
        position: absolute;
        top: 33%;
        width: 100%;
        text-align: left;

        .banner-addon {
          position: relative;
          width: 320px;
          height: 586px;
          background-image: url("#{$image_path}premium_topo_iphone.png");
          background-position: 0 0;
          background-repeat: no-repeat;
          background-size: 100%;

          .iphone-screens {
            position: absolute;
            top: 63px;
            left: 39px;
            width: 238px;
            height: 422px;
            overflow: hidden;

            img {
              width: 100%;
            }
          }
        }

        .liftup {
          color: #fff;
          padding: 0;
          margin-left: 30px;
          margin-top: 0;

          h1 {
            font-size: 38px;
            font-family: $font-family-roboto;
            font-weight: 500;
            margin: 0;
            padding: 0;
            line-height: 1.1;
            text-transform: none;
            text-align: center;

            .pretag {
              display: block;
              font-size: 50%;
            }
          }

          h2 {
            font-size: 18px;
            font-family: $font-family-roboto;
            margin: 20px 0;
            line-height: 1.5;
            padding: 0 30px;
            text-transform: none;
            text-align: center;

            .premium {
              font-size: 100%;
              color: #fff;
            }
          }
        }
      }
    }

    .carousel.black-friday {

      .content-placeholder {
        top: 29%;

        .liftup {
          h2.note {
            font-weight: 700;
            text-transform: uppercase;
          }

          h1 {
            font-size: 70px;
          }
        }

      }

      .overlay {
      }
    }
  }

  .slideshow-container {
    margin-top: 0px;

    .swiper-container {
      .swiper-pagination {
        bottom: 0;
      }
    }
  }

  .premium-crags {
    margin-top: 10px;
  }

  .more-locations {
    margin-top: 30px;
  }

  .getit {
    text-align: center;

    .btn {
      font-size: 14px;
      padding: 12px 10px;
      width: 190px;
    }

    .btn-buy {
      border-radius: 50px;
      text-align: center;
      background: $brand-primary;
      border: 2px solid $brand-primary;

      .premium {
        color: #000;
      }

      &:hover {
      }
    }

    .btn-transparent {
      color: #fff;
      border: 2px solid #fff;
      background: transparent;
      transform: none;

      &:hover {
        border: 2px solid #dadada;
      }
    }
  }

  h2.heading {
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    font-family: $font-family-roboto;
    text-transform: none;
  }

  h3 {
    font-family: $font-family-roboto;
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 0px;
    padding: 0;
    text-transform: none;
  }

  h4 {
    font-family: $font-family-roboto;
    text-transform: none;
    font-size: 18px;
    font-weight: 700;
    margin-top: 25px;
    margin-bottom: 15px;
    padding: 0;
  }

  p.description {
    font-size: 16px;
    text-align: center;
    margin: 15px 0 20px 0;
    text-transform: none;
  }

  .intereset {
    margin-top: 45px;

    p {
      font-weight: 300;
      font-size: 16px;
    }

    .getit {
      margin-top: 35px;
      text-align: left;
    }
  }

  .bg-dark {
    .premium {
      color: #fff;
    }
  }

  .subscription-box {
    padding: 10px;
    background-color: #f3f3f3;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    position: relative;

    .old-price {
      font-size: 16px;
      text-decoration: line-through;
    }

    ul.benefits li.centered {
      list-style-image: none;
      background: transparent;
      padding-left: 0;
      text-align: center;
      font-weight: 700;
    }

    .ribbon {
      position: absolute;
      left: -6px;
      top: -7px;
      z-index: 1;
      overflow: hidden;
      width: 75px;
      height: 75px;
      text-align: right;
    }

    .ribbon span {
      font-size: 11px;
      font-weight: bold;
      color: #fff;
      text-transform: uppercase;
      text-align: center;
      line-height: 22px;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      width: 100px;
      display: block;
      background: #79a70a;
      background: linear-gradient(#2989d8 0%, #1e5799 100%);
      box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
      position: absolute;
      top: 19px;
      left: -21px;
    }

    .ribbon span::before {
      content: "";
      position: absolute;
      left: 0px;
      top: 100%;
      z-index: -1;
      border-left: 3px solid #1e5799;
      border-right: 3px solid transparent;
      border-bottom: 3px solid transparent;
      border-top: 3px solid #1e5799;
    }

    .ribbon span::after {
      content: "";
      position: absolute;
      right: 0px;
      top: 100%;
      z-index: -1;
      border-left: 3px solid transparent;
      border-right: 3px solid #1e5799;
      border-bottom: 3px solid transparent;
      border-top: 3px solid #1e5799;
    }

    .ribbon.alt span {
      background: linear-gradient(#f44b00 0%, #c84500 100%);

      &::before {
        border-left-color: #b54000;
        border-top-color: #b54000;
      }

      &::after {
        border-right-color: #b54000;
        border-top-color: #b54000;
      }
    }

    .btn-pay,
    .stripe-button-el {
      display: block;
      width: 100%;
      font-size: 18px;
      padding: 12px 0;

      span {
        font-size: 18px !important;
        display: block;
        width: 100%;
      }
    }

    &.oneday {
    }

    &.monthly {
    }

    .help-block {
      text-align: center;
    }

    .price {
      font-family: $font-family-roboto;
      font-size: 25px;
      margin: 10px 0 17px;
      font-weight: 700;

      .period {
        font-size: 14px;
        color: #656565;
        font-weight: 400;
      }
    }

    hr {
      border-top-color: #d9d9d9;
      margin: 10px 0;
    }
  }

  .subscription-box.black {
    background-color: #222;
    color: #fff;
    border-color: #000;

    .ribbon {
      span {
        background: linear-gradient(#e4cd29 0%, #f3dc10 100%);
        color: #000;

        &::before {
          border-left-color: #a59509;
          border-top-color: #a59509;
        }

        &::after {
          border-right-color: #a59509;
          border-top-color: #a59509;
        }
      }
    }

    .price {
      .period {
        color: #fff;
      }
    }
  }

  .plan-name {
    font-family: $font-family-roboto;
    text-transform: none;
    font-weight: 700;
  }

  section.intereset {
    .visualization {
      max-width: 100%;
    }
  }

  section.action {
    padding-top: 50px;
    background-color: #fff;

    ul.benefits li {
      font-weight: 400;
    }

    .benefits-container {
      padding: 0 15px;
      margin: 10px 0;
    }

    .current-plan {
      text-align: center;
      padding: 14px 0 13px 0;
      font-size: 15px;
    }

    .subscription-name {
      font-size: 25px;
      font-family: $font-family-roboto;
      font-weight: 500;
      margin-bottom: 20px;
      text-transform: none;
    }

    .get-it {
      margin-top: 25px;

      a {
        color: #222;
      }
    }

    .remove-ads {
      .stripe-button-el,
      .btn-pay {
        background: transparent !important;
        border: none !important;
        border-radius: 0 !important;
        box-shadow: none !important;
        font-weight: 400;

        &:hover span {
          text-decoration: underline !important;
        }
      }
    }
  }
}

@include respond-to(md) {
  .subscription-plans .banner .carousel {
    .content-placeholder {
      .liftup {
        h1 {
          font-size: 38px;
        }

        h2 {
          margin: 15px 0 20px 0;
          font-size: 16px;
        }
      }

      .banner-addon {
        width: 320px;
        height: 586px;
      }
    }
  }
}

@include respond-to(sm) {
  .subscription-plans .banner .carousel {
    .content-placeholder {
      .liftup {
        margin-left: 0px;
        margin-top: 40px;
        padding: 37px 46px 20px 37px;

        h1 {
          font-size: 28px;
        }

        h2 {
          margin: 15px 0 20px 0;
          font-size: 16px;
        }
      }

      .banner-addon {
        width: 284px;
        height: 520px;

        .iphone-screens {
          position: absolute;
          top: 56px;
          left: 35px;
          width: 211px;
          height: 374px;
          overflow: hidden;
        }
      }
    }

    &.black-friday {
      .content-placeholder {
        .liftup {
          margin-top: 0;
        }
      }
    }
  }
}

@include respond-to(xs) {
  .subscription-plans {
    h2.heading {
      font-size: 24px;
    }

    .banner .carousel {
      min-height: 300px;
      height: 66vh;

      .item {
        min-height: 300px;
        height: 66vh;
      }

      .content-placeholder {
        bottom: 10px;
        top: auto;

        .liftup {
          background-color: transparent;
          margin: 0px;
          padding: 10px;

          h1 {
            font-size: 24px;
            text-align: left;
          }

          h2 {
            text-align: left;
            margin: 10px 0 15px 0;
            font-size: 14px;
            padding: 0;
          }

          .getit {
            padding-bottom: 5px;
            text-align: left;

            .btn {
              font-size: 12px;
              padding: 8px 10px;
              width: 130px;
            }
          }
        }
      }

      &.black-friday {
        .content-placeholder {
          top: 26%;

          .liftup {
            margin-top: 0;

            h1 {
              font-size: 35px;
            }
          }
        }
      }
    }

    .intereset {
      margin-top: 0;
      padding-top: 30px;

      h3 {
        font-size: 22px;
        font-family: $font-family-roboto;
        text-align: left;
      }

      h4 {
        text-align: left;
      }

      p {
        text-align: left;
        font-size: 14px;
        font-weight: 300;
        line-height: 1.5;
      }

      .visualization {
        //max-height: 200px;
      }

      .getit {
        text-align: center;
      }
    }

    section.action {
      .subscription-box {
        &.oneday {
          margin-bottom: 40px;
        }

        ul.benefits {
          margin: 0;
        }

        ul.benefits li {
          margin: 10px 0;
          background-size: 16px;
          padding-left: 25px;
          background-position: left 5px;

          &.centered {
            padding-left: 0;
          }
        }

        .price {
          font-size: 20px;
          margin: 5px 0 12px;
        }

        hr {
          margin: 5px 0;
        }

        .benefits-container {
          margin: 5px 0;
          padding: 0 10px;
        }
      }
    }
  }
  #subscription_plans-blackfriday .subscription-plans {
    .banner .carousel .content-placeholder .liftup h2 {
      padding-right: 70px;
    }
  }
}

.subscription-plans-thankyou {
  h1 {
    font-family: $font-family-roboto;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 20px;
  }
}

#filter-nav {
  text-align: center;

  li {
    display: inline-block;
    float: none;
  }
}

#filter-items {
  .filter-item {
    display: none !important;
  }

  .filter-item.active {
    display: inline-block !important;
  }
}

.bundle-deal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-bottom: 20px;

  .bundle-description {
    display: flex;
    padding: 0px 10px;
    flex-direction: column;
    align-items: center;
    gap: 15px;

    h3 {
      font-size: 24px;
      font-weight: 700;
      text-align: center;
      margin: 0;
      padding: 0;
    }

    p {
      margin: 0;
      padding: 0;
      font-size: 16px;
    }
  }

  .btn-pay {
    display: block;
    width: 100%;
    font-size: 18px;
    padding: 12px 0;
    border-radius: 4px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700 !important;
  }
}