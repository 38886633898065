@mixin crag-chart($width, $height) {
  width: $width;
  height: $height;

  .bars {
    border-bottom: 1px solid #ccc;
  }
  .col {
    display: inline-block;
    position: relative;
    width: 15%;
    background-color: #F2F2F2;
    border-radius: 2px 2px 0 0;
    height: 100%;
    vertical-align: bottom;
    & + .col {
      margin-left: 2%;
    }
    .cell {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      color: $gray-darker;
      font-size: 10px;
      font-weight: 300;
      text-align: center;
    }
    .bar {
      display: block;
      width: 100%;
    }
    .grade-color-3{
      background-color: #67DB67;
    }
    .grade-color-4{
      background-color: #67DB67;
    }
    .grade-color-5{
      background-color: #54B254;
    }
    .grade-color-6{
      background-color: #DBC567;
    }
    .grade-color-7{
      background-color: #DB8267;
    }
    .grade-color-8{
      background-color: #874076;
    }
    .grade-color-9{
      background-color: #020202;
    }
    .grade-label {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center;
      margin-bottom: -20px;
      font-weight: 500;
      font-size: $font-size-small;
    }
  }
}

@mixin like-btn($factor, $base-color, $hover-color) {
  display: inline-block;
  font-family: $font-family-roboto;
  font-style: normal;
  text-decoration: none;
  font-size: 18px * $factor;
  color: $base-color;
  &:hover {
    color: $hover-color;
  }
  .text {
    display: none;
  }

  .icons {
    display: block;
    position: relative;
    height: 15px * $factor;
    width: 21px * $factor;
    .like, .liked {
      position: absolute;
      top: 0px;
      left: 0px;
      display: inline-block;
      overflow: hidden;
      height: 21px * $factor;
      width: 100%;
    }

    .liked {
      height: 0px;
      color: #FB8484;
    }
  }

  &.btn-like-remove {
    .icons {
      .like {
        height: 0;
      }
      .liked {
        height: 21px * $factor;
      }
    }
  }

  @include respond-to(xs){
    //padding: 5px 5px 3px 5px;
    .icon27 {
      transform: translateX(2px);
      font-size: 16px * $factor;
    }
    .text{
      display: none;
    }
  }

  &.animation-like {
    .like {
      height: 0px;
      animation: anim-hide 0.6s;
    }
    .liked {
      height: 21px * $factor;
      animation: anim-appear 0.6s;
    }
  }
}