#teams-show {
  .timeline {
    margin: 15px 0 0 0;
    padding: 0;
  }
}
#teams-photos {
  .photo-wall {
    margin: 15px 0 0 0;
    padding: 0;
  }
}