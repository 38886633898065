.search-popup {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2000;
  background: rgba(255, 255, 255, 0.95);
  display: none;
  opacity: 0;
  transition: opacity 0.3s;
  overflow-y: auto;

  &--visible {
    display: block;
    opacity: 1;
  }
  .search-popup-close-btn {
    position: absolute;
    display: block;
    top: 30px;
    right: 30px;
    width: 40px;
    height: 40px;
    transition: all 0.3s;
    border-radius: 50px;
    text-decoration: none !important;
    background: rgba(255, 255, 255, 0);
    cursor: pointer;

    &:after,
    &:before {
      position: absolute;
      border-radius: 1px;
      height: 3px;
      width: 30px;
      background: black;
      display: block;
      content: "";
      top: 20px;
      left: 0;
    }
    &:before {
      transform: rotate(45deg);
      -ms-transform: rotate(45deg); /* IE 9 */
      -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    }
    &:after {
      transform: rotate(-45deg);
      -ms-transform: rotate(-45deg); /* IE 9 */
      -webkit-transform: rotate(-45deg); /* Chrome, Safari, Opera */
    }

    @include respond-to(xs) {
      top: 13px;
      right: 17px;
      width: 30px;
      height: 30px;

      &:after,
      &:before {
        top: 13px;
      }
    }
  }
}

.search-popup-container {
  margin-top: 20vh;
  @include respond-to(xs) {
    margin-top: 75px;
  }
  .nav-tabs.nav-tabs--premium {
    border-bottom: none;
    > li {
      > a {
        font-size: 15px;
        color: #000;
        font-weight: 500;
        border: none;
        padding: 7px 5px;
        border-bottom: solid 3px transparent;
        text-transform: none;
        margin-right: 15px;
        background: transparent !important;

        &:hover {
          color: #000;
          background: transparent;
          border-bottom: solid 3px $brand-primary;
        }
      }
      &.active a {
        border-bottom: solid 3px $brand-primary;
      }
    }
  }
  .search-active {
    line-height: 20px;
    .items-container {
      box-shadow: none;
      border: none;
      .autocomplete-suggestion:hover {
        background-color: #eee;
      }
    }
  }
  .search-unactive {
    display: none;
  }
  .search-window-dropdown {
    width: 100%;
    max-width: 100%;
    .premium-search-field {
      max-width: 100%;
      width: 100%;
    }
  }
  .slider-container {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
}
