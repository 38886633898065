.my-privacy-container {
  width: 100%;
  // padding: 80px 0 100px 0;
  /*h1 {
    text-align: center;
    margin-bottom: 70px;
    @include respond-to(xs) {
      font-size: 2.2rem;
    }
  }*/
  .my-privacy-form {
    margin-top: 15px;
    label {
      display: inline;
    }
    .row:nth-child(odd) {
      margin-bottom: 15px;
    }
    .col-xs-1 {
      text-align: right;
    }
    a {
      font-weight: 600
    }
    .row-agree-all {
      margin-bottom: 5px;
      label {
        font-size: 1.5rem;
      }
    }
    .continue {
      margin-top: 40px;
    }
    .disagree {
      text-align: center;
      margin-top: 20px;
      a {
        color: rgba(0,0,0,0.5);
      }
    }
    .disagree-info {
      display: none;
      margin-top: 20px;
    }
  }
}

.my-personal-data-container {
  h1 {
    text-align: center;
    margin: 70px 0 50px;
  }
  form {
    margin-bottom: 80px;
  }
  .footer {
    border-top: none
  }
}