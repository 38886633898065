#create_premium_topo {

    // Typography

    h2 {
        font-size: 4rem;
        line-height: 4.8rem;
        font-weight: 700;
        color: #212529;
        @include respond-to(sm-max) {
            font-size: 3rem;
            line-height: 3.8rem;
        }
    }

    p {
        font-size: 2rem;
        line-height: 3rem;
        color: #32383e;
        @include respond-to(sm-max) {
            font-size: 1.6rem;
            line-height: 2.4rem;
        }
    }

    // Elements

    .create-topos-icon {
        padding-right: 1rem;

        img {
            padding-bottom: 3px;
        }
    }

    .btn-topos {
        padding: 1rem 1.5rem;
        background-color: #F8F9FA;
        color: #000;
        margin-right: auto;
        margin-left: auto;
        font-size: 2rem;
        line-height: 3rem;
        @include respond-to(sm-max) {
            font-size: 1.6rem;
            line-height: 2.4rem;
        }
        &:hover {
            background-color: #e6e6e6;
        }
    }

    .photo__overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, rgba(33, 37, 41, 0.7) 34.09%, rgba(33, 37, 41, 0.511) 68.57%);
    }

    .photo__overlay-banner {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(69.39deg, rgba(0, 0, 0, 0.4) 3.89%, rgba(0, 0, 0, 0) 108.89%);
        // backdrop-filter: blur(4px);
    }

    .btn-container {
        display: flex;
        flex-wrap: wrap;
        gap: 2.4rem;
    }
    .btn {
        font-size: 16px;
        padding: 7px 14px;
    }

    // Utility classes

    .d-flex {
        display: flex;
    }
    .align-center {
        align-items: center;
    }

    .flex-col {
        flex-direction: column;
    }

    .text-center {
        text-align: center;
    }

    // Sections

    .banner {
        &-container {
            position: relative;
            display: flex;
            height: 80vh;
            .video {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;

                video {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            &-content {
                z-index: 1;

                .banner-content {
                    max-width: 50rem;
                    padding-top: 4rem;
                    padding-bottom: 4rem;

                    > h1,p {
                        color: #fff;
                    }

                    > * + * {
                        margin-top: 3.2rem;
                    }

                    > p {
                        margin-top: 1.6rem;
                    }

                    > * {
                        margin-bottom: 0;
                    }
                    @include respond-to(xs) {
                        h1 {
                            font-size: 30px;
                        }
                    }

                }
            }
        }
        &-device-info {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 3rem;

            p {
                font-size: 1.6rem;
                line-height: 2.4rem;
                margin-bottom: 0;
            }

            img {
                height: 2.8rem;
                padding-bottom: 2px;
            }
        }
    }

    .how-to {

        &-container {
            display: flex;
            flex-direction: column;
            gap: 10rem;
            @include respond-to(sm-max) {
                gap: 5rem;
            }
            > .row {
                display: flex;
                align-items: center;
                column-gap: 3rem;
                @include respond-to(sm-max) {
                    display: grid;
                    .col-md-5 {
                        order: 1;
                    }
                }
            }
        }

        &-content > * + *{
            margin-top: 2.7rem;
        }

        &-image {
            width: 100%;
            border-radius: 2rem;
            min-height: 44rem;
            object-fit: cover;
            @include respond-to(sm-max) {
                min-height: unset;
            }
        }
    }

    .features {
        background-color: #212529;
        padding-top: 8rem;
        padding-bottom: 8rem;
        @include respond-to(sm-max) {
            padding-top: 6rem;
            padding-bottom: 6rem;
        }
        &-container {
            display: flex;
            align-items: center;
            flex-direction: column;
            @include respond-to(sm-max) {
                align-items: flex-start;
            }
        }

        *:not(a) {
            color: #fff;
        }

        &-heading {
            text-align: center;
            @include respond-to(sm-max) {
                text-align: start;
            }
        }

        &-lead {
            text-align: center;
            @include respond-to(sm-max) {
                text-align: start;
            }
            margin-top: 3.4rem;
            max-width: 82rem;
        }

        &-list {

            &:first-of-type {
            margin-top: 3.6rem;
        }

            &-card {
                max-width: 40rem;
                margin-top: 3rem;

                &__heading {
                    font-size: 2rem;
                    line-height: 2.4rem;
                    font-weight: 500;
                }

                p {
                    font-size: 1.6rem;
                    line-height: 2.4rem;
                }
                @include respond-to(xs) {
                    img {
                        float: left;
                    }
                    > *:not(img) {
                        margin-left: 6rem;
                    }
                }
            }
        }

        &-link {
            margin-top: 4rem;
            padding: 1rem 1.5rem;
        }
    }

    .comparison {
        padding-top: 10rem;
        padding-bottom: 10rem;
        @include respond-to(sm-max) {
            padding-top: 6rem;
            padding-bottom: 6rem;
        }
        &-container {
            max-width: 73rem;
            text-align: center;

            > * + * {
                margin-top: 3.4rem;
            }

            img {
                width: 80%;
                height: auto;
            }
        }
    }

    .statement {
        position: relative;
        display: flex;
        padding-top: 14rem;
        padding-bottom: 13rem;
        @include respond-to(sm-max) {
            padding-top: 6rem;
            padding-bottom: 5rem;
        }
        .photo {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        &-container {
            z-index: 1;

            &-content {
            max-width: 52rem;

            * {
                color: #fff;
            }

            > * + * {
                margin-top: 3rem;
            }
        }
        }
    }

    .testimonials {
        padding-top: 13rem;
        padding-bottom: 12rem;
        @include respond-to(sm-max) {
            padding-top: 6rem;
            padding-bottom: 5rem;
        }
        .swiper-container {
            margin-top: 4rem;
        }

        .testimonial {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            text-align: start;
            max-width: 33.2rem;

            > * {
                margin-bottom: 0;
            }

            &-image {
                width: 100%;
                height: 26rem;
                border-radius: 10px;
                object-fit: cover;
            }

            &-quote {
                margin-top: 2.4rem;
            }

            &-name {
                line-height: 2.4rem;
                font-weight: 500;
                margin-top: 1.4rem;
            }
            &-place {
                margin-top: 0;
                font-size: 1.6rem;
                line-height: 2.4rem;
                color: #6C757D;
            }
        }
    }

    .try-it {
        background-color: #212529;
        padding-top: 10rem;
        padding-bottom: 10rem;
        @include respond-to(sm-max) {
            padding-top: 6rem;
            padding-bottom: 6rem;
        }
        *:not(a) {
            color: #fff;
        }

        &-container {
            max-width: 73rem;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;

            > * {
                margin-bottom: 0;
                }

            p {
                margin-top: 1.5rem;
            }

            .btn-container {
                margin-top: 3.4rem;
            }
        }
    }

    .topos-faq {
        padding-top: 6.4rem;
        padding-bottom: 6.4rem;
        @include respond-to(sm-max) {
            padding-top: 4.4rem;
        }
        &-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            @include respond-to(sm-max) {
                align-items: flex-start;
            }
            &-content {
                margin-top: 3.6rem;
                @include respond-to(sm-max) {
                    margin-top: 2rem;
                    justify-content: flex-start;
                }
                column-gap: 3rem;
                column-count: 3;

                @include respond-to(sm-max) {
                    column-count: 1;
                }

                .faq-card {
                    max-width: 35rem;
                    margin-bottom: 3rem;
                    break-inside: avoid-column;

                    h3 {
                        font-size: 2rem;
                        line-height: 2.5rem;
                        font-weight: 500;
                        margin-bottom: 0;
                        margin-top: 0;
                    }

                    p {
                        font-size: 1.6rem;
                        line-height: 2.4rem;
                        margin-top: 1.3rem;
                        margin-bottom: 0;
                        a {
                            color: #222222;
                            font-weight: bold;
                        }
                    }

                    h3.faq-card__special-heading {
                        font-size: 1.6rem;
                        line-height: 2.4rem;
                        font-weight: 700;
                    }
                }
            }
        }
    }
}
#site-create_premium_topo {
    .popup-container__get-started {
        //width: 400px;
    }
}