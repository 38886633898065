.layout-size {
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  background: $gray-base;
  color: $brand-primary;
  padding: 10px 15px;
  border-radius: 0 4px 0 0;
  .my-lg, .my-md, .my-sm, .my-xs {
    display: none;
  }

  @include respond-to(xs) {
    .my-xs{
      display: inline-block;
    }
  }
  @include respond-to(sm) {
    .my-sm {
      display: inline-block;
    }
  }
  @include respond-to(md) {
    .my-md {
      display: inline-block;
    }
  }
  @include respond-to(lg) {
    .my-lg {
      display: inline-block;
    }
  }

}