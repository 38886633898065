.invite {
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
  overflow-x: hidden;
  padding-bottom: 50px;

  &__info {
    height: 420px;
    background: black;
    color: white;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 15px 50px;
    margin-bottom: 70px;
    background-image: url('#{$image_path}invite_background_default.jpg');
    background-position: right bottom;
    background-size: cover;

    .header--wide {
      max-width: 420px;
    }

    h1 {
      font-size: 2.7rem;
      max-width: 380px;
      line-height: 3.7rem;
      letter-spacing: 1px;
      text-shadow: 1px 1px 2px rgba(0,0,0,0.3);
    }

    p {
      margin-top: 5px;
      max-width: 550px;
      line-height: 2.3rem;
      letter-spacing: 0.05rem;
      text-shadow: 1px 1px 2px rgba(0,0,0,0.3);
    }

    @include respond-to(xs) {
      align-items: flex-start;
      margin-bottom: 170px;

      h1, p {
        text-align: left;
      }
    }
    .position--top-right {
      position: absolute;
      top: 15px;
      right: 15px;
      .dropdown-toggle {
        color: #fff;
      }
    }
  }

  &__action-box {
    position: absolute;
    top: 365px;
    left: 0;
    right: 0;
    background: white;
    border-radius: 4px;
    max-width: 740px;
    margin: 0 auto;
    padding: 35px 10px;
    @include respond-to(xs) {
      padding: 20px 5px;
    }
    border: 1px solid rgba(0,0,0,0.15);

    .action-box-info {
      //height: 96px;
      display: flex;
      padding-left: 20px;
      align-items: center;
      text-align: left;
      font-size: 1.6rem;
      line-height: 1.7;
      @include respond-to(xs){
        padding-left: 15px;
        font-size: 1.4rem;
        padding-bottom: 10px;
      }
    }

    @include respond-to(xs) {
      max-width: 100%;
      margin: 0 14px;
      padding: 15px 0 5px;

      .btn {
        margin-bottom: 10px;

        &--upper {
          margin-bottom: 10px;
        }
      }
    }
  }

  &__invited-climbers {
    color: #545454;
    margin: 0 0 40px;
    border-bottom: 1px solid rgba(0,0,0,0.25);
    padding-bottom: 25px;
    @include respond-to(xs){
      margin: 0 15px 40px;
    }
    .container-fluid {
      padding: 0;
    }
  }

  &__how-to {
    color: #545454;
    padding: 0 15px;
    font-weight: 300;

    .list-item {
      display: flex;
      vertical-align: top;

      i.glyphicon {
        flex: 1;
        max-width: 40px;
        color: black;
        font-size: 2.3rem;
      }

      i.glyphicon--large {
        font-size: 3.5rem;
      }

      p {
        flex: 4;
        margin: 0;
        padding-left: 18px;
        text-align: left;

        @include respond-to(xs) {
          padding-left: 24px;
        }
      }

      @include respond-to(xs) {
        margin-bottom: 15px;
        padding-left: 25px;
      }
    }

    .invite-more-info {
      font-size: 1.4rem;
      margin-top: 30px;
      display: inline-block;
      color: #545454;
      font-weight: bold;
    }

    @include respond-to(xs) {
      h3 {
        text-align: left;
        font-size: 1.9rem;
      }
    }
  }

  .header-small {
    text-transform: none;
    font-size: 2.2rem;
    font-weight: bold;
    padding-top: 60px;
    margin-bottom: 30px;
    font-family: $font-family-roboto;
    @include respond-to(xs){
      padding-top: 50px;
      font-size: 1.8rem;
    }
  }
}

body.locale--el {
  .invite__info h1 {
    text-transform: none;
    max-width: 400px;
    line-height: 1.1;
  }
  .invite__info p {
    line-height: 1.3;
  }
}

.invite-container {
  max-width: 600px;
  margin: 100px auto 0;
  text-align: center;

  .invitee-scrolling-container {
    height: 200px;
    overflow-y: scroll;
    background-color: #8e8e8e;
    text-align: left;

    ul {
      list-style-type: none;
    }
  }
}
.invite-help {
  padding: 10px;
  ul li {
    list-style: disc;
    margin-left: 15px;
    font-size: 14px;
  }
}

.btn {
  &--invite {
    display: flex;
    width: 100%;
    padding: 0;
    align-items: center;
    border-radius: 0.2rem;

    .button-icon {
      font-size: 1.8rem;
      flex: 1;
      width: 60px;
      max-width: 60px;
      color: #B8B8B8;
      //padding: 0 5px;
      //padding: 10px 15px 10px 20px;
    }

    .button-text {
      flex: 5;
      text-align: left;
      padding: 15px 12px;
      font-weight: 500;
      border-left: 1px solid rgba(255,255,255,0.4);
    }
  }

  &--upper {
    margin-bottom: 20px;
    box-shadow: 0 2px 3px rgba(0,0,0,0.2);
  }

  &--center-text {
    .button-text {
      text-align: center;
    }
  }
}

.btn-facebook {
  position: relative;

  .icon-facebook {
    position: absolute;
    bottom: -5px;
    left: 4px;
    font-size: 5rem;
    color: white;
  }
}

.btn-whatsapp {
  color: white !important;
  background: #33D16B;

  .button-icon {
    color: white;
  }
}

.btn-twitter {
  background: #55acee;
  color: white !important;
  i {
    color: white !important;
    font-size: 2.3rem;
  }
}

.btn-email, .btn-sms {
  background: #EAEAEA;
  color: #5E6062;

  .button-text {
    border-left: 1px solid rgba(255,255,255,0.5);
  }
  i {
    color: #7D7D7D;
  }
}

.btn-email--sign-in {
  background: white;
  color: black;
  border: 1px solid rgba(0,0,0,0.2);
}

.share-link-copy {
  position: relative;
  font-size: 1.4rem;
  margin-bottom: 9px;
  z-index: 1;

  span {
    background: white;
    padding: 0 15px;
    color: #A2A2A2;
  }

  &:before {
    z-index: -1;
    content: "";
    position: absolute;
    left: 15px;
    right: 15px;
    bottom: 9px;
    height: 1px;
    background: #B8B8B8;
  }
}

.btn-share-link {
  background: white;
  border: 1px solid rgba(0,0,0,0.1);

  .button-text {
    color: black;
    font-weight: normal;
    overflow: hidden;
    border-left: 1px solid rgba(0,0,0,0.1);
  }
}

.thank-you {
  span.premium {
    color: #fff;
  }
  .continue {
    margin-top: 10px;
    .btn{
      border-radius: 50px;
      padding: 7px 15px;
    }
  }
  .invite__info {
    margin-bottom: 0;
    padding-bottom: 0;
    @include respond-to(xs) {
      max-height: 300px;
    }
    p {
      text-shadow: none;
      font-weight: 300;
    }
  }
  .invite__how-to {
    .header-small {
      padding-top: 30px;
    }
  }

}