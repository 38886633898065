#feed_items-index {
  .banner {
    $banner-xs-height: 45vh;
    $banner-sm-height: 90vh;
    $banner-md-height: 80vh;
    $banner-lg-height: 80vh;

    .banner-container {
      width: 100%;
      position: relative;
      .photo {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background-size: cover;
        background-position: center center;
        @include respond-to(xs) {
          height: $banner-xs-height;
          min-height: 250px;
        }
        @include respond-to(sm) {
          height: $banner-sm-height;
          min-height: 640px;
        }
        @include respond-to(md) {
          height: $banner-md-height;
          min-height: 640px;
        }
        @include respond-to(lg) {
          height: $banner-lg-height;
          min-height: 640px;
        }
      }
      .photo__overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        //background: linear-gradient(0deg, rgba(255,252,0,1) 50%, rgba(0,121,255,1) 50%);
        //opacity: 0.3;
        background-color: #000;
        opacity: 0.2;
        @include respond-to(sm) {
          opacity: 0.05;
        }
      }
      .we_stand_with_ukraine {
        position: absolute;
        right: 15px;
        bottom: 15px;
        z-index: 1000;
        img {
          width: 150px;
        }
      }
      .we_stand_with_ukraine--xs {
        float: right;
        img {
          width: 100px;
        }
      }
      .banner-search-header__term {
        display: inline-block;
      }
      @include respond-to(xs) {
        .banner-search-container {
          z-index: 2;
          position: relative;
          .banner-search-box {
            .banner-search-header-container {
              height: $banner-xs-height;
              min-height: 250px;
              display: flex;
              align-items: flex-end;
              .banner-search-header {
                //max-width: 350px;
                padding-bottom: 7%;
                h1 {
                  font-weight: 600;
                  color: #fff;
                  text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
                  width: 100%;
                  display: block;
                  font-size: 30px;
                  letter-spacing: 1px;
                }
                p {
                  width: 100%;
                  display: block;
                  color: #fff;
                  font-size: 14px;
                  text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
                }
              }
            }
            .banner-premium-search-container {
              padding-top: 7%;
              .premium-search-form__footer {
                .premium-search-form__btn {
                  display: block;
                  padding: 10px;
                }
              }
            }
          }
        }
      }
      @include respond-to(sm) {
        .banner-search-container {
          height: $banner-sm-height;
          min-height: 640px;
          z-index: 2;
          position: relative;
          display: flex;
          align-items: center;
          .banner-search-box {
            background: rgba(255, 255, 255, 0.95);
            padding: 40px 30px 30px 30px;
            border-radius: 4px;
            width: 360px;
            margin-left: 45px;
            .banner-search-header-container {
              .banner-search-header {
                h1 {
                  font-family: $font-family-roboto;
                  font-weight: 700;
                  text-transform: none;
                  color: #222;
                  width: 100%;
                  display: block;
                  font-size: 26px;
                  padding: 0;
                  line-height: 1.2;
                }
                p {
                  width: 100%;
                  display: none;
                  color: #222;
                  font-size: 14px;
                }
              }
            }
            .banner-premium-search-container {
              margin-top: 10px;
            }
          }
        }
      }
      @include respond-to(md) {
        .banner-search-container {
          height: $banner-md-height;
          min-height: 640px;
          z-index: 2;
          position: relative;
          display: flex;
          align-items: center;
          .banner-search-box {
            background: rgba(255, 255, 255, 0.95);
            padding: 40px 30px 30px 30px;
            border-radius: 4px;
            width: 360px;
            margin-left: 45px;
            .banner-search-header-container {
              .banner-search-header {
                h1 {
                  font-family: $font-family-roboto;
                  font-weight: 700;
                  text-transform: none;
                  color: #222;
                  width: 100%;
                  display: block;
                  font-size: 26px;
                  padding: 0;
                  line-height: 1.2;
                }
                p {
                  width: 100%;
                  display: none;
                  color: #222;
                  font-size: 14px;
                }
              }
            }
            .banner-premium-search-container {
              margin-top: 10px;
            }
          }
        }
      }
      @include respond-to(lg) {
        .banner-search-container {
          height: $banner-lg-height;
          min-height: 640px;
          z-index: 2;
          position: relative;
          display: flex;
          align-items: center;
          .banner-search-box {
            background: rgba(255, 255, 255, 1);
            padding: 30px;
            border-radius: 4px;
            width: 450px;
            .banner-search-header-container {
              .banner-search-header {
                h1 {
                  font-family: $font-family-roboto;
                  font-weight: 700;
                  text-transform: none;
                  color: #222;
                  width: 100%;
                  display: block;
                  font-size: 34px;
                  padding: 0;
                  line-height: 1.2;
                }
                p {
                  width: 100%;
                  display: none;
                  color: #222;
                  font-size: 14px;
                }
              }
            }
            .banner-premium-search-container {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
  .bf-deal {
    .text {
      text-align: left;
      h2 {
        margin-bottom: 0;
      }
    }
    .cta {
      text-align: right;
    }
    .row {
      display: flex;
      align-items: center;
    }
    @include respond-to(xs) {
      .cta {
        text-align: left;
      }
      .row {
        display: block;
        align-items: normal;
      }
    }

  }
}

.bg-brand-primary {
  .btn-bordered {
    font-size: 14px !important;
    @include respond-to(xs) {
      font-size: 10px !important;
      padding: 5px 7px !important;
      border-width: 1px !important;
      border-radius: 3px !important;
    }
    &:hover {
      color: $brand-primary !important;
    }
  }
}

.getapp {
  padding: 30px 0;
  p {
    margin: 5px 0;
    @include respond-to(xs) {
      font-size: 12px;
    }
  }
  h4 {
    margin: 5px 0 20px;
    @include respond-to(xs) {
      font-size: 16px;
    }
  }
}

.promoteapp {
  text-align: center;
}

/*.climbing-locations {
  .join-us {
    padding-top: 30px;
  }
}*/
.latest-updates {
  padding: 50px 0 0;

  .updates-container {
    height: 800px;
    overflow: hidden;
  }

  @include respond-to(xs) {
    padding: 0px 0;
  }

  h2 {
   // font-family: $font-family-roboto;
    //font-size: $font-size-h2;
    line-height: 140%;
    // text-transform: uppercase;
    display: block;
    padding: 0;
    text-align: center;
  }
  p.description {
    // text-transform: uppercase;
    padding: 0 0 20px 0;
    text-align: center;
  }
  .read-more {
    padding: 30px 0 10px 0;
    text-align: center;
    @include respond-to(xs) {
      a {
        font-size: 13px !important;
        padding: 10px 16px !important;
        border-radius: 3px !important;
      }
    }
  }
}

.btn-xsize {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 18px;
  padding: 15px 55px;
  span {
    display: block;
    font-size: 14px;
    font-weight: 400;
  }
}

.climbing-locations {
  text-align: center;
  padding: 50px 0;

  &__container {
    padding-top: 30px;
  }

  @include respond-to(xs) {
    padding: 30px 0;
  }

  h2 {
    line-height: 130%;
  }
  p.description {
    padding: 0 0 20px 0;
  }
  .location-item {
    .read-more {
      font-size: 14px;
    }
  }
}
