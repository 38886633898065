.skil-screen-container {
  
}
.login-screen {
  max-width: 600px;
  margin: auto;
  text-align: center;
}
.subscription-screen {
  display: none;
}
.skil-login {
  text-align: center;
}
.skil-login-screen-sign-up-buttons {
  max-width: 350px;
  margin: auto;
  text-align: center;
}