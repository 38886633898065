/* jquery star rating */
.ui-rating{padding-top:4px;}
.ui-rating-star{
    width:17px;
    height:16px;
    font-size:2px;
    float:left;
    text-decoration:none;
    vertical-align:bottom;
    background-image: url("#{$image_path}stars.png");
    background-repeat:no-repeat;
}
.ui-rating a { cursor: pointer; }
.ui-rating-full { background-position:left top; }
.ui-rating-half { background-position:left -16px; }
.ui-rating-empty { background-position:left -32px; }
.ui-rating-hover { background-position:left -48px; }
.ui-rating-cancel-empty { background-position:left -64px; margin-top:1px; }
.ui-rating-cancel-full { background-position:left -80px; }


/* /facebox/facebox.css */

#facebox,
#facetip,
#subfacebox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

#facebox {
  z-index: 100;
  text-align: left;
}
#subfacebox {
  z-index: 110;
}
#facetip{
  z-index: 200;
}

.facebox .popup {
  position:relative;
  text-align: center;
  -webkit-border-radius:2px;
  -moz-border-radius:2px;
  border-radius:2px;
  margin-bottom: 20px;
}


.facebox .content {
  display: inline-block;
  text-align: left;
  min-width: 350px;
  max-width: 95%;
  background: #fff;
  -webkit-border-radius:4px;
  -moz-border-radius:4px;
  border-radius:4px;
}

.facebox-content-wrapper {
  padding: 10px 10px;
}
.facebox-content-nopadding {
  padding: 0 !important;
}

.facebox-close{
  position:fixed;
  display: none;
  top:18px;
  right:35px;
  width: 35px;
  height: 35px;
  background: transparent;
  z-index: 1090;
  opacity: 0.8;
  transition: opacity .2s;
}
.facebox-close:hover {
  opacity: 1;
}
.facebox-close:after,
.facebox-close:before {
  position: absolute;
  cursor: pointer;
  border-radius: 1px;
  height: 5px;
  width: 35px;
  background: white;
  display: block;
  content: '';
  top: 17px;
}
.facebox-close:before {
  transform: rotate(45deg);
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */

}
.facebox-close:after {
  transform: rotate(-45deg);
  -ms-transform: rotate(-45deg); /* IE 9 */
  -webkit-transform: rotate(-45deg); /* Chrome, Safari, Opera */
}

.facebox .image {
  text-align: center;
  position: relative;
}
.facebox .image img {
  width: 100%;
}

.facebox img {
  border: 0;
  margin: 0;
}

/* other facebox related style tweaks */

.facebox a.blue {
  color: $link-color-blue;
}
.mapbox {
  width: 360px;
  margin-top: 4px;
}
.mapbox .footer {
  padding-top: 5px;
  margin-top: 10px;
  text-align: right;
}

.mapbox form .footer {
  padding-top: 4px;
  margin-bottom: 2px;
}

.mapbox form .footer input {
  margin-right: 6px;
}

a[rel='facetip'], a[rel='facetip']:hover {
  text-decoration: none;
  border-bottom: 1px dotted #ddd;
  color: #ddd !important;
  font-weight: normal !important;
}
.facebox a[rel='facetip'] {
  color: #392C20 !important;
}
.mapbox a[rel='facetip'] {
  color: #272727 !important;
  border-color: #272727;
}
.facebox a.icon-help[rel='facetip'] {
  color: #4d45c4 !important;
}
a.icon-help[rel='facetip'], a.icon-help[rel='facetip']:hover {
  border-bottom: none;
}

#facebox .photofooter {
  text-align: left;
  background: rgba(0,0,0,0);
}


#facetip {
  background: rgba(39, 39, 39, 0);
  border-radius: 4px;
  box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.05);
  max-width: 27em;
}
#facetip .content {
  border: 1px solid #cccccc;
  padding: 10px 15px;
  background: #fff;
  border-radius: 4px;
}
