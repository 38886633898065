.blog-list-sidebar {
  margin-top: 30px;
  padding-bottom: 30px;
}
.blog-list-tools {
  margin-bottom: 15px;
}
.sidebar-ads {
  margin: 15px 0;
}
.blog-list-links {
  .list-group-item {
    a {
      display: block;
      margin-right: 50px;
      color: $gray-base;
      font-weight: 400;
      font-size: 14px;
    }
    .actions {
      display: block;
      float: right;
      a {
        color: $gray-light-2;
        display: inline-block;
        margin: 0 0 0 3px;
        &:hover {
          color: $gray-light;
        }
      }
    }
    &:after {
      content: ' ';
      display: block;
      clear: both;
    }
  }
}
.blog-list {
  width: 100%;
  margin-top: 15px;
  padding-bottom: 30px;

  .item {
    .item-wrapper {
      padding-top: 0;
    }
    .blog-headline {
      margin-top: 0;
    }
    .headline {
      .image {
        border-radius: 4px 4px 0 0;
      }
      .ago {
        color: $gray-light-2;
      }
      .image + .title {
        h2 {
          margin: 0;
          color: #fff;
        }
        .ago {
          color: #fff;
        }
      }
      &:hover {
        text-decoration: none;
        .image {
          .image-overlay {
            opacity: 0.1;
          }
        }
      }
    }
  }
}
.post {
  .post-title {
    text-transform: none;
    margin: 50px 0 20px 0;
    textarea {
      text-align: center;
      border: 1px dashed #E7E7E7;
      box-shadow: none;
      font-size: 34px;
    }
  }
  .edit-tool {
    margin: 20px 0;
  }
  .post-details {
    margin: 20px 0;
    .ago {
      display: inline-block;
      vertical-align: middle;
      color: $gray-light;
      height: 20px;
      line-height: 14px;
      font-size: 12px;
      &:after {
        content: '\2022';
        display: inline-block;
        margin: 0 10px;
      }
    }
    .reactions {
      display: inline-block;
      border-top: none;
      padding: 0;
      margin: 0;
      .btn-like {
        color: $gray-light-2;
        &:hover {
          color: $gray-light;
        }
      }
      .likes {
        color: $gray-light-2;
      }
      .like-container {
        display: inline-block;
        margin: 0;
      }
      .sharetools {
        display: inline-block;
        height: 20px;
        a {
          margin: 0 10px;
        }
      }
    }
  }
  .post-author {
    text-align: center;
    padding: 0 15px;
    .climber-thumb {
      width: 100px;
      height: 100px;
      border: 3px solid $gray-lighter;
    }
    .climber-name {
      font-family: $font-family-roboto;
      color: $gray-dark;
      font-weight: 700;
      font-size: 16px;
      margin: 10px 0;
    }
    .climber-description {
      font-family: $font-family-roboto;
      font-weight: 400;
      font-size: 14px;
      margin: 15px 0;
      color: $gray-light;
      a {
        color: $link-color-blue;
      }
    }
    .author-title {
      color: $gray-light-2;
      font-style: italic;
      font-weight: 300;
      font-size: 12px;
      margin: 0 0 10px 0;
    }
    @include respond-to(sm){
      padding: 15px 0;
      border-top: 1px solid #f0f0f0;
      border-bottom: 1px solid #f0f0f0;
      margin: 15px auto;
      .climber-thumb {
        width: 50px;
        height: 50px;
        border: 2px solid $gray-lighter;
      }
      .climber-name {
        font-size: 14px;
      }
      .climber-description {
        font-size: 12px;
      }
    }

    @include respond-to(xs){
      padding: 15px 0;
      border-top: 1px solid #f0f0f0;
      border-bottom: 1px solid #f0f0f0;
      margin: 15px auto;
      .climber-thumb {
        width: 50px;
        height: 50px;
        border: 2px solid $gray-lighter;
      }
      .climber-name {
        font-size: 14px;
      }
      .climber-description {
        font-size: 12px;
      }
    }
  }
  .post-text {
    img {
      width: 108%;
      margin-left: -4%;
      clear: both;
      border-radius: 2px;
      @include respond-to(sm) {
        width: 100%;
        margin-left: 0;
      }
      @include respond-to(xs) {
        width: 100%;
        margin-left: 0;
      }
    }
    h1,h2,h3,h4,h5 {
      font-family: $font-family-roboto;
      text-transform: none;
      font-weight: normal;
    }
    h4 {
      font-size: 16px;
      margin: 25px 0 10px;
      line-height: 1.4;
      color: #474850;
      font-weight: normal;
    }
    a {
      color: $link-color-blue;
    }
    p {
      font-size: 14px;
      line-height: 1.7;
      & + img {
        margin-top: 15px;
      }
    }
  }
}
