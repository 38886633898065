.crag-analytics {
  .dropdown--filter {
    display: block;
  }
  .chart-wrapper {
    position: relative;
    .chart-loader-animation {
      position: absolute;
      display: flex;
      align-content: center;
      justify-content: center;
      flex-direction: row;
      align-items: center;

      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #e2e2e2;


      .lds {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
      }
      .lds div {
        display: inline-block;
        position: absolute;
        left: 8px;
        width: 16px;
        background: #fff;
        animation: lds 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
      }
      .lds div:nth-child(1) {
        left: 8px;
        animation-delay: -0.24s;
      }
      .lds div:nth-child(2) {
        left: 32px;
        animation-delay: -0.12s;
      }
      .lds div:nth-child(3) {
        left: 56px;
        animation-delay: 0;
      }
      @keyframes lds {
        0% {
          top: 8px;
          height: 64px;
        }
        50%, 100% {
          top: 24px;
          height: 32px;
        }
      }
    }
  }
}