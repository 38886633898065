span.premium {
  font-family: $font-family-roboto !important;
  font-weight: 700 !important;
  text-transform: none;
  color: #000;
}
.buy-premium {
  h1 {
    margin: 55px 0 20px;
    font-size: 42px;
  }
  h3 {
    font-size: 16px;
    font-weight: 400;
    font-family: $font-family-roboto;
    margin: 25px 0;
    text-transform: none;
  }

  .buy-buttons {
    margin-top: 30px;
    padding: 0 0 30px 0;
  }
  img.visualization {
    max-width: 100%;
  }
  .visualization-tablet {
    width: 230px;
  }

  .subscription-plan {
    display: inline-block;
    text-align: center;
    float: left;
    & + .subscription-plan {
      margin-left: 20px;
    }
  }
}
.banner {
  .premium-tag {
    position: absolute;
    top: 30px;
    right: 0;
    background-color: $premium-tag-bg;
    padding: 5px 10px;
    border-radius: 2px 0 0 2px;

    .premium {
      font-size: 12px;
      font-weight: 500;
      color: #000;
      // text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2);
    }
  }

  .free-tag {
    position: absolute;
    top: 30px;
    right: 0;
    background-color: $gray-light-2;
    padding: 5px 10px;
    border-radius: 2px 0 0 2px;

    span {
      font-size: 12px;
      font-weight: 500;
      color: #000;
      // text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2);
    }
  }
}
button.stripe-button-el,
.btn-pay {
  background-color: #f4de01 !important;
  background-image: none !important;
  border: 1px solid transparent !important;
  font-weight: 700 !important;
  font-family: $font-family-roboto !important;
  color: #000 !important;
  span {
    font-weight: 700 !important;
    font-family: $font-family-roboto !important;
    background: none !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  &:hover {
    border: 1px solid #222 !important;
  }
}
.btn-more-padding {
  padding: 10px 15px;
}
.subscriptions {
  .subscription {
    background-color: #f7f7f7;
    border: 1px solid #efefef;
    border-radius: 4px;
    padding: 30px 20px;
    text-align: center;
    .name {
      font-size: 20px;
      font-weight: 700;
    }
    .price {
      margin: 10px 0;
      font-size: 14px;
    }
    .description {
      font-size: 14px;
    }
  }
  .edit-subscription {
    padding-left: 25px;
    padding-bottom: 30px;
    padding-top: 5px;
    padding-right: 20px;
    border-left: 1px solid #e2e2e2;
    @include respond-to(xs) {
      margin-top: 30px;
      border-left: none;
    }
  }
  .action {
    color: #222;
    display: block;
    margin: 20px 0;
  }
  .stripe-button-el {
    display: block;
    width: 100%;
    border-radius: 4px;
    box-shadow: none;
    &:hover {
      border: 1px solid $brand-primary;
    }
    span {
      display: block;
    }
  }
}

ul.app-benefits {
  margin: 15px 10px;
  li {
    margin-left: 15px;
    font-size: 14px;
    font-weight: 400;
  }
}

ul.benefits {
  margin: 15px 0;
  li {
    font-size: 16px;
    list-style-type: none;
    padding-left: 35px;
    margin: 16px 0;
    background-image: url("#{$image_path}blue_li.png");
    background-repeat: no-repeat;
    background-position: left top;
    font-weight: 700;
  }
  li.plus-item {
    background-image: url("#{$image_path}plus_li.png");
  }
}

.subscription-plans .section-faq {
  padding-bottom: 60px;
  h3 {
    padding-top: 30px;
    padding-bottom: 20px;
  }
  h4 {
    font-weight: 500;
    line-height: 1.5;
  }
  p {
    font-weight: 300;
    line-height: 1.5;
  }
}

@include respond-to(md) {
  .buy-premium {
    h1 {
      margin: 50px 0 30px;
      font-size: 34px;
    }
    ul.benefits li {
      margin: 12px 0;
    }

    .buy-buttons {
      margin-top: 30px;
      padding: 0 0 20px 0;
    }
  }
}
@include respond-to(sm) {
  .buy-premium {
    padding-left: 15px;
    h1 {
      margin: 20px 0 30px;
      font-size: 34px;
    }
    ul.benefits li {
      margin: 12px 0;
    }

    .buy-buttons {
      margin-top: 30px;
      padding: 0 0 20px 0;
    }
  }
}

@include respond-to(xs) {
  .buy-premium {
    h1 {
      margin: 20px 0 20px;
      font-size: 24px;
    }
    ul.benefits li {
      background-position: left 3px;
      margin: 5px 0;
      font-size: 14px;
      background-size: 18px auto;
      padding-left: 30px;
    }
    .buy-buttons {
      text-align: center;
    }
  }
}