.autocomplete-suggestions {
  padding: 0;
  line-height: 1.5;
}
.autocomplete-selected {
  background: #ddd;
}
.autocomplete-suggestions strong {
  font-weight: normal;
  color: #3399ff;
}

.autocomplete-suggestions {
  z-index: $zindex-tooltip;
  padding-top: 10px;
  &:after {
    top: 0;
    left: 15px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 8px solid #fafafa;
  }
  .items-container {
    padding: 0;
    //white-space: nowrap;
    cursor: default;
    border: 1px solid #ededed;
    background: #fafafa;
    text-align: left;
    box-shadow: 6px 6px 24px -5px rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    position: relative;
    max-height: 350px;
    overflow: auto;
  }
  .autocomplete-selected {
    background-color: #eee;
  }
  .autocomplete-suggestion {
    padding: 0 0px 0 15px;

    .cont {
      padding: 5px 15px 5px 0;
      display: table;
      width: 100%;
      border-bottom: thin solid #ccc;
    }

    .text {
      display: table-cell;
      vertical-align: middle;
      height: 35px;
      max-width: 225px;
    }
    .badges {
      display: table-cell;
      vertical-align: middle;
      height: 35px;
      width: 60px;
      text-align: left;
    }

    &:last-child {
      .cont {
        border-bottom: none !important;
      }
    }

    .headline {
      display: block;
      font-family: $font-family-roboto;
      color: #666;
      font-weight: 300;
      font-size: $font-size-base;
      strong {
        font-weight: 700;
        color: #000;
      }
    }
    .description {
      font-size: $font-size-small;
    }
    .type {
      display: inline-block;
      float: right;
      font-size: 10px;
      clear: both;
      background-color: #666;
      color: #fff;
      padding: 2px 6px;
      border-radius: 10px;
      font-weight: 300;
    }
  }
}
