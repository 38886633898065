.video {
  text-align: left;
  &.container {
    padding: 0;
  }
  .image-row {
    display: block;
    border-radius: 4px;

    a {
      color: $link-color-blue;
    }
    .row {
      margin: 0;
    }

    @include respond-to(xs){
      border-radius: 0;
    }
  }
  .edit-form {
    display: none;
  }
  .youtube_embed_container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    iframe, .embed-container object, .embed-container embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .video__placeholder {
    width: 750px;
    height: 420px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .video__details {
    text-align: left;
    background: #fff;
    border-radius: 0 0 4px 4px;
    padding: 0 15px;
    @include respond-to(xs) {
      border-radius: 0;
    }

    h1 {
      font-size: 26px;
      text-align: left;
      padding-top: 20px;
      margin-top: 0;
      @include respond-to(xs) {
        font-size: 16px;
      }
      a {
        color: #333333;
        display: inline-block;
      }
    }

    .video__description {
      .caption {
        margin: 15px 0;
      }
    }
    .climber-follow-container {
      padding-top: 15px;
      margin-bottom: 15px;
      clear: both;
    }
    .climber {
      float: left;
      display: block;
      text-decoration: none;
      .climber-thumb {
        vertical-align: middle;
        border: 2px solid $gray-lighter;
      }
      .climber-name {
        vertical-align: middle;
        display: inline-block;
        margin-left: 10px;
        color: $gray-dark;
        font-weight: 700;
      }
      .date {
        vertical-align: middle;
        display: inline-block;
        color: #777777;
      }
    }
    .btn-follow {
      float: right;
      margin-top: 3px;
    }

    .shares {
      padding-bottom: 15px;
      .btn-like {
        font-size: 18px;
        color: $gray-light;
      }

      .likes {
        margin: 0;
        display: inline-block;
        .climber-thumb {
          width: 25px;
          height: 25px;
        }
        a {
          text-decoration: none;
        }
      }
      .addthis_sharing_toolbox {
        margin: 0;
        float: right;
      }
      .sharetools {
        a {
          font-size: 18px;
          color: $gray-light-2;
          text-decoration: none;
          display: inline-block;
          margin: 3px 5px;
          &:hover {
            color: $gray-light;
          }
          i {
            vertical-align: middle;
          }
          .caption {
            font-size: 70%;
            vertical-align: middle;
            display: inline-block;
            color: $gray-light;
          }
        }
      }
    }
    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }
}
#videos-show {
  background: $gray-lighter !important;
  .video.container {
    //@extend .container;
    margin: 30px auto;
    @include respond-to(sm) {
      margin: 0 auto;
    }
    @include respond-to(xs) {
      margin: 0;
      .remove-gutter-xs {
        padding: 0;
      }
    }
  }
  ul.video-list {
    margin: 0;
    padding: 0;
    .video-item {
      margin: 15px 0;
      display: block;
      padding: 0;
      list-style: none;
      &__container {
        display: flex;
        align-items: flex-start;
      }
      &__photo {
        width: 118px;
        height: 84px;
        max-width: 118px;
        max-height: 84px;
        min-width: 118px;
        min-height: 84px;
        background-size: cover;
        display: block;
        position: relative;
        .likes__total {
          color: #fff;
          text-decoration: none;
          display: block;
          position: absolute;
          bottom: 10px;
          left: 10px;
        }
      }
      &__details {
        padding-left: 15px;
        &__title {
          color: $gray-dark !important;
          font-size: 14px;
          font-weight: 700;
          text-decoration: none;
          &:hover{
            text-decoration: underline;
          }
        }
        &__place {
          color: $gray-light;
          font-size: 12px;
          font-weight: 400;
          a {
            color: $gray-light;
            &:hover{
              color: $gray-light-2;
              text-decoration: none;
            }
          }
        }
        &__addedby {
          color: $gray-dark !important;
          font-size: 16px;
          font-weight: 400;
          .climber-name {
            display: inline;
          }
        }
      }
    }
  }
  .multiline-read-more .readmore-gradient {
    background: linear-gradient(to bottom, rgba(238, 238, 238, 0) 0%, rgba(238, 238, 238, 1) 80%);
  }
  .related-videos {
    text-align: left;
    @include respond-to(xs) {
      margin-top: 25px;
    }
  }
}