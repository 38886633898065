.onboarding {
  padding-top: 100px;
  padding-bottom: 100px;
  label .help {
    font-weight: normal;
    display: inline-block;
    margin-left: 10px;
    color: #666;
  }
  @include respond-to(xs) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  h3 {
    text-transform: none;
    font-size: 28px;
    margin-bottom: 28px;
    @include respond-to(xs) {
      font-size: 20px;
      margin-top: 10px;
      margin-bottom: 15px;
    }
  }
  p {
    font-family: $font-family-roboto;
    font-weight: 300;
    font-size: 1.7rem;
    line-height: 1.3;
    color: #464646;
    @include respond-to(xs) {
      font-size: 1.5rem;
    }
  }
  &__main-photo {
    background-image: url("#{$image_path}onboarding-main-md.png");
    height: 510px;
    width: 659px;
    max-width: 100%;
    background-repeat: no-repeat;
    background-size: 100% auto;
    @include respond-to(sm) {
      background-image: url("#{$image_path}onboarding-main-sm.png");
    }
  }
  &__header-description {
    padding-top: 40px;
    text-transform: uppercase;
    color: #666;
    font-size: 1.2rem;
    font-weight: 500;
    @include respond-to(xs) {
      font-size: 1.1rem;
      padding-top: 0px;
    }
  }
  &__forward-container {
    &--top {
      text-align: right;
      margin-top: 72px;
    }
    &--bottom {
      margin-top: 40px;
    }
  }
  &__grade-genre-label {
    margin-top: 3px;
    height: 30px;
    @include respond-to(xs) {
      height: auto;
    }
  }
  .btn-primary--onboarding {
    padding: 10px 35px;

    @include respond-to(xs) {
      display: block;
      width: 100%;
    }
  }
  &__genres {
    margin: 28px 0;
  }
  .btn-rounded {
    border: 1px solid #E9E9E9;
    background-color: #fff;
    border-radius: 50px;
    font-size: 14px;
    padding: 5px 24px 4px;
    margin-top: 6px;
    font-weight: 500;
    margin-right: 10px;
    &:focus {
      outline: none;
    }
    &.selected {
      border: 1px solid #BEB11E;
      background-color: #F8E81C;
      border-radius: 50px;
    }

    @include respond-to(sm) {
      padding: 5px 17px 4px;
      font-weight: 500;
      margin-right: 10px;
    }
  }
  label .no-bold{
    font-weight: normal;
  }
  &__search-container {
    margin: 15px 0;
  }
}
