.section-bottom-padding {
  padding-bottom: 30px;
}

.section-top-padding {
  padding-top: 15px;
}

.section-location {
  font-size: 14px;
  max-width: 400px;
  margin: 0 auto;
}

.crag-todos {
  display: block;
  position: absolute;
  right: 20px;
  top: 20px;
  font-family: $font-family-roboto;
  max-width: 350px;
  z-index: 1000;

  .close {
    content: "&times;";
  }

  .wrapper {
    background-color: rgba(255, 255, 255, 1);
    border-radius: $border-radius-base;
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
  }

  .opened,
  .closed {
    display: none;
    padding: 15px;

    &.active {
      display: block;
    }

    .heading {
      font-size: 14px;
      font-weight: 700;
      display: block;
      min-width: 220px;

      @include respond-to(xs) {
        min-width: 75px;
      }

      a {
        color: $gray-base;

        &:hover {
          color: $gray-light;
        }

        text-decoration: none;
      }
    }

    .description {
      margin: 10px 0;
      font-size: 12px;
      font-weight: 400;
    }

    .todos {
      margin: 0 0 -9px 0px;

      .todo {
        display: block;
        padding: 10px 0 0 0;
        margin: 0 0 9px 0;
        text-decoration: none;

        .todo-icon-block {
          float: left;
          width: 38px;
          height: 38px;
          border: 2px solid #666666;
          background-color: #ffffff;
          color: #545454;
          line-height: 38px;
          font-size: 18px;
          text-align: center;
          border-radius: 50%;

          .todo-icon {
            top: 0;
          }
        }

        .todo-description {
          color: #666666;
          margin-left: 50px;
          margin-right: 30px;

          b {
            color: #222222;
            font-weight: 700;
          }
        }

        .todo-action {
          height: 38px;
          display: block;
          float: right;
          line-height: 38px;
          color: rgba(0, 0, 0, 0.2);
          font-size: 18px;
        }

        &::after {
          content: "";
          display: block;
          clear: both;
        }

        &:hover {
          .todo-action {
            color: rgba(0, 0, 0, 1);
          }
        }

        &:first-child {
          border-top: 2px solid #f1f1f1;
        }

        & + .todo {
          border-top: 1px solid #f1f1f1;
        }
      }
    }
  }

  @include respond-to(xs) {
    display: none;
  }
}

.banner {
  &.banner--crag_subpage {
    .banner-placeholder {
      .crag-description {
        display: none;
      }

      .info-container {
        justify-content: flex-start;
      }

      .location {
        margin: 0 0 10px 0;
      }
    }

    h1.cragname a {
      color: #fff;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.nav--crag_subpage {
  nav.scroll-nav-container {
    text-align: left;
  }
}

#crags-show {
  .banner {
    .edit-tool {
      position: absolute;
    }
  }

  .access-info {
    padding: 10px;

    p {
      margin: 0;
    }

    a {
    }
  }

  .crag-big-menu {
    border-bottom: 1px solid #111;

    a {
      display: inline-block;
      width: 200px;
      border-radius: 4px;
      padding: 15px;
      margin: 15px 0px;
      text-decoration: none !important;

      .icon {
        height: 50px !important;
        font-size: 50px;
        line-height: 50px;
        color: #555555;

        img {
          height: 50px;
          border-radius: 4px;
        }

        margin: 15px 0;
      }

      .text {
        font-family: $font-family-roboto;
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 700;
        color: $gray-dark;
        margin-bottom: 5px;
      }

      .description {
        font-family: $font-family-roboto;
        font-size: 14px;
        text-transform: none;
        color: #555555;
      }

      &:hover {
        background-color: $brand-primary;
      }

      @include respond-to(md) {
        width: 180px;
        padding: 10px;
        .icon {
          height: 40px !important;
          font-size: 40px;
          line-height: 40px;

          img {
            height: 40px;
          }
        }
        .text {
          font-size: 16px;
        }
        .description {
          font-size: 12px;
        }
      }

      @include respond-to(sm) {
        width: 140px;
        padding: 5px;
        margin: 10px 0px;
        .icon {
          height: 40px !important;
          font-size: 40px;
          line-height: 40px;

          img {
            height: 40px;
          }
        }
        .text {
          font-size: 16px;
        }
        .description {
          font-size: 12px;
        }
      }

      @include respond-to(xs) {
        width: auto;
        padding: 6px 15px;
        margin: 0 -15px;
        border-radius: 0;
        text-align: left;
        display: block;
        .icon {
          float: left;
          height: 30px !important;
          font-size: 30px;
          line-height: 30px;
          margin: 0;

          img {
            height: 30px;
          }
        }
        .text {
          font-size: 12px;
          margin-bottom: 0;
          margin-left: 45px;
        }
        .description {
          font-size: 12px;
          margin-left: 45px;
        }
      }
    }
  }

  .sub-menu {
    ul.links {
      text-align: center;
      /*> li {
        > a,
        h3 {
          @include respond-to(lg) {
            padding: 32px 16px 27px !important;
            font-size: 16px;
          }
        }
      }*/
    }

    .links.pull-left {
      float: none !important;
    }

    .dropdown {
      display: none;
    }
  }

  .crag-details {

    @include respond-to(xs) {
      padding-bottom: 30px;
    }

    h2 {
      margin-top: 30px;
      margin-bottom: 15px;
      @include respond-to(xs) {
        margin-top: 15px;
      }
    }
  }

  .crag-activity {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .info-box {
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    background-color: #fff;

    & + .info-box {
      margin-top: 20px;
    }

    .infobox-header {
      padding: 0px 0 0px 20px;

      .headline {
        font-size: 16px;
        border-bottom: 1px solid #e6e6e6;
        font-family: $font-family-roboto;
        font-weight: 700;
        text-transform: uppercase;
        padding: 15px 15px 10px 0;
      }
    }

    @include respond-to(sm) {
      margin-top: -96px;

      .infobox-header {
        padding: 0px 0 0px 15px;

        .headline {
          font-size: 15px;
          padding: 10px 10px 10px 0;
        }
      }
    }

    .infobox-content {
      padding: 0 20px 20px 20px;

      .route-count-container {
        display: block;
        margin: 15px 0 10px 0;

        .route-count {
          display: inline-block;
          font-size: 13px;
          font-weight: 500;

          & + .route-count {
            margin-left: 6%;
          }
        }
      }

      @include respond-to(sm) {
        padding: 0 15px 15px 15px;
        .route-count-container {
          margin: 15px 0;
        }
      }
    }

    .btn {
    }
  }

  .tools {
    a {
      display: inline-block;
      color: $gray-darker;
      font-size: $font-family-base;
      font-weight: 500;
      margin-top: 15px;
    }
  }

  .crag-static-map {
    a {
      display: block;
      width: 100%;
      height: 175px;
      overflow: hidden;
      background-size: cover;
    }
  }

  table.list {
    width: 100%;
    font-size: 12px;

    .tick {
      margin-right: 5px;
      color: #444;
    }

    a .tick {
      color: #e2e2e2;
    }

    a {
      color: #333;

      &:hover {
        .tick {
          color: #444;
        }
      }
    }

    .highlighted {
      .icon-check {
        opacity: 1;
      }
    }
  }

  .tab-content.info-box {
    margin-top: 20px;
  }

  .tab-pane.infobox-content {
    padding: 5px 20px 20px 20px;
  }

  .box-wrapper {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #f2f2f2;
    background-color: #fff;
    border-radius: 4px;
    color: $gray-dark;
  }

  .comments {
  }

  .comment {
    display: inline-block;
    vertical-align: top;
    width: 30%;
    font-size: 14px;
    padding: 0 15px;
    margin: 30px 0;

    & + .favourite_comment {
      margin: 3.3% 0;
    }

    .image {
      margin: 15px 0;
      text-align: center;

      .climber-thumb {
        width: 80px;
        height: 80px;
      }
    }

    .climber-name {
      color: #333;
      font-weight: bold;
    }

    .date {
      color: #777777;
    }

    .description {
    }

    @include respond-to(xs) {
      width: 100%;
      margin: 10px 0;
    }
  }

  .visits {
    margin: 40px 0 30px;

    .climber-thumb {
      height: 50px;
      width: 50px;
      margin: 0 2px;
    }

    .more-likes {
      display: inline-block;
      height: 50px;
      padding: 13px 10px;
      vertical-align: middle;
      text-align: center;
      background-color: $gray-lighter;
      color: $gray-dark;
      border-radius: 30px;
      border: 2px solid #e0e0e0;

      .inner-text {
        display: block;
        vertical-align: middle;
        line-height: 10px;
        font-size: 11px;
        letter-spacing: -1px;

        .count {
          font-weight: bold;
        }
      }
    }
  }

  .access-prohibited p.quote {
    font-style: normal;
  }
}

.crag.sub-menu {
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);

  .edit-tool {
    margin-top: 12px;
  }

  ul.links {
    list-style-type: none;
    margin: 0;
    padding: 0;

    > li {
      list-style-type: none;
      display: inline-block;
      padding: 0 5px;
      margin: 0;

      > a,
      > h3 {
        display: inline-block;
        padding: 19px 6px 15px;
        text-transform: uppercase;
        text-decoration: none;
        color: #212121;
        font-size: 14px;
        font-family: $font-family-roboto;
        font-weight: normal;
        margin: 0;
      }

      &:first-child {
        padding-left: 0;
      }

      h3 {
        border-bottom: 3px solid $brand-primary;
      }

      @include respond-to(xs) {
        display: inline-block;
        border-top: #ffffff;
        border-bottom: #f0f0f0;
        padding: 0;
        a {
          font-size: 12px;
          padding: 15px 5px 12px !important;
        }
        h3 {
          display: none;
        }
      }
    }
  }
}

.premium-topo-properties {
  background-color: $gray-lighter;
  padding: 10px 10px 5px 10px;
  margin: -10px -10px 0 0;
}

.topo-navigation {
  .sector-properties {
  }

  .sector-property {
    padding: 10px 0;
    color: #777;
    display: inline-block;
    text-decoration: none;
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
    font-family: $font-family-roboto;
    margin-right: 10px;

    &:hover {
      color: $gray-dark;
    }

    .description {
      display: inline;
    }

    @include respond-to(xs) {
      font-size: 10px;
      padding: 0px 5px 10px 0;
      margin: 0;
    }

    i {
      font-size: 13px;
    }
  }

  h2 {
    font-family: $font-family-roboto;
    text-transform: none;
    font-weight: 700;
    color: #666;

    .icon {
      color: #7a7a7a;
      font-size: 20px;
      transition: transform 0.2s;
    }

    .divider-vertical {
      display: inline-block;
      border-left: 2px solid #e6e6e6;
      height: 27px;
      margin-bottom: -3px;
      padding: 0 0px 0 2px;
    }

    &:hover {
      //color: $brand-primary;
      .icon {
        color: #000;
        transform: translateY(3px);
      }
    }

    @include respond-to(xs) {
      font-size: 20px;
      height: 20px * 1.5;
      .name {
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 82%;
      }
      .icon {
        color: #666;
        font-size: 16px;
      }
    }
  }

  .nav-pills {
    margin-top: 20px;

    &.navbar-right {
      margin-right: 0;

      li {
        float: right;
      }
    }
  }

  .btn-sector-mode {
    @include respond-to(xs) {
      font-size: 16px;
      padding: 5px 6px;
      line-height: 100%;
    }
  }

  .edit-tool {
    margin-top: 20px;
    margin-left: 5px;

    button.dropdown-toggle {
      padding: 9px 15px;
      font-size: 12px;
    }
  }

  .dropdown-menu {
    li.dropdown-header {
      padding: 3px 25px;
    }

    li > * {
      text-transform: none;
      font-size: $font-size-h5;
      display: block;
      padding: 5px 25px;
      margin: 0;
      clear: both;
      font-weight: normal !important;
      line-height: 1.42857143;
      color: #333333;
      white-space: nowrap;
    }

    li h3 {
      font-weight: bold;
      font-family: $font-family-roboto;
    }
  }

  .toggle-mouse-wheel-zoom {
    display: none;
    margin-top: 20px;

    button {
      display: none; // display is toggled from somewhere javascript
    }

    img {
      max-height: 20px;
    }
  }
}

.edit-tool {
  display: block;
  @include respond-to(xs) {
    display: none;
  }
}

.cragmap {
  #map {
    height: 60vh;
  }
}

.sectors-menu {
  .sectors-menu-container {
    padding: 5px 0 0 0;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    margin-bottom: 15px;
  }

  .sector-item {
    display: block;
    margin-bottom: 5px;
    height: 70px;
    padding: 10px 10px;
    border-radius: 4px;

    .active,
    &:focus,
    &:hover {
      background-color: #e6e6e6;
      text-decoration: none;

      .text {
        .name {
          color: #222;
        }

        .description {
          color: #333;
        }
      }
    }

    //margin-bottom: 15px;

    img {
      float: left;
      width: 50px;
      border: 3px solid transparent;
      border-radius: 4px;
    }

    .text {
      padding-top: 5px;
      margin-left: 60px;
      //width: 130px;
      //float: left;
      text-decoration: none;

      .name {
        font-size: 14px;
        font-weight: 700;
        color: #333;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        height: 14px * 1.5;
        width: 100%;
      }

      .description {
        font-size: 14px;
        font-weight: 400;
        color: #444;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        height: 14px * 1.5;
        width: 100%;
      }
    }
  }
}

.add-to-sector {
  form {
    .linkish {
    }
  }
}

.route {
  color: #424142;
  line-height: 18px;
  font-size: 14px;
  padding: 5px 0px;

  & + .route {
    //border-top: 1px solid $gray-lighter;
  }

  &:hover {
    //background-color: $gray-lighter;
  }

  .info {
    padding: 3px 0 1px 0;
  }

  .route-name {
    font-weight: 700;
    font-size: 14px;
    color: #222;
    display: inline;

    .done {
      text-decoration: line-through;
    }

    &::after {
      content: ",";
      font-weight: 400;
    }

    .icon {
      color: $gray-light;
    }
  }

  .route-info {
  }

  a.btn + a.btn {
    margin-left: 5px;
  }
}

.route-info + .route-info {
  margin-top: 5px;
}

.sortable {
  th {
    .tablesorter-header-inner {
      background-position: right center;
      background-repeat: no-repeat;
    }
  }

  th.tablesorter-headerAsc {
    .tablesorter-header-inner {
      background-image: url("#{$image_path}sort-up.png");
    }
  }

  th.tablesorter-headerDesc {
    .tablesorter-header-inner {
      background-image: url("#{$image_path}sort-down.png");
    }
  }

  .rating {
    display: none;
  }
}

#topo_editor {
  canvas {
    cursor: crosshair;
  }
}

.topobox {
  img {
    max-width: 100%;
    max-height: 85vh;
    height: auto !important;
    width: auto !important;
  }

  canvas {
    width: 100% !important;
    height: 100% !important;
  }
}

.topo {
  width: 100%;
  display: table;
  margin-left: 0;
  margin-top: 0px;

  & + .topo {
    margin-top: 40px;
  }

  .topo-image-container {
    display: table-cell;
    float: none;
    background-color: #333;
    text-align: center;
    vertical-align: middle;
    padding-left: 0;
    padding-right: 0;

    .topo-image {
      display: inline-block;
      position: relative;
    }

    .topo-image__badge {
      position: absolute;
      top: 15px;
      right: 15px;
      z-index: 2;
    }

    //height: 600px;
    width: 100% * 2/3;
    border: 2px solid #fff;
  }

  .topo-routes-container {
    display: table-cell;
    float: none;
    vertical-align: top;
    width: 100% * 1/3;
    padding: 10px 10px 10px 15px;
    @include respond-to(lg) {
      padding: 15px 15px 15px 20px;
    }

    .topo-routes {
      overflow: visible;
      position: relative;
      height: auto;

      ul.route-list {
        // min-height: 550px;
        //padding-bottom: 30px;
        display: block;
        border-top: 1px solid #f0f0f0;

        li.route {
          padding: 7px 0;
          margin: 0;
          height: auto;
          display: block;
          //border-bottom: 1px solid #f0f0f0;
          border-radius: 0;
          box-shadow: none;
          font-size: 14px;
          //border-left: 3px solid transparent;

          &.active {
            background-color: #f0f0f0; //border-left: 3px solid $brand-primary;
          }

          .route-container {
            padding: 0px 5px 0px 3px;
            color: #424142;
            display: block;
            line-height: 18px;

            &:hover {
              //background-color: #ebebeb;
              .more {
                .icon {
                  color: #333;
                }
              }

              .route-edit-actions {
                visibility: visible;
              }
            }

            .route-edit-actions {
              visibility: hidden;
            }

            .orderno {
              width: 22px;
              height: 22px;
              margin-top: -3px;
              margin-right: 5px;
              display: block;
              float: left;
              text-align: center;
              padding: 2px;
              background: #424242;
              color: #fff;
              border-radius: 4px;
              font-size: 12px;
            }

            .header-container {
              //float: left;
              margin-left: 30px;
              margin-right: 30px;
              //width: 260px;
              display: block;

              .route-name {
                font-weight: 700;
                font-size: 14px;
                color: #222;

                a {
                  color: #222;
                }

                &::after {
                  content: "";
                }
              }

              .tags {
                margin: 6px 0;

                .rating {
                  display: inline-block;
                  position: relative;
                  top: -3px;
                }
              }
            }

            .more {
              float: right;
              width: 25px;
              text-align: center;
              color: #333;
              display: block;

              .icon {
                color: #a5a5a5;
              }
            }

            @include respond-to(md) {
              .header-container {
                width: 210px;
              }
            }
          }

          .collapse.in {
          }

          .route-info {
            padding: 0px 5px 0 0px;
            margin-left: 32px;
            font-size: 12px;
            line-height: 1.2;
          }

          .ascent-buttons {
            padding: 0 30px;
            margin: 10px 0;
          }
        }
      }

      ul.pagination {
        position: absolute;
        bottom: 0;
        right: 0;
        margin: 0;
        float: right;

        li {
          a {
            color: #222;
          }

          &.active {
            a {
              font-weight: 700;
              color: #333;
            }
          }
        }
      }
    }

    .nav {
      margin-top: -15px;
      @include respond-to(sm) {
        margin-top: 0px;
      }
      @include respond-to(xs) {
        margin-top: 0px;
      }

      li a {
        padding: 8px 12px;
      }
    }
  }

  @include respond-to(sm) {
    .topo-image-container {
      width: 100% * 3/5;
    }

    .topo-routes-container {
      width: 100% * 2/5;
      padding: 7px 10px 15px 10px;
    }
  }

  @include respond-to(xs) {
    .topo-image-container {
      display: block;
      width: 100%;
      height: auto;
    }

    .topo-routes-container {
      display: block;
      width: 100%;
    }
  }

  .topobox {
    .nbr {
      cursor: pointer;
      position: absolute;
      padding: 2px 5px;
      text-align: center;
      background: #333;
      color: #fff;
      border: 1px solid #333;
      border-radius: 4px;
      z-index: 1;
      font-size: 12px;
      line-height: 12px;
      font-weight: 500;
      background: rgba(25, 25, 25, 0.8);
      margin-left: -9px;

      &.active {
        background: rgba(243, 220, 16, 0.8);
        color: #333;
        z-index: 2;
        font-weight: 700;
      }

      &.strong {
        font-weight: normal;
        z-index: 1;
        background: rgba(243, 220, 16, 1);
        border: 12px solid #333;
      }
    }
  }
}

.route-done a {
  color: #fff !important;
}

.small-badge a {
  font-size: 10px !important;
  margin-top: 2px;
}

table.route-list {
  td,
  th {
    font-size: 14px;
  }

  td {
    vertical-align: middle;
  }

  td.txt {
    @include respond-to(xs) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .icon {
    color: $gray-light;
  }

  .route-block a,
  td > a {
    color: $gray-dark;
    font-weight: 500;

    &.action {
      color: #ffffff;
      text-shadow: 0px 0px 1px #606060;

      &:hover {
        color: $gray-dark;
        text-shadow: none;
      }
    }
  }

  tr.active {
    .tick {
      color: $gray-dark;
    }
  }

  .tablesorter-header-inner {
    cursor: pointer;
  }

  .tiny-topo-image {
    display: inline-block;
    height: 35px;
    width: 35px;
    overflow: hidden;
    float: left;
    border: 1px solid $gray-lighter;
    border-radius: 2px;
    margin-right: 10px;

    img {
      height: 35px;
    }
  }

  .route-block {
    margin-left: 45px;
  }

  .badge {
    font-size: 10px;
  }

  .tags {
    .tag {
      .icon {
        color: #222;
      }
    }
  }

  .actions {
    .more {
      float: right;
      width: 25px;
      text-align: center;
      color: #333;
      display: block;

      .icon {
        color: #a5a5a5;
      }
    }
  }
}

#route_pages-show {
  .sub-menu {
    display: none;
  }

  .banner {
    h1 {
      .stars {
        font-size: 40px;
        @include respond-to(xs) {
          font-size: 28px;
        }
      }
    }

    .back-to {
      display: block;
      margin-bottom: 5px;
      color: #fff;
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 400;
      text-decoration: none;

      .glyphicon {
        font-size: 12px;
      }
    }
  }

  .route-details {
    padding-bottom: 20px;
  }

  .topo {
    .topo-image-container {
    }
  }

  .box-white {
    font-size: 14px;

    .header-container {
      display: block;

      .route-name {
        margin-top: 5px;
        font-weight: 700;
        font-size: 16px;
        color: $gray-dark;

        &::after {
          content: "";
        }

        .stars {
          font-size: 14px;
        }
      }
    }

    .route-info {
      margin-top: 10px;
      color: $gray-dark;
    }

    .route-info + .route-info {
      margin-top: 5px;
    }

    .tags {
      margin: 6px 0;
      display: block;
      color: $gray;

      .tag {
        display: inline-block;
        margin-right: 3px;
        width: 21px;
        background-color: $brand-primary;
        height: 21px;
        padding: 3px;
        text-align: center;
        font-size: 13px;
        line-height: 21px;
        border-radius: 50%;
        overflow: hidden;
      }

      .tag-text {
        width: auto;
        //border-radius: 25%;
        background-color: transparent;
      }

      .rating {
        display: inline-block;
        position: relative;
        top: -3px;
      }
    }

    .section {
      margin-top: 15px;
    }

    img {
      max-width: 100%;
    }
  }

  .cards {
    margin: 15px 0 20px 0;
  }

  .section.video-beta {
    .video {
      display: flex;
      margin: 10px 0;

      .playbutton {
        display: inline-block;
        font-size: 28px;
        min-width: 50px;
        max-width: 50px;
      }

      & + .video {
        padding-top: 10px;
        border-top: 1px solid $gray-lighter;
      }
    }

    .video-header {
      font-weight: 700;
      font-size: 14px;

      .edit-tool {
        font-weight: normal;
        @include respond-to(xs) {
          display: block;
        }

        a {
          color: $gray-light;
        }
      }
    }

    .video-added-by {
      color: $gray-light;
      margin: 0 0 5px 0;
    }

    iframe {
      max-width: 100%;
      height: 200px;
    }
  }
}

.chat {
  position: relative;

  .chat-container {
    &.refreshable {
      .chat-item {
        border-bottom: none;
      }
    }
  }

  font-size: 14px;

  .chat-item {
    .smallfont {
      display: block;

      a {
        color: $gray-dark;
        font-weight: normal;
      }
    }

    .pale {
      color: $gray-light;
    }

    & + .chat-item {
    }

    border-bottom: 1px solid $gray-lighter;
    padding-bottom: 15px;
    margin-top: 15px;
  }

  .who {
    float: left;
    vertical-align: top;
  }

  .message {
    display: block;
    margin-left: 50px;

    a {
      color: $gray-dark;
      font-weight: bold;
    }
  }

  .refresh {
    display: block;
    margin: 10px 50px;
  }

  .chat-input-table {
    display: table-row;
    width: 100%;

    .text-cell {
      display: table-cell;
      border: 1px solid #ddd;
      border-radius: 5px 0 0 5px;

      textarea {
        margin: 0;
        border: none;
      }
    }

    .send-cell {
      display: table-cell;
      border: 1px solid #ddd;
      border-radius: 0 5px 5px 0;
      border-left: none;
      width: 1%;
      vertical-align: middle;

      .send-button {
        margin: 0;
        border: none;
        height: 100%;
        padding-top: 0;
        padding-bottom: 0;
        border-radius: 0 5px 5px 0;
        //margin-right: -1px;
      }
    }
  }
}

.card {
  & + .card {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid $gray-lighter;
  }

  .climber-thumb {
    float: left;
  }
}

.card-content {
  .header {
    a {
      display: inline-block;
      font-weight: bold;
      color: $gray-dark;
    }

    .date {
      color: $gray-light;
      display: inline-block;
      font-size: 12px;
    }
  }

  .ascent {
    margin: 2px 0;
  }

  .stars {
    font-size: 12px;
  }

  .brc_space {
    display: none;
  }
}

#hidden_html_container {
  display: none;
}

#cragmap-show,
#cragmap-index {
  .topo-navigation {
    padding-bottom: 12px;

    .toggle-mouse-wheel-zoom {
      display: block;
    }
  }

  #facetip {
    background: #fff;

    .content {
      padding: 5px 7px;

      .card {
        img {
          float: left;
          width: 50px;
          border: 3px solid transparent;
          border-radius: 4px;
        }

        .text {
          padding-top: 5px;
          margin-left: 60px;
          text-decoration: none;

          .name {
            font-size: 14px;
            font-weight: 700;
            color: #333;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            height: 14px * 1.5;
            width: 100%;
          }

          .description {
            font-size: 14px;
            font-weight: 400;
            color: #444;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            height: 14px * 1.5;
            width: 100%;
          }
        }
      }
    }
  }
}

.map_marker_buttons {
  .button + .button {
    margin-top: 5px;
  }

  input[type="submit"] {
  }
}

#cragmap-index {
  section.alerts {
    z-index: $zindex-tooltip;
    width: 100%;
    margin-top: 20px;
    position: absolute;
  }

  /*.alert-placeholder {
    z-index: $zindex-tooltip;
    position: absolute;
    top: 20px;
    left: 50%;
    margin-left: -150px;
    width: 300px;
  }*/
}

.crag-summary.summary-small {
  height: 120px;
  width: 100%;
  display: block;
  text-decoration: none;

  .crag-name {
    display: block;
    color: #fff;
    text-align: left;
    font-size: 16px;
    font-family: $font-family-roboto;
    text-transform: uppercase;
  }

  .route-count-container {
    display: block;
    margin: 0;

    .route-count {
      color: $gray-base;

      i {
        background-color: $brand-primary;
        border-radius: 50%;
        padding: 2px;
        color: $gray-dark;
        border: none;
        font-size: 12px;
      }

      display: inline-block;
      font-size: 11px;
      font-weight: 400;

      & + .route-count {
        margin-left: 3px;
      }

      .name {
        @include respond-to(sm) {
          display: none;
        }
      }
    }
  }

  /*.summary-chart {
    @include crag-chart(100%, 90px);
    .bars {
      .col {
        background-color: transparent;
        color: $gray-base;
        .bar {
          border-radius: 2px 2px 0 0;
        }

        @include respond-to(sm) {
          width: 15%;
          & + .col {
            margin-left: 2%;
          }
        }
        .cell {
          font-size: $font-size-small;
          font-weight: 400;
          color: #fff;
        }
        .grade-label {
          font-size: 10px;
          font-weight: normal;
          color: $gray-light;
        }
      }
      border-color: #bbb;
    }
    margin-bottom: 0px;
    &--simple {

    }
  }*/
}

.crag-summary.summary-big {
  display: inline-block;
  width: 100%;
  max-width: 600px;
  margin-bottom: 35px;

  .route-count-container {
    font-weight: 300;
    font-size: 18px;
    line-height: 1.5;
    color: #212121;
    margin: 20px 0 30px;
    display: block;

    .route-count {
      i {
        background-color: $brand-primary;
        border-radius: 50%;
        padding: 5px;
        margin-bottom: -8px;
        color: $gray-dark;
      }

      display: inline-block;

      & + .route-count {
        margin-left: 6%;
      }
    }
  }

  //.summary-chart {
  //  @include crag-chart(100%, 145px);
  //  .bars {
  //    .col {
  //      width: 10%;
  //      background-color: transparent;
  //      & + .col {
  //        margin-left: 8%;
  //      }
  //      .bar {
  //        border-radius: 2px 2px 0 0;
  //      }
  //
  //      @include respond-to(sm) {
  //        width: 15%;
  //        & + .col {
  //          margin-left: 2%;
  //        }
  //      }
  //      .cell {
  //        font-size: $font-size-base;
  //        font-weight: 400;
  //        color: #fff;
  //        /*.bar {
  //          opacity: 0.9;
  //          &:hover {
  //            opacity: 1;
  //          }
  //        }*/
  //      }
  //      .grade-label {
  //        font-size: $font-size-base;
  //      }
  //    }
  //    border-color: #fff;
  //  }
  //  margin-bottom: 0px;
  //}
  @include respond-to(xs) {
    display: block;
    width: 100%;
    .route-count-container {
      .route-count {
        i {
          border-radius: 50%;
          padding: 5px;
          margin-bottom: -3px;
        }

        font-size: 12px;
      }
    }
    //.summary-chart {
    //  .bars .col .cell {
    //    font-size: 12px;
    //  }
    //  .bars .col .grade-label {
    //    font-size: 12px;
    //  }
    //}
  }
}

.crag-cards {
  margin-bottom: 15px;
}

.crag-card--with-summary {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  margin: 15px 0;
  color: $gray-dark;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 10px;
  box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;

  &:hover {
    text-decoration: none !important;
    transform: scale(1.02);
  }

  .crag-card__img-container {
    border-radius: 10px 10px 0 0;
    position: relative;
    width: 100%;
    height: 175px;
    overflow: hidden;

    .crag-card__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    .premium-tag {
      z-index: 4;
      position: absolute;
      top: 10px;
      right: 0;
      background-color: $premium-tag-bg;
      padding: 3px 4px;
      border-radius: 2px 0 0 2px;

      .premium {
        font-size: 10px;
        font-weight: 400;
        color: #000;
        // text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2);
      }
    }
  }

  .crag-card__img {

  }

  .crag-card__summary-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #fff;
    border-radius: 0 0 10px 10px;
    padding: 12px 16px;
  }

  .crag-card__chart {
    width: 40%;
    //.summary-chart {
    //  @include crag-chart(100%, auto);
    //  padding-bottom: 0px;
    //  .bars {
    //    .col {
    //      background-color: transparent;
    //      & + .col {
    //        margin-left: 8%;
    //      }
    //      .bar {
    //        border-radius: 15px;
    //      }
    //      .cell {
    //        font-size: $font-size-base;
    //        font-weight: 400;
    //        color: #fff;
    //        border-radius: 15px;
    //        background-color: #E6E6EB;
    //      }
    //      .grade-label {
    //        font-size: $font-size-base;
    //      }
    //    }
    //    border: none;
    //  }
    //  margin-bottom: 0px;
    //}
  }

  .crag-card__summary__details {
    width: 60%;
    text-decoration: none;
    text-align: left;
    align-items: flex-start;
    flex-direction: column;
    text-decoration: none !important;
    padding-right: 5px;
  }

  .crag-card__title {
    display: block;
    text-align: left;
    width: 100%;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #000;
    font-size: 16px;
    font-weight: 500;
  }

  .crag-card__description {
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;

    .route-count .name {
      display: none;
    }
  }
}


.plugin-sortable {
  max-height: 400px;
  overflow: auto;

  .sortable-item {
    cursor: move;
    display: block;
    // width: 31%;
    border: 1px solid #ddd;
    border-radius: 2px;
    margin: 5px;
    padding: 5px 10px;
    background-color: rgba(255, 255, 255, 0.6);

    .lbl {
      width: 100%;
    }

    .sector-name {
      font-size: 14px;
      font-weight: 700;
      color: #333;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 70%;
      display: inline-block;
    }

    .edit-tools {
      color: $gray-light-2;

      a {
        color: $gray-light-2;

        &:hover {
          color: $gray-base;
        }
      }
    }
  }
}

a {
  .access-prohibited {
    color: #e04646;
  }

  .access-limited {
    color: #e04646;
  }

  .access-sensitive {
    color: #e09f46;
  }

  &:hover {
    .access-prohibited,
    .access-limited,
    .access-sensitive {
      color: #555555;
    }
  }
}

section.crag-description {
  .description {
    img {
      width: 100%;
    }

    .text {
      margin: 25px 0 40px;
      padding: 0 30px;
      @include respond-to(xs) {
        padding: 0 0px;
      }

      p {
        font-size: 16px;
        line-height: 1.5;
        font-weight: 300;
        @include respond-to(xs) {
          font-size: 15px;
        }
      }
    }
  }
}

.access-popup {
  display: none;
}

.access-popup-container {
  padding: 20px 30px 20px;
  width: 700px;

  h2 {
    text-align: left;
    font-family: $font-family-roboto;
    font-weight: 400;
    margin: 0 0 30px;
  }

  label,
  p {
    font-size: 16px;
  }

  .footer {
    padding-top: 20px;
    margin-top: 20px;
  }

  @include respond-to(xs) {
    width: 350px;
    padding: 15px;
    label,
    p {
      font-size: 14px;
    }

    h2 {
      margin: 0 0 20px;
    }
  }
}

.topo-status {
  display: table;
  width: 100%;

  &--icon {
    display: table-cell;
    width: 25px;
    text-align: left;
    vertical-align: top;
    font-size: 16px;
  }

  &--description {
    display: table-cell;
    font-size: 14px;
  }
}