.termsofuse {
  font-size: 14px;

  ul, ol {
    margin-top: 1em;
    margin-bottom: 1em;
    li {
      font-size: 1em;
      margin-left: 20px;
      padding-left: 0px;
      ul li {
        list-style-type: '- ';
      }
    }
  }

  h2, h3, h4, h5 {
    font-family: $font-family-roboto;
    font-weight: 500;
  }
  h2 {
    font-size: 2em;
  }
  h3 {
    font-size: 1.2em;
  }
  h4 {
    font-size: 1.1em;
  }

  p {
    font-size: 1em;
  }
}