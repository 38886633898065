@import "variables";
@import "./icons";


// a simple reset
@media screen, print {
  * {
    margin: 0;
    padding: 0;
  }
  img {
    border: 0;
  }
  // .container to keep floated elements
  .form_row:after,
  .fbs-feed .sub:after,
  .container:after {
    content: ".";
    display: block;
    height: 0;
    font-size: 0;
    clear: both;
    visibility: hidden;
  }
}

@media print {
  @import "print.scss";
}

// some mixins
@mixin box-sizing-border-box {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

@mixin transform-scale($scale, $x, $y) {
  transform: scale($scale, $scale);
  transform-origin: $x $y;
  -ms-transform: scale($scale, $scale);
  -ms-transform-origin: $x $y;
  -moz-transform: scale($scale, $scale);
  -moz-transform-origin: $x $y;
  -webkit-transform: scale($scale, $scale);
  -webkit-transform-origin: $x $y;
}

@mixin linear-gradient($top, $bottom) {
  background-image: -moz-linear-gradient(top, $top 0%, $bottom 100%);
  background-image: -webkit-linear-gradient(top, $top 0%, $bottom 100%);
  background-image: linear-gradient(to top, $bottom 0%, $top 100%);
}

@media screen {
  @import "screen/jquery_ui.scss";
  @import "screen/plugin_style.scss";
}

@import "screen/bootstrap_overrides";
@import "screen/mixins";
@import "screen/general";
@import "screen/autocomplete";
@import "screen/index";
@import "screen/crag";
@import "screen/photo";
@import "screen/video";
@import "screen/feed-items";
@import "screen/news";
@import "screen/typography";
@import "screen/header";
@import "screen/climber";
@import "screen/ascents";
@import "screen/team";
@import "screen/blog";
@import "screen/login";
@import "screen/search";
@import "screen/footer";
@import "screen/world_map";
@import "screen/development";
@import "screen/create_topo";
@import "screen/ads";
@import "screen/shop";
@import "screen/payment_information";
@import "screen/premium";
@import "screen/subscription_plans";
@import "screen/publish_premium";
@import "screen/create_premium_topo";
@import "screen/openletter";
@import "screen/receipt";
@import "screen/feed-items-user";
@import "screen/onboarding";
@import "screen/form";
@import "screen/invite";
@import "screen/annual_summary";
@import "screen/my_privacy";
@import "screen/area_lander";
@import "screen/map";
@import "screen/skil";
@import "screen/products";
@import "screen/search_popup.scss";
@import "screen/termsofuse";
@import "screen/crag-analytics";
