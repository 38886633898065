#climbers-annual_summary {
  .body-container {
    margin-bottom: 79px;
  }
  .facebox .content {
    padding: 15px 0 25px;

    .share-link-copy {
      text-align: center;
    }
  }
}

.annual-summary-container {
  .annual-summary {
    text-align: center;

    .chart-row {
      margin-top: 40px;
    }
    /*.location-item {
      background-color: transparent !important;
      color: #222;
      &--fixed-size {
      }
      &--fixed-small {
      }
      &:focus,
      &:hover {
        .location-item__img {
          &__content {
          }
        }
      }
      &__img {
        &:before {
        }
        &__content {
          img {
          }
        }
      }
      &__header {
      }
      &__description {
      }
    }*/

    .genre-chart-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 200px;
      width: 200px;
      margin: 0 auto;

      canvas {
        position: absolute;
        top: 0;
        left: 0;
      }

      > div {
        z-index: 2;
      }

      .ascents-total {
        .ascents-total-count {
          font-size: 3.7rem;
          font-weight: bold;
          line-height: 3.7rem;
        }

        .ascents-total-label {
          text-transform: uppercase;
          font-weight: bold;
          color: #686868;
        }
      }

      .ascents-change {
        color: #686868;

        div {
          line-height: 1.1rem;
        }

        .arrow {
          transform: scaleX(2.3);
        }
      }
    }

    .genre-chart-legend {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 50px 40px 40px;

      font-size: 14px;
      > div {
        margin-bottom: 20px;
      }

      @include respond-to(xs) {
        justify-content: space-between;
        flex-direction: row;
        margin: 40px;
      }

      span {
        display: inline-block;
        width: 30px;
        height: 10px;
        border-radius: 10px;
        margin-right: 8px;
      }

      .trad {
        background-color: #ff01fb;
      }

      .sport {
        background-color: #02a9ea;
      }

      .boulder {
        background-color: #f2df00;
      }
    }
  }

  .share-button-container {
    position: fixed;
    bottom: 0;
    z-index: 5;
    width: 100%;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0;

    .share-button {
      cursor: pointer;
      font-size: 1.6rem;
      padding: 12px 80px;
      text-transform: uppercase;
      color: black;
      background-color: #f2df00;
      border-radius: 50px;

      @include respond-to(xs) {
        padding: 12px 30px;
      }

      &:hover {
        text-decoration: none;
      }
    }
    .exeption-text {
      color: #fff;
    }
  }
  .summary,
  .ascents-by-grade {
    h1 {
      font-family: $font-family-roboto;
      text-transform: uppercase;
      font-weight: 400;
      @include respond-to(xs) {
        font-size: 24px;
      }
    }
    border-top: 1px solid #222;
    padding-top: 80px;
    padding-bottom: 100px;
    @include respond-to(xs) {
      padding-top: 60px;
      padding-bottom: 30px;
    }
    position: relative;
    .background {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-size: cover;
      -webkit-filter: blur(5px);
      -moz-filter: blur(5px);
      -o-filter: blur(5px);
      -ms-filter: blur(5px);
      filter: blur(5px);
    }
    .background__overlay {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.7);
    }
  }
  .best-ascents {
    text-align: left;
    padding: 50px 0;

    h2 {
      text-align: center;
      margin-bottom: 30px;
    }
  }

  .recommended {
    padding: 60px 0;
    h2 {
      margin: 0 0 30px;
      @include respond-to(sm) {
        margin: 0 0 10px;
      }
      @include respond-to(xs) {
        margin: 0 0 0px;
      }
    }
    p.description {
      text-transform: uppercase;
    }
  }

  .recommended-card {
    display: block;
    width: 30%;
    margin: 0 10px;
    background-size: cover;
    background-position: center center;
    position: relative;
    background: transparent !important;
    text-decoration: none !important;
    @include respond-to(xs) {
      width: 300px;
      margin: 10px 10px;
    }
    @include respond-to(sm) {
      width: 30%;
    }
    &__month {
      color: #fff;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 700;
      padding-bottom: 7px;
    }
    &__container {
      height: 400px;
      position: relative;
      overflow: hidden;
      @include respond-to(xs) {
        height: 300px;
      }
      @include respond-to(sm) {
        height: 350px;
      }
    }
    .bg {
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center center;
      transition: all 0.3s;
    }
    .overlay {
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 1;
      transition: opacity 0.2s;
      background: -moz-linear-gradient(
        top,
        rgba(0, 0, 0, 0) 50%,
        rgba(0, 0, 0, 0.3) 100%
      ); /* FF3.6-15 */
      background: -webkit-linear-gradient(
        top,
        rgba(0, 0, 0, 0) 50%,
        rgba(0, 0, 0, 0.3) 100%
      ); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 50%,
        rgba(0, 0, 0, 0.3) 100%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#4d000000',GradientType=0 ); /* IE6-9 */
    }
    .text-container {
      z-index: 3;
      position: absolute;
      bottom: 20%;
      margin: 0;
      padding: 0;
      left: 0;
      width: 100%;
      color: #fff;
      text-align: center;
      padding: 15px;
      @include respond-to(xs) {
        bottom: 25%;
      }

      .name {
        font-weight: 500;
        font-family: $font-family-roboto;
        font-size: 36px;
        text-transform: uppercase;
        text-shadow: 0px 0px 9px rgba(0, 0, 0, 0.6);
        @include respond-to(sm) {
          font-size: 30px;
        }
        @include respond-to(xs) {
          font-size: 28px;
        }
      }
      .description {
        font-weight: 400;
        font-family: $font-family-roboto;
        font-size: 18px;
        font-style: italic;
        @include respond-to(sm) {
          font-size: 14px;
        }
        @include respond-to(xs) {
          font-size: 16px;
        }
      }
    }
    &:hover {
      .bg {
        transform: scale(1.2);
      }
    }
  }
}
