body,html {
  margin:0;
  padding:0;
  height: 100%;
}
.body-container {
  position: relative;
  min-height:100%;
}
main {
  padding-bottom: 280px; // height of footer
  @include respond-to(xs) {
    padding-bottom: 350px;
    &.hide-footer {
      padding-bottom: 0;
    }
  }
}
#areas-show,
#crags-index {
  main {
    padding-bottom: 30px;
  }
}

footer.footer {
  height: 280px;
  @include respond-to(xs) {
    &.hide-footer {
      display: none;
    }
    height: 350px;
  }
  box-sizing: border-box;
  padding: 30px 0 10px;
  background-color: $gray-darker;
  color: #fff;
  font-weight: 300;
  position: absolute;
  width: 100%;
  bottom: 0;
  a {
    color: #ccc;
    font-weight: 300;
  }
  ul {
    list-style:none;
    padding-left:0px;
    margin-left: 0px;
    li {
      margin-top: 3px;
    }
  }

  .footer-links {
    @include respond-to(xs){
      ul li {
        display: inline-block;
        margin-right: 5px;
      }
    }

  }

  .footer-crags {

  }

  .footer-share {
  }

  h3 {
    margin: 0 0 10px 0;
    padding: 0;
    font-size: 16px;
    font-weight: 300;
    font-weight: 700;
  }
  .footer-share {
    text-align: right;
    .share {
      margin-bottom: 15px;
      a {
        font-size: 40px;
        opacity: 0.9;
        text-decoration: none;
        &:hover {
          opacity: 1;
        }
      }

    }
    @include respond-to(xs) {
      padding-top: 20px;
      text-align: center;
    }
  }
  .copyright {
    clear: both;
    border-top: 1px solid $gray-dark;
    margin-top: 30px;
    padding-top: 10px;
    text-align: center;
    color: $gray;
  }
}
