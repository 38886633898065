.feed-items-user {

  .weekly_photo {
    display: block;
    width: 100%;
    height: 200px;
    @include respond-to(sm) {
      height: 400px;
    }
    background-size: cover;
    margin-bottom: 5px;
    position: relative;
    text-decoration: none !important;
    .ribbon {
      position: absolute;
      right: 0px; top: 0px;
      z-index: 1;
      overflow: hidden;
      width: 75px; height: 75px;
      text-align: right;
    }
    .ribbon span {
      font-size: 10px;
      font-weight: bold;
      color: #222;
      text-transform: lowercase;
      text-align: center;
      line-height: 20px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      width: 100px;
      display: block;
      background: #ECDC08;
      position: absolute;
      top: 19px; right: -21px;
    }
    /*.ribbon span::before {
      content: "";
      position: absolute; left: 0px; top: 100%;
      z-index: -1;
      border-left: 3px solid #79A70A;
      border-right: 3px solid transparent;
      border-bottom: 3px solid transparent;
      border-top: 3px solid #79A70A;
    }
    .ribbon span::after {
      content: "";
      position: absolute; right: 0px; top: 100%;
      z-index: -1;
      border-left: 3px solid transparent;
      border-right: 3px solid #79A70A;
      border-bottom: 3px solid transparent;
      border-top: 3px solid #79A70A;
    }*/
  }

  .number-liftup {
    .name {
      font-size: 10px;
      color: #999;
      text-shadow: none;
    }
    .number {
      color: #000;
      text-shadow: none;
    }
    &:hover {
      text-decoration: none;
      .name {
        color: #333;
      }
      .number {
        color: #000;
      }
    }
  }
  .miniheader {
    color: #999999;
    font-size: 12px;
    margin-top: 20px !important;
    margin-bottom: 20px;
  }
  .padded {
    padding: 0 10px 0 15px;
  }
  .bottom-padding {
    padding-bottom: 15px;
  }

  .user-profile {
    .background-image {
      height: 105px;
      background-size: cover;
      background-position: center center;
      border-radius: 4px 4px 0 0;
      position: relative;
      @include respond-to(xs){
        border-radius: 0;
      }
      .edit-setting {
        color: #fff;
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 16px;
        text-shadow: 1px 1px 1px rgba(0,0,0,0.3);
      }
      z-index: 1;
    }
    .user-details {
      position: relative;
      z-index: 2;
      margin-top: -35px;
      .climber{
        display: block;
        text-decoration: none;
        .climber-thumb {
          height: 70px;
          width: 70px;
          vertical-align: middle;
          border: 3px solid #fff;
        }
        .climber-name {
          padding-top: 34px;
          vertical-align: middle;
          display: inline-block;
          margin-left: 5px;
          color: $gray-dark;
          font-weight: 500;
          font-size: 16px;
        }
      }
    }
    .badge--premium {
      background-color: $premium-tag-bg;
      color: #fff;
      font-size: 10px;
    }
    .climber-description {
      margin-top: 10px;
      color: #333333;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      .enjoys {
        text-transform: capitalize;
      }
      i {
        color: #777777;
      }
    }
    .ascent-details {
    }
    hr {
      margin-top: 14px;
      margin-bottom: 14px;
    }
  }


  .user-social {
    ul.groups {
      margin: 0;
      padding: 0;
      li {
        list-style-type: none;
        margin: 13px 0;
        padding: 0 0 0 4px;
        a {
          font-size: 12px;
          line-height: 14px;
          color: #333;
          i {
            font-size: 14px;
            color: #A9A9A9;
            margin-right: 15px;
          }
          &:hover {
            color: #000;
            text-decoration: none;
            i {
              color: #333;
            }
          }
        }
      }
    }
  }
  .user-share-box {
    background: #fff;
    border: 1px solid;
    border-color: #e5e6e9;// #dfe0e4 #d0d1d5;
    border-radius: 4px;
    margin-top: 30px;
    margin-bottom: 25px;
    .share-actions-container {
      background-color: #F8F8F8;
      border-radius: 0 0 4px 4px;
      display: table;
      width: 100%;
      a {
        display: table-cell;
        width: 33%;
        font-weight: 500;
        font-size: 13px;
        text-align: center;
        padding: 8px;
        border-top: 1px solid #ebecef;
        text-decoration: none;
        border-radius: 0;
        &:hover {
          background-color: #fcfcfc;
        }
        & + a {
          border-left: 1px solid #e5e6e9;
        }
      }
    }
    .share-update-container {
      display: table;
      width: 100%;
      padding: 15px;
      .textarea-container {
        display: table-cell;
        width: 70%;
      }
      .button-container {
        display: table-cell;
        width: 30%;
        text-align: right;
      }
      textarea {
        border: none;
        outline: none;
        resize: none;
        box-shadow: none;
        padding: 0;
      }

    }
  }
  .feed-items {
    padding-top: 0;
    li.item {
      width: 100%;
      margin-bottom: 15px;
    }
  }
  .box-white + .box-white {
    margin-top: 15px;
  }
  .confirmation-dialog {
    h3 {
      font-family: $font-family-roboto;
      font-size: 18px;
      font-weight: 500;
    }
  }

  .tab-bar {
    display: none;
    position: fixed;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #DEDEDE;
    background: #E9E9E9;
    padding: 9px 0 5px 0;
    z-index: 1030;
    .tab-bar__container {
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }
    &__item {
      color: #666666;
      text-align: center;
      //width: 80px;
      margin: auto;
      line-height: 1.2;
      text-decoration: none;
      .icon {
        font-size: 18px;
      }
      .text {
        text-transform: uppercase;
        font-size: 9px;
      }
      &--active {
        color: $link-color-blue;
      }
    }
  }

  @mixin feed_page_mobile_layout(){
    .container {
      padding: 0;
      .row {
        margin: 0;
      }
    }
    .user-share-box {
      margin: 0 0 15px 0;
      border-radius: 0;
      border-width: 1px 0;
      border-style: solid;
    }
    .box-white,
    .feed-items .feed-item .item-wrapper {
      border-radius: 0;
      border-width: 1px 0;
      border-style: solid;
    }
    .box-white {
      margin-top: 0;
    }
    .mobile-view {
      display: none;
      &--active {
        display: block;
      }
      &--me {

      }
      &--search {

      }
      &--moments {

      }
      &--home {
        padding-bottom: 45px !important;
      }
    }
    .tab-bar {
      display: block;
    }
  }

  @include respond-to(sm) {
    @include feed_page_mobile_layout();

    .mobile-view {
      padding: 0 0 48px 0 !important;
    }
  }

  @include respond-to(xs) {
    @include feed_page_mobile_layout();

    .mobile-view {
      padding: 0 0 48px 0 !important;
    }
  }

  @include respond-to(md) {
    .download-badges {
      .appstore-badge-x1 {
        width: 115px;
        height: 34px;
        background-size: 100% auto;
      }
      .googleplay-badge-x1 {
        width: 115px;
        height: 34px;
        background-size: 100% auto;
      }
    }
  }
}