.openletter {
  .banner {

    .carousel {
      width: 100%;
      overflow: visible;
      min-height: 380px;
      position: relative;
      height: 490px;

      .overlay {
        display: none;
      }
      .item {
        display: inline-block;
        width: 100%;
        height: 100%;
        min-height: 380px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        .photographer {
          opacity: 0.7;
          position: absolute;
          bottom: 20px;
          right: 20px;
          color: #fff;
          z-index: 10;

          @include respond-to(xs) {
            font-size: 10px;
            opacity: 0.9;
            bottom: 15px;
            right: 15px;
          }
        }
      }
    }

    @include respond-to(xs){

      .carousel {
        min-height: 250px;
        height: 250px;
        .item {
          min-height: 250px;
        }
      }
    }
  }
  .letter {
    background-color: rgba(255,255,255,0.93);
    margin-top: -200px;
    padding: 50px 100px;
    z-index: 1000;
    border: 1px solid #fafafa;
    @include respond-to(xs){
      padding: 30px 30px;
      margin-top: 0px;
    }
    @include respond-to(md){
      padding: 30px 60px;
    }
    @include respond-to(sm){
      padding: 30px 60px;
    }
    a {
      color: #000;
      font-weight: 700;
    }
    img {
      max-width: 100%;
    }
  }
  h2 {
    font-family: $font-family-roboto;
    text-transform: none;
    font-weight: 700;
    padding-top: 30px;
    padding-bottom: 20px;
    @include respond-to(xs){
      padding-top: 0px;
      margin-top: 10px;
      font-size: 22px;
    }
  }
  h3.liftup {
    font-family: $font-family-roboto;
    text-align: center;
    text-transform: none;
    font-weight: 700;
    margin: 45px -30px 30px;
    font-size: 25px;
    @include respond-to(xs){
      font-size: 20px;
      margin: 45px -10px 30px;
    }
  }
  h3 {
    font-family: $font-family-roboto;
    text-transform: none;
    font-weight: 700;
    font-size: 20px;
  }
  .ingress {
    font-size: 20px;
    font-weight: 300;
    @include respond-to(xs){
      font-size: 18px;
    }
  }
  p {
    font-size: 16px;
  }
  .help-block {
    display: none;
  }
  .subscription-plan {
    margin: 30px 0;
    text-align: center;
  }
}