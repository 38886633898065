.row_template {
  display: none;
}

.ascents-form {
  min-width: 350px;
  max-width: 500px;
}
.edit_ascent,
.new_ascent {
  max-width: 450px;
}
.rating-description {
  //margin-top: -30px;
  margin-left: 80px;
  //padding-bottom: 15px;
}
.imported_ascents .ascent-list .imported_crag_name {
  display: none;
}
.add-ascents-container {
  height: auto;
  overflow: auto;
  background: #f6f6f6;
  margin: 0 -10px;
  table {
    tbody {
      overflow: auto;
    }
  }
  @include respond-to(lg) {
    width: 1000px;

  }
  @include respond-to(md) {
    width: 800px;
  }
  @include respond-to(sm) {
    width: 600px;
  }
  @include respond-to(xs) {
    width: 100%;
  }
  .alert {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: inline;
      li {
        margin: 0;
        padding: 0;
        display: inline;
      }
    }
  }
}
.ascent-cell {
  min-width: 350px;
  max-width: 400px;
  padding: 10px;
  border-right: 2px solid #fff;

  &.fresh {
    textarea,
    select,
    input[type=text] {
      background-color: #eee;
    }
  }
}