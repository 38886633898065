.area-lander {
  .crag-card {
    padding: 10px 15px;
  }

  .readmore-wrap p.description {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .map {
    height: 50vh;
    background: #ccc;
    position: relative;

    .btn-container {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 10%;
      text-align: center;

      .btn {
        padding: 10px 30px;
      }

      display: none;
    }

    .full-screen-popup--hidden {
      display: block;
      position: absolute;
      opacity: 1;
      z-index: 5;

      .btn-container {
        display: block;
      }

      .header {
        display: none;
      }

      .content {
        top: 0;

        .map-container {
          #mapControlsContainer {
            display: none !important;
          }

          .map-buttons-container {
            display: none;
          }
        }
      }
    }

    .map-container {
      top: 0;
      bottom: 0;
      width: 100%;
    }
  }

  .photos {
    .photos-container {
      position: relative;

      .photo {
        width: 100%;
        max-width: 330px;
        height: 330px;
        background: #ccc;
        overflow: hidden;
        background-size: cover;
        background-position: center center;
        margin: 0 15px;

        svg,
        img {
          height: auto;
          width: auto;
          max-height: 100%;
          visibility: hidden;
        }

        @include respond-to(md) {
          height: 430px;
          max-width: 430px;
        }
        @include respond-to(lg) {
          height: 430px;
          max-width: 430px;
        }
      }
    }
  }

  .booking-deals {
    padding: 20px 0 20px 20px;
    @include respond-to(xs) {
      padding: 30px 0 20px 0;
    }
  }

  .blogs {
    .blog {
      color: #212121;
      display: block;
      clear: both;
      padding: 20px 0;

      &__header {
        color: #212121;
        font-size: 21px;
        font-weight: 600;
        line-height: 1.4;
        margin: 5px 0 20px 0;
        @include respond-to(xs) {
          color: #111111;
          font-size: 18px;
          font-weight: 500;
          margin: 0px 0 15px 0;
        }
      }

      &__description,
      &__description * {
        color: #333;
        font-size: 14px;
        line-height: 1.5;
        font-weight: 400;
        /*@include respond-to(xs) {
          font-size: 14px;
          font-weight: 400;
        }*/
      }

      &__thumbnail {
        float: right;
        width: 100px;
        height: 140px;
        margin-left: 20px;
        background-size: cover;
        background-position: center center;
        @include respond-to(xs) {
          margin-left: 25px;
        }
      }

      &:hover {
        text-decoration: none;

        .blog__header {
          text-decoration: underline;
        }
      }
    }
  }

  .travel-info {
    &__item {
      padding: 14px 15px;
      display: flex;

      &__icon {
        font-size: 40px;
        padding-right: 10px;
      }

      &__content {
        font-family: $font-family-roboto;
        line-height: 1.5;
        padding-left: 20px;

        h3 {
          margin: 0;
          padding: 0;
          color: #212121;
          font-size: 14px;
          font-weight: 500;
        }

        p {
          color: #666;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }

  .recommended-services {
    background: #f2f2f2;
    padding: 15px 10px;
    @include respond-to(xs) {
      margin-left: -30px;
      margin-right: -30px;
    }

    .heading {
      padding: 10px 15px 0px;
      margin-bottom: 20px;
      color: #222222;
      font-size: 16px;
      font-weight: 700;
    }

    .readmore-gradient {
      background: linear-gradient(to bottom, rgba(242, 242, 242, 0) 0%, #f2f2f2 100%);
    }

    .travel-card {
      a {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        text-decoration: none;
        gap: 15px;

        .thumbnail {
          height: 75px;
          width: 75px;
          background-size: cover;
          background-position: center center;
          flex: none;
          border-radius: $border-radius-base;
        }

        .title {
          display: block;
          color: #212121;
          font-size: 14px;
          font-weight: 500;
        }

        .info {
          display: block;
          color: #666;
          font-size: 14px;
        }

        &:hover {

        }
      }

    }
  }

  .buy-topo {
    ul.benefits {
      margin: 15px 25px;
      @include respond-to(md) {
        padding-top: 30px;
      }
      @include respond-to(lg) {
        padding-top: 30px;
      }

      li {
        font-weight: 300;
        background-size: 18px auto;
        background-position: left 4px;
      }
    }

    .visualization {
      @include respond-to(xs) {
        width: 80%;
      }
    }

    .buy-buttons {
      padding-left: 60px;
      @include respond-to(xs) {
        padding-left: 0px;
        text-align: center;
      }

      .subscription-plan {
        @include respond-to(xs) {
          display: block;
          float: none;
          padding: 0 25px;
          .btn {
            display: block;
          }
        }
      }
    }
  }
}