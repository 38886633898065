.product_areas_items {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    .product_area_item {
        display: flex;
        padding: 4px 6px;
        margin-right: 3px;
        border-radius: 4px;
        background: #fafafa;
        border: 1px solid #ddd;
        .name {
            font-weight: bold;
        }
        .product_areas_item_remove {
            color: #222;
        }
    }
}
.box-padded > .product-list-public-view {
    margin: 0 -15px 0 -20px;
}
.product-list-public-view {
    .product-list-item {
        width: 165px;
        @include respond-to(md) {
            margin-right: 10px; 
        }
        @include respond-to(lg) {
            margin-right: 10px;
        }
    }
    .product.product-public-view-container {
        display: block;
        text-decoration: none !important;
        text-align: center;
        padding: 15px;
        .product-image {
            img {
              max-width: 100%;
              max-height: 135px;
            }
        }
        .product-name {
            color: #000;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 500;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .product-brand {
            color: #222;
            text-transform: none;
            font-size: 14px;
            font-weight: 400;
            margin-top: 3px;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .product-description {
            margin-top: 5px;
            color: #333;
            font-size: 12px;
            font-weight: 400;
            height: 50px;
        }
    }
}