.search-criteria {
  h1 {
    padding: 20px;
    margin: 0;
    font-family: $font-family-roboto;
    font-weight: 400;
    font-size: 24px;
  }
}
.search-results {
  h3 {
    font-size: 12px;
    font-family: $font-family-roboto;
    text-transform: uppercase;
    color: $gray-light-2;
    font-weight: 700;
  }
}
.result-row {
  & + .result-row {
    border-top: 1px solid $gray-lighter;
  }
  .result {
    display: table;
    width: 100%;
    padding: 4px 0;
    .picture {
      display: table-cell;
      width: 60px;
      text-align: left;
      vertical-align: middle;
      .circle {
        background-color: $gray-lighter;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        text-align: center;
        vertical-align: middle;
        .text {
          line-height: 40px;
          text-transform: uppercase;
        }
      }
      img {
        max-width: 40px;
        border-radius: 50%;
      }
    }
    .details {
      display: table-cell;
      text-align: left;
      vertical-align: middle;
      .name,
      .name a {
        font-size: 14px;
        font-weight: 700;
        color: $gray-dark;
      }
      .stars {
        font-size: 12px;
      }
      .description,
      .description a {
        font-size: 12px;
        font-weight: 400;
        color: $gray-light;
      }
    }
    .link {
      width: 100px;
      display: table-cell;
      text-align: right;
      vertical-align: middle;
    }
  }
}
