#mapcontainer {
  position: relative;
  padding: 0;
}

body.embeddable #mapControlsContainer {
  display: none;
}

#areas-show,
#crags-index {
  .mapfooter {
    display: none;
  }
}

.map-control-container {
  color: $gray-light-2;
  font-size: 14px;
  &:hover {
    color: $gray-base;
  }
}
.map-sharebuttons {
  display: none;
  position: absolute;
  right: 35px;
  top: 0;
  width: 60px;
  a {
    font-size: 14px;
    color: $gray-light-2;
    text-decoration: none;
    display: inline-block;
    margin: 1px 4px 0 4px;
    &:hover {
      color: $gray-base;
    }
    i {

    }
  }
}
.map-sharebuttons-chevron {

  position: absolute;
  left: 100%;
  top: 50%;
  margin-top: -4px;
  border-color: transparent transparent transparent #fff;
  border-style: solid;
  border-width: 4px;
  height: 0;
  width: 0;
}


#mapControlsContainer {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  width: 360px;
  height: auto;
  @include respond-to(md) {
    width: 350px;
  }
  @include respond-to(sm) {
    width: 350px;
  }
  @include respond-to(xs) {
    width: 100%;
  }

  .controls-container {
    box-shadow: 1px 1px 10px rgba(0,0,0,0.7);
    height: auto;
    border-radius: 4px;
    margin-left: 15px;
    margin-top: 15px;
    background: #EFEFF3;
    position: relative;
    @include respond-to(xs) {
      margin: 0;
      border-radius: 0px;
    }
  }
  &.full-height {
    height: 100%;
    .controls-container {
      height: 100%;
      //background: rgba(250,250,250, 1);
      margin-left: 0px;
      margin-top: 0px;
      padding-left: 0;
      padding-top: 0px;
      border-radius: 0px;
      .controls-page {
        padding-bottom: 0;
        .controls-header {
          padding: 20px 35px 20px 31px;
          @include respond-to(xs) {
            padding: 20px 15px 20px 15px;
          }
        }
      }
      .controls-page.filters {
        padding-top: 15px;
      }
    }
  }
  input#worldmap_search {
    display: block;
    background: transparent;
    border: none;
    outline: none;
    border-bottom: 1px solid #999;
    width: 100%;
    font-size: 14px;
    text-align: left !important;
    font-style: italic;
    color: #333;
    font-weight: 300;
    background: transparent url('#{$image_path}input_search.png') no-repeat left top;
    background-size: auto 80%;
    padding: 2px 15px 7px 40px;
    border-radius: 0;


    &::-moz-placeholder {
      color: #666;
      font-style: italic;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: #666;
      font-style: italic;
      opacity: 1;
    } // Internet Explorer 10+
    &::-webkit-input-placeholder  {
      color: #666;
      font-style: italic;
      opacity: 1;
    }
  }

  .controls-page{
    position: relative;
    width: 100%;
    max-height: 100%;
    overflow: auto;
    padding-bottom: 15px;
    border-radius: $border-radius-base;

    .controls-header {
      position: relative;
      width: 100%;
      height: 48px;
      z-index: 1;
      padding: 20px;
      .headline {
        width: 285px;
        position: relative;
        float: left;
        @include respond-to(xs) {
          width: 265px;
        }
        @include respond-to(md) {
          width: 235px;
        }
        @include respond-to(sm) {
          width: 235px;
        }
      }
      .menubutton {
        width: 45px;
        position: relative;
        float: left;
        text-align: right;
        @include respond-to(xs) {
          width: 30px;
        }
      }
      //border-radius: $border-radius-base $border-radius-base 0 0;


      .btn-close {
        margin-top: 0px;
        height: 23px;
        width: 23px;
        display: inline-block;
        background: transparent url('#{$image_path}appicon_close.png');
        background-size: cover;
        vertical-align: top;
      }
      .btn-filters {
        margin-top: 0px;
        height: 25px;
        width: 25px;
        display: inline-block;
        background: transparent url('#{$image_path}appicon_filter.png');
        background-size: cover;
        vertical-align: top;
      }
    }
    .controls-content {
      position: relative;
      height: auto;
      max-height: 350px;
      padding: 0;
      overflow-y: auto;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;

      .travel-information {
        .miniheader {
          padding: 0 10px;
        }
      }
    }
  }
  .controls-page.search {
    .controls-header {
    }
    .help-label {
      text-align: right;
      color: #666;
      margin: 5px 30px 0 5px;
    }

  }
}
.tag-premium-topo {
  display: inline-block;
  background: $premium-tag-bg;
  color: #000;
  padding: 3px 5px;
  font-size: 10px;
  font-weight: 500;
  border-radius: 2px;
}

#mapLoadingOverlay {
  background: rgba(0,0,0,0.3);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: none;

  .loading-crags {
    height: 55px;
    width: 200px;
    background: #333333;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -27px;
    margin-left: -100px;
    display: block;
    border-radius: $border-radius-base;
    box-shadow: 2px 2px 2px rgba(0,0,0,0.8);

    .icon-loading {
      padding: 15px;
      display: inline-block;
      img {
        height: 25px;
        width: 25px;
      }
    }
    .message {
      display: inline-block;
      color: #fff;
    }
  }
}



#areas-show,
#crags-index {
  .footer {
    height: 30px;
    overflow: hidden;
    padding-top: 0;
    padding-bottom: 0;
    .footer-links {
      display: none;
    }
    .footer-crags {
      display: none;
    }
    .footer-share {
      display: none;
    }
    .copyright {
      border-top: none;
      padding: 6px 0;
      margin-top: 0;
    }
  }
}
  //
//.popup-crag-card {
//  color: $gray-dark;
//  text-decoration: none;
//  width: 250px;
//  a {
//    display: block;
//    color: $gray-dark;
//    text-decoration: none;
//    .img {
//      display: block;
//      position: relative;
//      height: 120px;
//      background-color: rgba(0,0,0,0.7);
//      background-size: cover;
//      text-align: center;
//      .crag-summary {
//        .route-count-container {
//          //margin: 5px 0;
//        }
//        /*.summary-chart {
//          display: none;
//        }*/
//      }
//
//      .crag-summary {
//        opacity: 1;
//        transition: opacity 0.3s;
//        position: absolute;
//        top: 0;
//        left: 0;
//        height: 120px;
//        width: 100%;
//        display: inline-block;
//        z-index: 3;
//        border-radius: 2px;
//        background-color: rgba(0,0,0,0.5);
//        text-decoration: none;
//
//        .tag-premium-topo {
//          position: absolute;
//          right: 0px;
//          top: 15px;
//          border-radius: 2px 0 0 2px;
//        }
//        .crag-name {
//          display: block;
//          color: #fff;
//          margin-top: 15px;
//          font-size: 14px;
//          font-family: $font-family-roboto;
//          text-transform: uppercase;
//        }
//        .route-count-container {
//          display: block;
//          margin: 5px 0;
//
//          .route-count{
//            color: #fff;
//            i {
//              background-color: $brand-primary;
//              border-radius: 50%;
//              padding: 2px;
//              color: $gray-dark;
//              border: none;
//              font-size: 12px;
//            }
//            display: inline-block;
//            font-size: 11px;
//            font-weight: 400;
//            & + .route-count {
//              margin-left: 10px;
//            }
//            .name {
//              @include respond-to(sm) {
//                display: none;
//              }
//            }
//          }
//        }
//        .crag-summary-chart {
//          position: absolute;
//          bottom: 10px;
//          left: 0;
//          margin-left: 5%;
//          @include crag-chart(90%, 49px);
//          .bars {
//            .col {
//              background-color: transparent;
//              color: #fff;
//              .bar {
//                border-radius: 2px 2px 0 0;
//              }
//
//              @include respond-to(sm) {
//                width: 15%;
//                & + .col {
//                  margin-left: 2%;
//                }
//              }
//              .cell {
//                font-size: $font-size-small;
//                font-weight: 400;
//                color: #fff;
//              }
//              .grade-label {
//                font-size: 10px;
//                font-weight: normal;
//                color: #bbb;
//              }
//            }
//            border-color: rgba(230,230,230,0.4);
//          }
//          margin-bottom: 0px;
//        }
//      }
//    }
//  }
//}
#map.world {
  #iw-container .iw-title {
    display: none;
    font-family: $font-family-roboto;
    font-size: 13px;
    font-weight: 400;
    padding: 0px;
    background-color: #48b5e9;
    color: white;
    margin: 0px;
    border-radius: 2px; /* In accordance with the rounding of the default infowindow corners. */
  }
  .gm-style-iw {
    width: 250px !important;
    top: 15px !important; // move the infowindow 15px down
    left: 0px !important;
    background-color: #fff;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(0, 0, 0, 0.6);
    border-radius: 2px;
  }
}
.gm-style-iw {
  .row-container{
    overflow: hidden;
  }
}
