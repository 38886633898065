section.feed-items-show {
  padding: 15px 0;
}

.feed-items {
  overflow: hidden;
  li {
    width: 350px;
    display: block;
  }
  .item.active {
    z-index: 10;
  }
}

.crag-feed-items {
  margin-top: 20px;
  li {
    width: 100%;
    margin-bottom: 15px;
  }
}

.notification-feed-items {
  li.item {
    width: 100%;
    margin-bottom: 15px;
    a.feed-item:hover {
      text-decoration: none;
      .item-wrapper {
        background: #fafafa;
      }
    }
  }
}

.feed-items-nav {
  margin: 15px;
  text-align: center;
  .nav {
    display: inline-block;
    li {
      a {
        &:hover {
          color: #000;
          background-color: darken($brand-primary, 3);
        }

        @include respond-to(xs) {
          font-size: $font-size-small;
          padding: 7px 12px;
        }
      }
      &.active {
        a {
          color: #000;
        }
      }
    }
  }
}

.feed-item {
  margin: 0;
  position: relative;
  $item-wrapper-vertical-padding: 12px;
  .item-wrapper {
    position: relative;
    display: block;
    padding: 10px $item-wrapper-vertical-padding;
    background: #fff;
    border: 1px solid;
    border-color: #e5e6e9 #dfe0e4 #d0d1d5;
    border-radius: $border-radius-base;
    text-align: left;
  }
  .unread-item {
    display: block;
    width: 8px;
    height: 8px;
    position: absolute;
    background: #ff2f2f;
    top: 10px;
    right: 15px;
    border-radius: $border-radius-base;
  }
  .edit-tool {
    position: absolute;
    top: 10px;
    right: 10px;
    a,
    button {
      color: $gray-light-2;
      font-size: 12px;
      opacity: 0.8;
      border: none;
      background: transparent;
      &:hover {
        opacity: 1;
      }
    }
  }

  .item-content {
    .blog-headline {
      margin-top: 5px;
      display: block;
      position: relative;
      width: 100%;
      .image {
        //margin: 0 (-$item-wrapper-vertical-padding);
        margin: 0px (-$item-wrapper-vertical-padding) 0px
          (-$item-wrapper-vertical-padding);
        position: relative;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        border-radius: 0;
        img {
          max-width: 100%;
          visibility: hidden;
          max-height: 250px;
        }

        .image-overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #000;
          border-radius: 0;
          opacity: 0.3;
          transition: opacity 0.2s;
        }
      }
      .title {
        padding: 25px 15px;
      }
      h2 {
        text-transform: none;
        font-size: 24px;
        width: 100%;
        margin: 0;
        padding: 0;
        color: $gray-dark;
        text-align: left;
      }
      .image + .title {
        position: absolute;
        bottom: 30px;
        width: 100%;
        padding: 0 30px;
        h2 {
          margin: 0;
          color: #fff;
        }
        .ago {
          color: #fff;
        }
        //transform: translateY(-50%);
      }
      &:hover {
        text-decoration: none;
        .image {
          .image-overlay {
            opacity: 0.1;
          }
        }
      }
    }

    .photo {
      //margin-top: 5px;
      //border: 1px solid $gray-lighter;
      margin: 5px (-$item-wrapper-vertical-padding) 0px
        (-$item-wrapper-vertical-padding);
      max-height: 350px;
      overflow: hidden;
      position: relative;
      background-size: cover;
      background-position: center center;
      &--missing {
        background-image: url("#{$image_path}crag_large.png");
        height: 561px;
      }
      .video-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        .wrapper {
          position: absolute;
          left: 0;
          width: 100%;
          text-align: center;
          top: 50%;
          margin-top: -15px;
        }
        i {
          color: #fff;
          font-size: 36px;
          opacity: 0.7;
          display: inline-block;
          //border: 2px solid #fff;
          //border-radius: 50%;
          //padding: 10px;
        }
      }
      a:hover {
        .video-overlay {
          i {
            opacity: 1;
          }
        }
      }
      img {
        opacity: 0;
        width: 100%;
      }
    }
    .more-photos {
      display: none;
      a {
        img {
        }
      }
    }
  }
  .reactions {
    a {
      color: #777777;
    }
  }
  .comments {
    .chat {
      font-size: 12px;
      border-top: 1px solid #eeeeee;
      background: #f3f3f3;
      margin: 6px (-1 * $item-wrapper-vertical-padding) -10px;
      padding: 10px $item-wrapper-vertical-padding;
      border-radius: 0 0 4px 4px;

      .chat-item {
        margin-top: 0;
        padding: 7px 0;
        border-bottom: none;
      }
      .comment-form {
        .item-details {
          padding-top: 0;
        }
      }
    }
  }
  .google-ad {
    display: inline-block;
    height: 200px;
    width: 300px;
  }
}

.hard-ascents {
  padding-bottom: 15px;
  .description a {
    color: #181818 !important;
    font-weight: 400 !important;
  }
}

.bg-dark {
  .item-wrapper {
    border: none;
  }
}

ul.feed-items.feed-items-full-width li {
  width: 100%;
}
#climbers-show,
#feed_items-show {
  .feed-item {
    .photo {
      max-height: 800px;
    }
  }
}

.feed-item {
  .footer {
    text-align: right;

    .button {
      display: inline-block;
      margin-left: 5px;
    }
  }

  .feed-embed {
    color: black;
    text-decoration: none;

    .embed-landscape,
    .embed-portrait {
      border: 1px solid #d4d4d4;
      margin-left: -50px;
      margin-top: 10px;

      .embed-photo {
        background: no-repeat center center;
        background-size: cover;
      }
    }

    .embed-portrait {
      display: flex;
      align-items: stretch;
      flex-direction: row;
      height: 190px;
      overflow: hidden;

      .embed-photo {
        flex: 3;
      }

      .content {
        flex: 4;
        max-width: 56%;
      }
    }

    .title {
      font-size: 1.6rem;
      padding: 20px 20px 8px 20px;
      color: black;
      font-weight: 600;
    }

    .text {
      padding: 0 20px;
      position: relative;
      overflow: hidden;
      max-height: 8.3rem;
      line-height: 1.2;
    }

    .text:after {
      content: "";
      text-align: right;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 50%;
      height: 1.2rem;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1) 80%
      );
    }

    .url {
      padding: 10px 20px 20px 20px;
      color: #7d7d7d;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    &:hover {
      .url {
        color: #222;
      }
    }

    @supports (-webkit-line-clamp: 5) {
      .text {
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
      }

      .text:after {
        display: none;
      }
    }

    @include respond-to(xs) {
      .embed-portrait {
        height: 43vw;
      }
    }

    @include respond-to(sm) {
      .embed-portrait {
        height: 306px;
      }
    }

    @include respond-to(md) {
      .embed-portrait {
        height: 154px;
      }

      .text {
        max-height: 6.7rem;
      }

      @supports (-webkit-line-clamp: 4) {
        .text {
          -webkit-line-clamp: 4;
        }
      }
    }
  }
}
