$gray-light-2: lighten($gray-base, 66.7%) !default; // #777


.btn-primary {
  border: 1px solid transparent;
  border-top: 1px solid lighten($btn-primary-bg, 10%);
  background: darken($btn-primary-bg, 5%);
  transition: background 0.1s;
  color: #222222 !important;
  //border-bottom: 1px solid darken($btn-primary-bg, 5%);
  // $btn-primary-border:             darken($btn-primary-bg, 5%) !default;
  &:hover {
    //border: 1px solid #fff;
    border: 1px solid transparent;
    background: $btn-primary-bg;
    color: #000000;
    //color: #fff;
  }
}

.btn.btn-rounded {
  border-radius: 30px;
  padding: 10px 15px;
}

.container {
  // width: $container-large-desktop !important;
}

a.btn.btn-default {
  color: $gray-dark;
}

.alert.alert-primary {
  background-color: $btn-primary-bg;
  border-color: $btn-primary-border;
  color: #424242;

  .btn.btn-default {
    background-color: $btn-primary-bg;
    border: 1px solid #222222;
    border-radius: 10px;

    &:hover {
      background-color: #222;
      color: #fff;
    }
  }
}

.btn-lg.btn-primary {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 18px;
  font-family: $font-family-roboto;
  padding: 18px 30px;
}

.btn-transparent {
  /*border: 1px solid $gray-light;
  background-color: transparent;
  color: $gray-dark;
  border-radius: 4px;
  margin: 5px;

  &:hover {
    background-color: $gray-light;
  }*/
  &:active {
    box-shadow: none;
  }
}

.bg-light {
  background: #fff url("#{$image_path}body_white.gif") repeat;

  .btn-primary {
    background-color: $brand-primary;
    border-top-color: lighten($brand-primary, 15);
  }

  .climber-thumb {
    border-color: #eee;
  }
}

.bg-transparent {
  background: transparent;
  color: $gray-dark;

  .btn-primary {
    border-top-color: lighten($brand-primary, 15);
    background-color: darken($brand-primary, 3);

    &:hover {
      //border-top-color: $brand-primary;
      background-color: $brand-primary;
      border-bottom-color: darken($brand-primary, 2);
    }
  }

  .climber-thumb {
    border-color: #eee;
  }
}

.bg-light-gray {
  background: #f3f3f3 none;
  color: $gray-darker;

  a {
    color: $link-color-blue;

    &.btn {
      color: inherit;
    }
  }
}

.section-top-padding {
  h3 {
    margin-top: 5px;
  }
}

.bg-dark {
  background: $gray-darker;
  color: #fff;

  .btn-transparent {
    border-color: #fff;
    color: #fff;

    &:hover {
      background-color: #fff;
      color: $gray-dark;
    }
  }
}

.bg-yellow {
  background: $brand-primary;
  color: #222;

  a {
    color: $gray-dark;
  }
}

.bg-white {
  background: #fff none;
  color: $gray-darker;

  a {
    color: $link-color-blue;
  }
}

.bg-brand-primary {
  background-color: $brand-primary;
  color: $gray-darker;

  .btn-primary {
    background-color: transparent;
    border: 2px solid $gray-darker;

    &:hover {
      color: $brand-primary !important;
      background-color: $gray-darker;
    }
  }

  a {
    color: $gray-darker;
  }
}

/*
.bg-light-gray .nav-pills,
.bg-transparent .nav-pills,
.bg-white .nav-pills
*/
.nav-pills {
  li {
    color: #000;

    a {
      color: $gray-darker !important;
      font-weight: normal;

      &:hover {
        //color: #000 !important;
        //background-color: darken($brand-primary, 3);
      }

      @include respond-to(xs) {
        font-size: $font-size-small;
        padding: 7px 12px;
      }
    }

    &.active {
      a {
        font-weight: 700;
        //color: #000 !important;
        //background-color: $brand-primary;
        &:hover {
          color: #000;
        }

        .badge {
          //color: $brand-primary;
          //background: $gray-darker;
        }
      }
    }
  }
}

a[data-toggle="collapse"][aria-expanded="true"] span.caret {
  transform: rotate(180deg);
}

.bg-dark .nav-pills {
  li {
    a {
      color: $brand-primary !important;
      font-weight: normal;
      background-color: transparent;

      &:hover {
        color: #000 !important;
        background-color: darken($brand-primary, 3);
      }

      /*@include respond-to(xs) {
        font-size: $font-size-small;
        padding: 7px 12px;
      }*/
    }

    &.active {
      a {
        color: #000 !important;
        background-color: $brand-primary;
      }
    }
  }
}

.tab-content.tab-content--scrollable-content {
  height: 30vh;
  overflow: auto;
  width: 100%;
  padding-right: 5px;
}

.text-success {
  color: #6cd76b;
}

.text-warning {
  color: #f0db0f;
}

.text-danger {
  color: #ff0000;
}

.alert a.btn-default {
  margin-top: -2px;
  font-weight: 500;
  color: #333;
}

.form-inline {
  .form-group + .form-group {
    margin-left: 2px;
    margin-right: 2px;
  }
}

.panel {
  .panel-heading {
    background: transparent;
    border-bottom: none;

    .panel-title {
      color: #959595;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 14px;
      margin: 5px 0 5px 0;
      font-family: $font-family-roboto;
    }
  }

  .list-group {
    a.list-group-item {
      color: #222;
      border: 1px solid transparent;
    }
  }
}
