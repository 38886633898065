@mixin login-h3() {
  font-family: $font-family-roboto;
  text-transform: none;
  font-weight: 700;
  font-size: 18px;
  //text-align: left;
  margin: 15px 0 30px;
}
.col-sm-vcenter {
  @include respond-to(sm) {
    display: inline-block;
    vertical-align: middle;
    float: none;
  }
  @include respond-to(md) {
    display: inline-block;
    vertical-align: middle;
    float: none;
  }
  @include respond-to(lg) {
    display: inline-block;
    vertical-align: middle;
    float: none;
  }
}
.auth-services-container {
  padding: 60px 0;
  height: 85vh;
  @include respond-to(xs) {
    height: auto;
  }
}
.auth-services {
  width: 100%;
  max-width: 960px;
  margin: auto;
  @include respond-to(sm) {
    width: 700px;
  }
  @include respond-to(xs) {
    max-width: 350px;
  }
}
.why-to-join-container {
  margin-right: -30px;
  @include respond-to(xs) {
    width: 100%;
    margin-right: 0;
  }
}
.why-to-join {
  background-color: rgba(34, 34, 34, 0.7);
  color: #ffffff;
  border-radius: 4px 0 0 4px;
  padding: 55px 80px;

  @include respond-to(sm) {
    padding: 35px 40px;
  }
  @include respond-to(xs) {
    background: transparent;
    padding: 15px 15px;
  }
  h1 {
    @include login-h3();
    margin: 0;

    @include respond-to(xs) {
      text-align: center;
    }
  }
  .reasons-to-join {
    margin: 25px 0 30px 0;
    text-align: left;
    @include respond-to(xs) {
      text-align: center;
    }
  }
  ul {
    max-width: 300px;
    display: inline-block;
    text-align: left;
    margin: 0;
  }
  .social-media-login {
    margin: 0;
    @include respond-to(xs) {
      display: none;
    }
  }
  h2 {
    font-size: 14px;
    font-weight: 500;
    font-family: $font-family-roboto;
    text-transform: none;
    margin: 0px 0 15px;
  }
  li.reason-discover,
  li.reason-track,
  li.reason-topos {
    margin: 14px 0;
    font-size: 14px;
    i {
      margin-right: 10px;
    }
    span {
      display: inline-block;
    }
  }
  .help-block {
    @include respond-to(xs) {
      text-align: center;
    }
    color: #dddddd;
  }
}
.login-container {
  display: table;
  width: 100%;
  .header-image {
    display: block;
    font-size: 30px;
    margin: 10px 0;
  }
  .btn-signup {
    padding: 8px 12px;
  }
  p {
    color: #444444;
    margin: 15px 0;
    a {
      color: #444;
      font-weight: 700;
    }
  }
  .help-block {
    margin: 5px 0 0 0;
    font-size: 12px;
  }
  .login-form {
    //width: 350px;
    // padding: 50px 50px 45px 40px;
    padding: 50px 70px 45px 70px;
    display: table-cell;
    vertical-align: middle;

    @include respond-to(xs) {
      //display: inline-block;
      min-height: initial;
      //max-width: 300px;
      text-align: left;
      padding: 0;
    }
    margin: 0px auto;
    background: #fff;
    border: 1px solid;
    border-color: rgba(229, 230, 233, 0.75) rgba(223, 224, 228, 0.75)
      rgba(208, 209, 213, 0.75);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    position: relative;

    .relative {
      margin: 10px 0;
    }
    .btn-facebook {
      display: block;
    }
    form {
      a {
        color: #444;
        font-weight: 700;
      }
      @include respond-to(xs) {
        position: relative;
        top: auto;
        left: auto;
        transform: translate(0, 0);
      }
    }

    //margin-top: 50px;
    //margin-bottom: 50px;
    .checkbox label {
      line-height: 1.5;
      font-size: 14px;
    }
  }
  h3 {
    @include login-h3();
    color: $gray-dark;
    margin: 0px 0 15px;
  }
  .btn-noaccount,
  .forgot-pw-link {
    color: $gray-base;
    font-weight: 500;
  }
  .logo-container {
  }
  .btn-facebook {
    margin-top: 10px;
  }
  .btn-login {
    margin-top: 5px;
  }

  @include respond-to(xs) {
    position: relative;
    top: auto;
    left: auto;
    transform: translate(0, 0);
    text-align: center;
    min-height: initial;
    .login-form {
      width: auto;
      // background: transparent;
      border: none;
      box-shadow: none;
      min-height: initial;
      text-align: left;
      padding: 30px 20px 20px 20px;

      p,
      p a,
      .btn-noaccount,
      .forgot-pw-link,
      h3 {
        // color: #fff;
      }
    }
  }
}

.facebox-content-wrapper {
  .login-container {
    position: relative;
    top: auto;
    left: auto;
    transform: translate(0, 0);
    min-height: initial;
    .login-form {
      position: relative;
      border: none;
      margin-top: 0px;
      margin-bottom: 0px;
      padding: 35px 25px;
      box-shadow: none;
      .btn-facebook {
        display: block;
      }
    }

    @include respond-to(xs) {
      position: relative;
      top: auto;
      left: auto;
      transform: translate(0, 0);
      .login-form {
        width: auto;
        padding: 15px 15px;

        background: transparent;
        border: none;
        box-shadow: none;

        color: $gray-dark;

        .btn-noaccount,
        .forgot-pw-link,
        h3 {
          color: $gray-base;
        }
      }
    }
  }
  /*.login-container {
    margin: -10px;
    padding: 15px;
    background-image: url('#{$image_path}login_bg.jpg');
    background-size: cover;

    .logo-container {
      .logo {
        display: inline-block;
        img {
          height: 100px;
        }
      }
    }
  }*/
}
#climbers-login {
  background-image: url("#{$image_path}login_bg.jpg");
  background-size: cover;
}
.captcha-form {
  margin: 15px 0;
}
.login-container.signup,
.login-container.password-recovery {
  position: relative;
  top: auto;
  left: auto;
  transform: translate(0, 0);
  //padding: 20px 0;
  width: 100%;
  .login-form {
    width: 100%;
  }
}
#payment_informations-index,
#climbers-create,
#climbers-new,
#invitations-sent,
#invitations-create,
#invitations-new,
#password_requests-new,
#password_requests-show,
#password_requests-create {
  background-image: url("#{$image_path}login_bg.jpg");
  background-size: cover;
  .overhang {
    display: none;
  }
}

#climbers-create .overhang {
  display: none;
}

.alert.alert-warning {
  h3 {
    margin: 0;
    padding: 0;
    display: inline-block;
    font-size: 12px !important;
    color: #8a6d3b;
    font-weight: 400;
  }
  ul {
    margin: 0;
    padding: 0;
    li {
      margin-left: 15px;
    }
  }
  a {
    color: #8a6d3b;
    font-weight: 500;
  }
}
.alert a{
  color: inherit;
  font-weight: 500;
}

#climbers-invite {
  .mbox {
    max-width: 380px;
  }
}

.invitation-signup {
  padding: 25px 40px;
  text-align: center;
  h3 {
    margin: 20px 0 30px;
  }
  .captcha {
    margin: 30px 0;
  }
  p.terms-agreement {
    margin: 20px auto;
    max-width: 255px;
    a {
      color: black;
      font-weight: bold;
    }
  }
  input[type="submit"] {
    margin-top: 25px;
    padding: 12px;
    border-radius: 30px;
    font-weight: bold;
  }
  a.already-account {
    display: block;
    margin-top: 30px;
    color: black;
    font-weight: bold;
    font-size: 1.5rem;
  }
}
