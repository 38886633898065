#area_edit {
  .map-container {
    position: relative;
    width: auto;
    height: 640px;
    top: 0;
    bottom: 0;
  }

  .crag-popup {
    position: absolute;

    &-visible {
      bottom: 0;
    }
  }
}

#iframe {
  .map-container {
    top: 0;
    bottom: 0;
  }
}

.map-container {
  overflow: hidden;
  position: absolute;
  top: 50px;
  bottom: 30px;
  width: 100vw;

  .map, .list {
    height: 100%;
  }

  .list {
    @include respond-to(xs) {
      display: none;
    }
  }

  .map-controls-visible {
    @media only screen and (min-width: $screen-sm-min) {
      display: block !important;
    }
  }

  .map-controls-mobile-visible {
    @include respond-to(xs) {
      display: block !important;
    }
  }

  #mapControlsContainer.map-controls-small {
    @media only screen and (min-width: $screen-sm-min) {
      height: auto;
      display: block;

      .controls-container {
        margin-left: 15px;
        margin-top: 15px;
        border-radius: 4px;
        min-height: 85px;
        padding-top: 0;

        .controls-header {
          padding: 20px;
        }

        .search {
          position: absolute;
        }

        .filters {
          position: relative;
          padding-top: 0;
        }

        .controls-content {
          position: static;
        }
      }
    }
  }

  #mapControlsContainer {
    display: none;

    .controls-page {
      height: 100%;

      .controls-content {
        position: absolute;
        top: 0px;
        left: 0;
        right: 0;
        bottom: 0;

        .help-label {
          text-align: center;
          margin: 30px 0 0;
          font-size: 14px;
        }
      }
    }

    .search {
      .controls-content {
        @include respond-to(xs) {
          top: 0;
        }
      }
    }

    .menubutton {
      width: 100% !important;
      float: none !important;

      button {
        border: none;
      }
    }
  }

  .map-buttons-container {
    pointer-events: none;
    height: 40px;
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;

    &-hidden-desktop {
      @media only screen and (min-width: $screen-sm-min) {
        display: none;
      }
    }
  }

  .map-add-crag-button-container {
    height: 40px;
    width: auto;
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .map-add-crag-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;

    .header-container {
      height: 60px;
      width: 100%;
      position: absolute;
      top: 0px;
      right: 0px;
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      padding: 0 15px;
      background: #000;
      pointer-events: visible;

      .title {
        color: #fff;
        font-size: 14px;
        text-align: center;
      }
    }

    .pointer-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;

      .pointer {
        font-size: 24px;
      }
    }

  }

  .map-buttons {
    pointer-events: all;
    height: 100%;
    display: flex;
    align-items: center;
    background-color: #222;
    padding: 0 15px;
    border-radius: 20px;
    color: white;
    text-transform: uppercase;
    font-weight: 500;

    .button {
      padding: 7px;
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    .divider {
      width: 1px;
      height: 20px;
      padding: 0;
      margin: 0 4px;
      background-color: #444;
    }

    .filter-count {
      font-size: 1.4rem;
      display: inline-block;
      background-color: white;
      color: #222;
      border-radius: 10px;
      width: 20px;
      text-align: center;
      margin-left: 8px;
    }

    .glyphicon {
      margin-left: 8px;

      &-map {
        font-size: 1.6rem;
        margin-bottom: 2px;
      }

      &-list {
        font-size: 1.4rem;
        margin-bottom: 2px;
      }

      &-filter {
        font-size: 1.4rem;
      }
    }
  }

  .crag-card {
    display: block;
    margin: 7px 0 0 0;
    width: 100%;
    user-select: none;
    position: relative;
    clear: both;
    background-color: #fff;
    border-right: 3px solid #fff;
    padding-right: 7px;

    &:hover {
      //border-right-color: $brand-primary;
      .title {
        text-decoration: underline;
      }
    }

    .crag-card__content-container {
      display: flex;
      flex-direction: row;
      column-gap: 10px;
      align-items: center;

      .clearfix {
        display: block;
        clear: both;
      }

      &:hover, &:focus, &:active {
        text-decoration: none;
      }
    }

    .thumbnail {
      display: block;
      height: 110px;
      width: 110px;
      flex-shrink: 0;
      background-size: cover;
      background-position: center center;
      margin-bottom: 0;
      background-image: url('#{$image_path}foggy-mountains-100x100.png');
      padding: 0;
      overflow: hidden;
      border-radius: 0px;
      border-color: #eee;

      .img {
        display: block;
        height: 110px;
        width: 110px;
        background-size: cover;
        background-position: center center;
        position: relative;

        .tag-premium-topo {
          position: absolute;
          top: 10px;
          left: -1px;
          border-radius: 0 2px 2px 0;
        }
      }

      img {
        display: none !important;
      }
    }

    .details-container {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      color: $gray-dark;

      .title {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: $font-size-base;

        .name {
          font-weight: 700;
        }

        .area {
          font-style: italic;
          color: #444;
          display: none;
        }
      }

      .route-count-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin: 0;

        .route-count {
          display: flex;
          align-items: center;
          font-size: 10px;

          i {
            font-size: 12px;
            margin-right: 3px;
          }
        }

        .route-count + .route-count {
          margin-left: 10px;
        }
      }
    }
  }

  .crag-popup {
    transition: all .3s;
    position: absolute;
    bottom: -60px;
    opacity: 0;
    z-index: 11;
    width: 100%;
    pointer-events: none;

    @include respond-to(xs) {
      left: 10px;
      right: 10px;
      width: auto;
    }

    .crag-card {
      width: 340px;
      margin: 0 auto;
      background-color: white;
      pointer-events: none;
      border-radius: 10px;
      //padding: 0;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

      &:hover {
        border-right-color: #fff;
      }

      .thumbnail {
        border-radius: 10px 0 0 10px;
      }

      @include respond-to(xs) {
        width: 100%;
      }
    }
  }

  .crag-card.travel-card {
    //display: flex;
    //flex-direction: row;
    //column-gap: 10px;

    &:hover {
      cursor: pointer;
    }

    .details-container {
      height: 110px;
      padding: 5px 0;
      width: 200px;

      .title {
        box-sizing: border-box;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;

        img {
          width: 20px;
          margin-right: 2px;
          margin-top: -7px;
        }
      }

      .distance {
        box-sizing: border-box;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .info {
        margin-top: 6px;
        font-weight: 400;
        color: #333;
        flex-grow: 1;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .crag-popup-visible {
    bottom: 10px;
    opacity: 1;

    .crag-card {
      pointer-events: all;
    }
  }

  .map-popup-container-close {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    width: 44px;
    height: 44px;
    z-index: 1070;
    opacity: 0.8;
    transition: opacity .2s;
    border-radius: 50px;
    text-decoration: none !important;

    &:hover {
      opacity: 1;
    }

    &:after,
    &:before {
      position: absolute;
      cursor: pointer;
      border-radius: 1px;
      height: 5px;
      width: 35px;
      background: #c1c1c1;
      display: block;
      content: '';
      top: 20px;
      left: 5px;
    }

    &:before {
      transform: rotate(45deg);
      -ms-transform: rotate(45deg); /* IE 9 */
      -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */

    }

    &:after {
      transform: rotate(-45deg);
      -ms-transform: rotate(-45deg); /* IE 9 */
      -webkit-transform: rotate(-45deg); /* Chrome, Safari, Opera */
    }
  }

  .map-popup-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 11;
    background-color: rgba(0, 0, 0, .3);

    .dropzone {
      width: 150px;
      height: 150px;
      padding: 20px;
      cursor: pointer;
    }

    .marker-photo {
      display: inline-block;
      margin: 10px 10px 0 0;
      position: relative;
      width: 100px;
      height: 100px;
      cursor: pointer;

      .glyphicon {
        color: black;
        display: none;
        font-size: 3rem;
        position: absolute;
      }

      &:hover {
        .glyphicon {
          display: inline-block;
        }

        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: rgba(255, 255, 255, .5);
        }
      }
    }
  }

  .mapbox {
    position: relative;
    background-color: white;
    padding: 15px;
    border-radius: 4px;
    width: auto;

    .marker-info-footer {
      position: relative;
      color: #333333;
      background: transparent;
      padding-top: 15px;
      border-top: 1px solid #ddd;
      text-align: right;

      .button {
        display: inline-block;

        &-delete {
          float: left;
        }
      }
    }

    .confirmation-dialog {
      position: absolute;
      bottom: -17px;
      left: -2px;
      background-color: white;
      display: flex;
      align-items: flex-start;
      height: 52px;
      padding: 2px 0 0 2px;

      h4 {
        margin: 7px 10px 0 0;
      }

      .btn:nth-of-type(2) {
        margin-left: 3px;
      }
    }
  }

  .commercial-marker-popup {
    width: 100%;
    max-width: 460px;
    overflow: auto;
    height: auto;
    max-height: 90%;

    &-header {
      font-family: $font-family-roboto;
      font-weight: 700;
      font-size: 2.6rem;

      img {
        margin-right: 10px;
        margin-top: -15px;
      }
    }

    @include respond-to(xs) {
      height: 100%;
      max-height: 100%;
    }

    .photo {
      margin: -15px -15px 20px -15px;
      height: 280px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    a {
      color: #444444;

      &:hover {
        color: #444444;
      }
    }

    .popup-row {
      padding: 15px 5px;
      border-bottom: 1px solid #E9E9E9;
      font-size: 1.7rem;
      display: flex;

      &__info {
        padding: 15px 0px;
        font-weight: 300;
        color: #000;
      }

      .glyphicon {
        margin-right: 10px;
      }
    }

    .buttons {
      margin: 30px 0;
    }

    .btn {
      margin-top: 15px;
      text-decoration: none !important;
      font-size: 1.7rem;

      &:hover {
        text-decoration: none !important;
      }
    }
  }
}

.google-maps__info-window-content {
  h3 {
    font-size: 14px;
    font-weight: 600;
    margin: 10px 5px;
  }

  p {
    font-size: 14px;
    margin: 10px 5px 5px 5px;
  }
}

.map-toggles {
  .modal-dialog {
    width: 350px;
    @include respond-to(xs) {
      width: auto;
    }
  }

  .miniheader {
    margin-top: 25px;
  }

  .toggle-switch {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #d6d6d6;
    padding: 6px 0;
    margin-bottom: 0;

    span:first-child {
      margin-top: 3px;
    }
  }
}