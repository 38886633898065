h1 {
  font-family: $font-family-roboto;
  font-weight: 700;
  font-size: 46px;
  @include respond-to(sm) {
    font-size: 36px;
  }
}
h2 {
  font-family: $font-family-roboto;
  font-size: 24px;
  font-weight: 700;
  @include respond-to(md) {
    font-size: 30px;
  }
  @include respond-to(lg) {
    font-size: 30px;
  }
}
h3 {
  font-family: $font-family-roboto;
  font-size: 20px;
  font-weight: 700;
  @include respond-to(md) {
    font-size: 24px;
  }
  @include respond-to(lg) {
    font-size: 24px;
  }
}
h4 {
  font-family: $font-family-roboto;
  font-weight: 700;
}
h5 {
  font-family: $font-family-roboto;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
}
p {
  font-family: $font-family-roboto;
  font-size: $font-size-base;
}

p + p {
  margin-top: 1em;
}
.additional-description,
.additional-heading {
  font-family: $font-family-roboto;
}
h1.additional-heading {
  text-transform: none;
  font-size: 46px;
  font-weight: 700;
  @include respond-to(sm) {
    font-size: 36px;
  }
}
h2.additional-heading {
  text-transform: none;
  font-size: 36px;
  font-weight: 700;
  @include respond-to(sm) {
    font-size: 28px;
  }
}
h3.additional-heading {
  text-transform: none;
  font-size: 26px;
  font-weight: 700;
  @include respond-to(sm) {
    font-size: 22px;
  }
}
h3.additional-description {
  text-transform: none;
  font-size: 24px;
  font-weight: 400;
}
p.additional-heading {
  text-transform: none;
  font-size: 20px !important;
  font-weight: 700;
}
p.additional-description {
  text-transform: none;
  font-size: 18px !important;
  font-weight: 400;
}

.additional-heading + .additional-description {
  margin: 0 !important;
}

.additional-description + .additional-heading {
  margin: 20px 0 40px 0 !important;
}
.additional-heading + .additional-heading {
  margin: 10px 0 40px 0 !important;
}

.lander-page {
  h2 {
    font-family: $font-family-roboto;
    text-transform: none;
    font-size: 24px;
    font-weight: 700;
    color: #212121;
    text-align: center;
    margin-top: 45px;
    &::after {
      padding-top: 17px;
      content: '';
      border-bottom: 2px solid #F3DD00;
      display: block;
      margin: 0 auto 20px;
      width: 70px;
    }
    @include respond-to(md) {
      font-size: 30px;
    }
    @include respond-to(lg) {
      font-size: 30px;
    }
  }
  h3 {
    font-family: $font-family-roboto;
    text-transform: none;
    font-weight: 400;

  }
  h4 {
    font-family: $font-family-roboto;
    text-transform: none;
    font-weight: 700;
  }
  h5 {
    font-family: $font-family-roboto;
    text-transform: none;
    font-weight: 700;
    font-size: 16px;
  }
  p {
    font-family: $font-family-roboto;
    font-size: $font-size-base;
  }
  p.description {
    font-weight: 300;
    font-size: 18px;
    line-height: 1.5;
    color: #212121;
    margin: 20px 0 30px;
    .line-break {
      display: block;
      height: 20px;
    }
  }
  hr {
    margin-top: 30px;
    margin-bottom: 40px;
    margin-left: 25px;
    margin-right: 25px;
    @include respond-to(md) {
      visibility: hidden;
    }
    @include respond-to(lg) {
      visibility: hidden;
    }
  }
  hr + section h2 {
    margin-top: 0;
  }
}