/** USED ICON MOON TO CREATE FONTS **/

@import "variables";

$fonts-path: "../fonts/";

@font-face {
  font-family: "27font";
  src: url('#{$fonts-path}27font_2020.eot');
  src: url('#{$fonts-path}27font_2020.eot') format("embedded-opentype"),
    url('#{$fonts-path}27font_2020.ttf') format("truetype"),
    url('#{$fonts-path}27font_2020.woff') format("woff"),
    url('#{$fonts-path}27font_2020.woff2') format("woff2"),
    url('#{$fonts-path}27font_2020.svg') format("svg");
  font-weight: normal;
  font-style: normal;
}

i.image-icon {
  display: inline-block;
  width: 38px;
  height: 38px;
  background-repeat: no-repeat;
  background-position: center bottom;

  &--messenger {
    background-image: url("#{$image_path}social-messenger.png");
  }
  &--whatsapp {
    background-image: url("#{$image_path}social-whatsapp.png");
  }
}

i.icon27 {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "27font" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.circle {
    background-color: $brand-primary;
    border-radius: 50%;
    color: $gray-base;
    padding: 4px;
    display: inline-block;
    font-size: 110%;
  }

  &.icon-arm:before {
    content: "\e900";
  }
  &.icon-check:before {
    content: "\e902";
  }
  &.icon-climber:before {
    content: "\e903";
  }
  &.icon-close:before {
    content: "\e904";
  }
  &.icon-crags27:before {
    content: "\e905";
  }
  &.icon-directions:before {
    content: "\e906";
  }
  &.icon-distance:before {
    content: "\e907";
  }
  &.icon-facebook:before {
    content: "\e908";
  }
  &.icon-filters:before {
    content: "\e909";
  }
  &.icon-heart-empty:before {
    content: "\e90a";
  }
  &.icon-heart-full:before {
    content: "\e90b";
  }
  &.icon-image:before {
    content: "\e90c";
  }
  &.icon-info:before {
    content: "\e90d";
  }
  &.icon-layers:before {
    content: "\e90e";
  }
  &.icon-list:before {
    content: "\e90f";
  }
  &.icon-map:before {
    content: "\e910";
  }
  &.icon-menu:before {
    content: "\e911";
  }
  &.icon-next:before {
    content: "\e912";
  }
  &.icon-prev:before {
    content: "\e913";
  }
  &.icon-search:before {
    content: "\e914";
  }
  &.icon-share:before {
    content: "\e915";
  }
  &.icon-boulder:before {
    content: "\e901";
  }
  &.icon-sport:before {
    content: "\e916";
  }
  &.icon-dws:before {
    content: "\e91d";
  }
  &.icon-partially-bolted:before,
  &.icon-traditional:before,
  &.icon-trad:before {
    content: "\e917";
  }
  &.icon-twitter:before {
    content: "\e918";
  }
  &.icon-upload:before {
    content: "\e919";
  }
  &.icon-user:before {
    content: "\e91a";
  }
  &.icon-vimeo:before {
    content: "\e91b";
  }
  &.icon-youtube:before {
    content: "\e91c";
  }
  &.icon-sun:before {
    content: "\e91e";
  }
  &.icon-compass:before {
    content: "\e91f";
  }
  &.icon-drops:before {
    content: "\e920";
  }
  &.icon-steps:before {
    content: "\e921";
  }
  &.icon-child-friendly:before {
    content: "\e922";
  }
  &.icon-altitude:before {
    content: "\e923";
  }
  &.icon-aid:before {
    content: "\e924";
  }
  &.icon-mountain:before {
    content: "\e925";
  }

  &.icon-crimpers:before {
    content: "\e926";
  }
  &.icon-slopers:before {
    content: "\e927";
  }
  &.icon-jugs:before {
    content: "\e928";
  }
  &.icon-crack:before {
    content: "\e929";
  }
  &.icon-fingery:before {
    content: "\e92a";
  }
  &.icon-powerful:before {
    content: "\e92b";
  }
  &.icon-endurance:before {
    content: "\e92c";
  }
  &.icon-dyno:before {
    content: "\e92d";
  }
  &.icon-technical:before {
    content: "\e92e";
  }
  &.icon-mental:before {
    content: "\e92f";
  }
  &.icon-roof:before {
    content: "\e930";
  }
  &.icon-overhang:before {
    content: "\e931";
  }
  &.icon-vertical:before {
    content: "\e932";
  }
  &.icon-slab:before {
    content: "\e933";
  }
  &.icon-traverse:before {
    content: "\e934";
  }
  &.icon-sitstart:before {
    content: "\e935";
  }
  &.icon-topslasthold:before {
    content: "\e936";
  }
  &.icon-tradgear_required:before {
    content: "\e937";
  }
  &.icon-dangerous:before {
    content: "\e938";
  }
  &.icon-pockets:before {
    content: "\e939";
  }
  &.icon-tufas:before {
    content: "\e93a";
  }
  &.icon-pro1:before {
    content: "\e93b";
  }
  &.icon-pro2:before {
    content: "\e93c";
  }
  &.icon-pro3:before {
    content: "\e93d";
  }
  &.icon-pro4:before {
    content: "\e93e";
  }
  &.icon-pro5:before {
    content: "\e93f";
  }
}
