#ascents-todo main.content,
#ascents-index main.content,
#teams-show main.content,
#teams-crags main.content,
#climbers-show main.content {
  background: #F3F3F3 none;
}

#climbers-show {
  .area-sidebar {
    padding-top: 25px;

    .box-padded {
      margin-top: 0px;
      margin-bottom: 15px;

      .more {
        margin-top: 5px;
      }
    }

    .crag-card {
      max-width: 100%;
    }
  }

  .actions + .box-padded {
    margin-top: 6px;
  }

  .timeline {
    margin: 15px 0 0 0;
    padding: 0;

    &:before {
      background: #fff;
      width: 4px;
    }

    li {
      padding: 0;
      padding-bottom: 6px;

      .item .flag-wrapper .icon-wrapper {
      }
    }
  }

  li .actions {
    //margin-left: 30px;
    height: 30px;
    padding-top: 0px;
    margin-bottom: 5px;
    @include respond-to(xs) {
      font-size: 10px;
    }
    @include respond-to(sm) {
      font-size: 10px;
    }

    .action {
      /*vertical-align: bottom;
      font-size: 14px;
      font-weight: 500;
      opacity: 0.8;
      &:hover {
        opacity: 1;
        text-decoration: none;
      }*/
    }
  }

  .climber-crags-visited {
  }

  .climber-shared-photos {
  }
}

#ascents-index {

  .box-tabnav {
    position: absolute;
    top: 21px;
    right: 31px;
  }

  .ascent-highlights {
    margin-top: 0;
    @include respond-to(xs) {
      margin-top: 20px;
    }
    @include respond-to(sm) {
      margin-top: 20px;
    }
  }
}


.onsight {
  background-color: $onsight-color;
}

.flash {
  background-color: $flash-color;
}

.redpoint {
  background-color: $redpoint-color;
}

.toprope {
  background-color: $toprope-color;
}

table.ascent-stats {
  width: 100%;

  th, td {
    padding: 2px;
    border-bottom: 1px solid $gray-lighter;
    @include respond-to(xs) {
      font-size: 10px;
    }
  }

  .ascent_diagram {
    td, th {
      padding: 0;

      img {
        border-left: 1px solid #fff !important;
        display: block;
        height: 16px;
      }
    }
  }
}

.ascent-chart {

}

table.ascent-list {
  th {
    white-space: nowrap;
  }

  td:nth-child(1) {
    //white-space: nowrap;
  }

  th:nth-child(2),
  td:nth-child(2) {
    // display: none;
  }

  //
  //td:nth-child(6) {
  //  white-space: nowrap;
  //}
  //
  //td:nth-child(7) {
  //  white-space: nowrap;
  //}

  .ascent-details {
  }

  td {
    vertical-align: middle !important;

    img {
      width: 40px;
      border-radius: 2px;
      margin-right: 5px;
      float: left;
    }
  }

  th .tablesorter-header-inner {
    padding-right: 15px;
  }

  @include respond-to(sm) {
    td, th {
      font-size: 12px;
    }
  }
  @include respond-to(xs) {
    td, th {
      font-size: 12px;
    }

    td:nth-child(1) {
      white-space: normal;
    }

    th:nth-child(2),
    td:nth-child(2),
    th:nth-child(3),
    td:nth-child(3),
    th:nth-child(4),
    td:nth-child(4),
    th:nth-child(6),
    td:nth-child(6) {
      display: none;
    }
  }

  td.actions {
    text-align: left;

    a {
      display: inline-block;
      margin: 0 2px;
      font-size: 14px;
      color: $gray-light-2;
      text-shadow: none;

      &:hover {
        color: $gray-dark;
      }
    }
  }
}

.ascent-date-xs {
  display: none;
  @include respond-to(xs) {
    display: table-cell;
  }
}

table.todo-list {
  th {
    white-space: nowrap;
  }

  td:nth-child(1) {
    white-space: nowrap;
  }

  td:nth-child(5) {
    white-space: nowrap;
  }

  td:nth-child(6) {
    white-space: nowrap;
  }

  th .tablesorter-header-inner {
    padding-right: 15px;
  }

  @include respond-to(sm) {
    td, th {
      font-size: 12px;
    }
  }
  @include respond-to(xs) {
    td, th {
      font-size: 12px;
    }

    th:nth-child(3),
    td:nth-child(3),
    th:nth-child(4),
    td:nth-child(4),
    th:nth-child(5),
    td:nth-child(5),
    th:nth-child(6),
    td:nth-child(6),
    th:nth-child(7),
    td:nth-child(7) {
      display: none;
    }
  }

  td.actions {
    text-align: left;

    a {
      display: inline-block;
      margin: 0 2px;
      font-size: 14px;
      color: $gray-light-2;
      text-shadow: none;

      &:hover {
        color: $gray-dark;
      }
    }
  }

  td {
    vertical-align: middle !important;

    img {
      width: 40px;
      border-radius: 2px;
      margin-right: 5px;
      float: left;
    }
  }
}

.ascent-details {
  max-width: 300px;
  color: #333333;
  font-weight: 400;
  font-size: 12px;
  white-space: normal;
  overflow-wrap: break-word;
}

.change-photo {

  label {
    img.climber-thumb {
      width: 50px;
      height: 50px;
    }

    margin: 3px;
    position: relative;
    display: inline-block;

    .badge {
      display: inline-block;
      position: absolute;
      bottom: 5px;
      right: 0px;
      opacity: 0;
      background-color: #b7b7b7;
      transition: opacity .2s;

      .glyphicon {
        font-size: 10px;
        color: #fff;
      }
    }
  }

  label:hover,
  input[type=radio]:checked + label {
    .badge {
      opacity: 1;
    }
  }

  input[type=radio]:checked + label {
    .badge {
      background-color: #337ab7;
    }
  }

  input[type=radio] {
    display: none;
  }

}

canvas#ascent-chart {
  width: 100%;
}

.profile-background {
  background-position: center center;
  background-size: cover;
  height: 50px;
  width: 180px;
  border: 1px solid #ddd;
  border-radius: $border-radius-base;
  max-width: 100%;
}

.sub-nav {
  .action {
    margin-top: 20px;
    font-size: 12px;
    padding: 9px 15px;
  }
}

.highlights-container {
  .row {
    margin-top: 15px;
  }

  .name {
    text-align: left;
    font-size: 14px;
    font-family: $font-family-roboto;
    font-weight: 300;
  }

  .value {
    text-align: right;
    font-size: 14px;
    font-family: $font-family-roboto;
    font-weight: 500;
  }
}

tr.failure {
  /*td {
    opacity: 0.8;
  }
  td .dropdown {
    opacity: 1;
  }*/
}